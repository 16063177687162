import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import OrderOptimizerDetailsItem from "../common/types/order-optimizer-details-item";
import { useAppContext } from "../../../../context/app.context";
import orderOptimizerDetailsDriverTableHelper from "./order-optimizer-details-driver-table.helper";
import OrderOptimizerDetailsDriverTableRow from "../common/types/order-optimizer-details-driver-table-row";
import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import orderOptimizerDetailsHelper from "../order-optimizer-details.helper";
import OrderOptimizerDetailsDriverTableRates from "./types/order-optimizer-details-driver-table-rates";

type OrderOptimizerDetailsDriverTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsItem?: OrderOptimizerDetailsItem;
  marginAba: number;
  marginSabs: number;
  validationResult: Record<
    keyof OrderOptimizerDetailsDriverTableRates,
    FormValidationResult | null
  >;
  onRateAbaChanged: (key: string, rateAba: number) => void;
  onRateSabsChanged: (key: string, rateSabs: number) => void;
  onRateSaAndBsChanged: (key: string, rateSaAndBs: number) => void;
  rates: OrderOptimizerDetailsDriverTableRates;
};

const OrderOptimizerDetailsDriverTableComponent: FC<
  OrderOptimizerDetailsDriverTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsDriverTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createOrdersByDriverAbaTableRow = (
    detailsItem: OrderOptimizerDetailsItem
  ): OrderOptimizerDetailsDriverTableRow => {
    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsItem.ordersByDriverAba?.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsItem.ordersByDriverAba?.distance
    );

    const totalTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverAba?.totalTime
    );
    const totalTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverAba?.totalTime
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverAba?.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverAba?.haltingTime
    );

    const contractRateTitle = orderOptimizerDetailsHelper.getRateLabel(
      props.rates.aba
    );

    const abaAndSabsMarginLabel = orderOptimizerDetailsHelper.getMarginLabel(
      props.marginAba
    );
    const abaAndSabsMarginTitle = orderOptimizerDetailsHelper.getMarginLabel(
      props.marginAba
    );

    const translations =
      orderOptimizerTranslationsHelper.getDetailsTranslations().table.driver;

    return {
      id: "aba",
      value: {
        driver: (
          <div title={detailsItem.driver?.displayName ?? "---"}>
            {detailsItem.driver?.displayName ?? "---"}
          </div>
        ),
        billingModel: <div title="ABA">ABA</div>,
        contractRate: (
          <div title={contractRateTitle}>
            <NumericInputComponent
              value={props.rates.aba}
              onChange={(value) =>
                props.onRateAbaChanged("aba", value ?? props.rates.aba)
              }
              decimalPrecision={2}
              hasError={!!props.validationResult.aba?.errorMessage}
              placeholder={translations.ratePlaceholderLabel}
            />
            {!!props.validationResult.aba?.errorMessage && (
              <span className={"route_details_error_message"}>
                {props.validationResult.aba.errorMessage}
              </span>
            )}
          </div>
        ),
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title={haltingTimeTitle}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        margin: (
          <div title={abaAndSabsMarginTitle}>{abaAndSabsMarginLabel}</div>
        ),
      },
    };
  };

  const createOrdersByDriverSabsTableRow = (
    detailsItem: OrderOptimizerDetailsItem
  ): OrderOptimizerDetailsDriverTableRow => {
    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsItem.ordersByDriverSabs?.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsItem.ordersByDriverSabs?.distance
    );

    const totalTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverSabs?.totalTime
    );
    const totalTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverSabs?.totalTime
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverSabs?.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsItem.ordersByDriverSabs?.haltingTime
    );

    const contractRateTitle = orderOptimizerDetailsHelper.getRateLabel(
      props.rates.sabs
    );

    const abaAndSabsMarginLabel = orderOptimizerDetailsHelper.getMarginLabel(
      props.marginSabs
    );
    const abaAndSabsMarginTitle = orderOptimizerDetailsHelper.getMarginLabel(
      props.marginSabs
    );

    const translations =
      orderOptimizerTranslationsHelper.getDetailsTranslations().table.driver;

    return {
      id: "sabs",
      value: {
        driver: (
          <div title={detailsItem.driver?.displayName ?? "---"}>
            {detailsItem.driver?.displayName ?? "---"}
          </div>
        ),
        billingModel: <div title="SABS">SABS</div>,
        contractRate: (
          <div title={contractRateTitle}>
            <NumericInputComponent
              value={props.rates.sabs}
              onChange={(value) =>
                props.onRateSabsChanged("sabs", value ?? props.rates.sabs)
              }
              decimalPrecision={2}
              hasError={!!props.validationResult.sabs?.errorMessage}
              placeholder={translations.ratePlaceholderLabel}
            />
            {props.validationResult.sabs?.errorMessage && (
              <span className={"route_details_error_message"}>
                {props.validationResult.sabs.errorMessage}
              </span>
            )}
          </div>
        ),
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title={haltingTimeTitle}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        margin: (
          <div title={abaAndSabsMarginTitle}>{abaAndSabsMarginLabel}</div>
        ),
      },
    };
  };

  const createSaAndBsDistanceTableRow = (
    detailsItem: OrderOptimizerDetailsItem
  ): OrderOptimizerDetailsDriverTableRow => {
    const sabsAbaDifferenceDistance = (detailsItem.ordersByDriverSabs?.distance ?? 0) - (detailsItem.ordersByDriverAba?.distance ?? 0);

    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      sabsAbaDifferenceDistance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      sabsAbaDifferenceDistance
    );

    const contractRateTitle = orderOptimizerDetailsHelper.getRateLabel(
      props.rates.saAndBs
    );

    const translations =
      orderOptimizerTranslationsHelper.getDetailsTranslations().table.driver;

    return {
      id: "saAndBs",
      value: {
        driver: (
          <div title={translations.saAndBsDistanceTitle}>
            {translations.saAndBsDistanceLabel}
          </div>
        ),
        billingModel: <div title="---">---</div>,
        contractRate: (
          <div title={contractRateTitle}>
            <NumericInputComponent
              value={props.rates.saAndBs}
              onChange={(value) =>
                props.onRateSaAndBsChanged(
                  "saAndBs",
                  value ?? props.rates.saAndBs
                )
              }
              decimalPrecision={2}
              hasError={!!props.validationResult.saAndBs?.errorMessage}
              placeholder={translations.ratePlaceholderLabel}
            />
            {props.validationResult.saAndBs?.errorMessage && (
              <span className={"route_details_error_message"}>
                {props.validationResult.saAndBs.errorMessage}
              </span>
            )}
          </div>
        ),
        totalTime: <div title="---">---</div>,
        haltingTime: <div title="---">---</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        margin: <div title="---">---</div>,
      },
    };
  };

  const rows: OrderOptimizerDetailsDriverTableRow[] = useMemo(() => {
    if (!props.detailsItem) return [];

    return [
      createOrdersByDriverAbaTableRow(props.detailsItem),
      createOrdersByDriverSabsTableRow(props.detailsItem),
      createSaAndBsDistanceTableRow(props.detailsItem),
    ];
  }, [props.rates, props.detailsItem, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsDriverTableComponent;
