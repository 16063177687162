import dateService from "../../../../common/utils/date/date.service";
import cargoTranslationsHelper from "../../../../languages/cargo-translations.helper";

const getDateInputFormattedDate = (date: Date): string => {
  return dateService.formatDate(date);
};

const getActivityStatusTitle = (isContractActive: boolean): string => {
  const translations =
    cargoTranslationsHelper.getCargoTaxiContractListingTranslations().table
      .activityStatus;

  return isContractActive
    ? translations.activeLabel
    : translations.inactiveLabel;
};

const getPermissionStatusTitle = (isPermitted: boolean): string => {
  const translations =
    cargoTranslationsHelper.getCargoTaxiContractListingTranslations().table
      .permission;

  return isPermitted
    ? translations.permissionLabel
    : translations.noPermissionLabel;
};

const getDiscountLimitLabel = (discountLimit: number): string => {
  return `${discountLimit} %`;
};

const getDistanceRateLabel = (distanceRate: number): string => {
  return `${distanceRate.toFixed(2)} PLN/KM`;
};

const getHaltingTimeAfterMinutesLabel = (
  haltingTimeAfterMinutes: number
): string => {
  return `${haltingTimeAfterMinutes} min`;
};

const getHaltingTimeRateLabel = (haltingTimeRate: number): string => {
  return `${haltingTimeRate.toFixed(2)} PLN/KM`;
};

const cargoCargoTaxiContractListingHelper = {
  getDateInputFormattedDate,
  getActivityStatusTitle,
  getPermissionStatusTitle,
  getDiscountLimitLabel,
  getDistanceRateLabel,
  getHaltingTimeAfterMinutesLabel,
  getHaltingTimeRateLabel,
};

export default cargoCargoTaxiContractListingHelper;
