import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type DriverListingUserPermissionDefinitionOption =
  | "hasAccessToTaxiContractListing"
  | "hasAccessToDetails"
  | "hasAccessToEditDriver"
  | "hasAccessToDeleteUser"
  | "hasAccessToRestoreUser"
  | "hasVisibleAddDriverButton";

export type DriverListingUserPermissionsDefinition =
  UserPermissionsDefinition<DriverListingUserPermissionDefinitionOption>;

export type DriverListingUserPermissions =
  UserPermissions<DriverListingUserPermissionDefinitionOption>;

const driverListingUserPermissionDefinition: DriverListingUserPermissionsDefinition =
  {
    hasAccessToTaxiContractListing: [
      UserRole.DEALER,
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.TAXI_OFFICER,
    ],
    hasAccessToDetails: [
      UserRole.DEALER,
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
    ],
    hasAccessToEditDriver: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToRestoreUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasVisibleAddDriverButton: [
      UserRole.ADMIN,
      UserRole.DEALER,
      UserRole.OPERATOR,
      UserRole.RAILY_OFFICER,
      UserRole.TAXI_OFFICER,
    ],
  };

export default driverListingUserPermissionDefinition;
