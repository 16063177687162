import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import { OrderOptimizerDetailsItemStepsOrdersPoints } from "../../../common/types/order-optimizer-details-item";
import { useAppContext } from "../../../../../../context/app.context";
import orderOptimizerDetailsHiddenDetailsCenterTablesDriverTableHelper from "./order-optimizer-details-hidden-details-heading-tables-driver-table.helper";
import OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableRow from "../types/order-optimizer-details-hidden-details-center-tables-driver-table-row";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import orderOptimizerDetailsHelper from "../../../order-optimizer-details.helper";

type OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsStepsOrdersPointsItems: OrderOptimizerDetailsItemStepsOrdersPoints[];
};

const OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableComponent: FC<
  OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsHiddenDetailsCenterTablesDriverTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createTableRow = (
    detailsStepsOrdersPointsItem: OrderOptimizerDetailsItemStepsOrdersPoints
  ): OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableRow => {
    const driverTimeLabel = orderOptimizerDetailsHelper.getTimeLabel(
      detailsStepsOrdersPointsItem.driverTime
    );
    const driverTimeTitle = orderOptimizerDetailsHelper.getTimeLabel(
      detailsStepsOrdersPointsItem.driverTime
    );

    const driverDistanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsStepsOrdersPointsItem.driverDistance
    );
    const driverDistanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsStepsOrdersPointsItem.driverDistance
    );

    const driverDrivingTimeLabel =
      orderOptimizerDetailsHelper.getTimeDeltaLabel(
        detailsStepsOrdersPointsItem.driverDrivingTime
      );
    const driverDrivingTimeTitle =
      orderOptimizerDetailsHelper.getTimeDeltaLabel(
        detailsStepsOrdersPointsItem.driverDrivingTime
      );

    const driverHaltingTimeLabel =
      orderOptimizerDetailsHelper.getTimeDeltaLabel(
        detailsStepsOrdersPointsItem.driverHaltingTime
      );
    const driverHaltingTimeTitle =
      orderOptimizerDetailsHelper.getTimeDeltaLabel(
        detailsStepsOrdersPointsItem.driverHaltingTime
      );

    return {
      id: uuidService.generate(),
      value: {
        driverTime: <div title={driverTimeTitle}>{driverTimeLabel}</div>,
        driverDistance: (
          <div title={driverDistanceTitle}>{driverDistanceLabel}</div>
        ),
        driverDrivingTime: (
          <div title={driverDrivingTimeTitle!}>{driverDrivingTimeLabel}</div>
        ),
        driverHaltingTime: (
          <div title={driverHaltingTimeTitle!}>{driverHaltingTimeLabel}</div>
        ),
      },
    };
  };

  const rows: OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableRow[] =
    useMemo(() => {
      return props.detailsStepsOrdersPointsItems.map(createTableRow);
    }, [props.detailsStepsOrdersPointsItems, selectedAppLanguage]);
  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
      noHeader
    />
  );
};

export default OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableComponent;
