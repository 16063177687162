import BillingModel from "../../../../../common/types/billing-model";
import {
  BillingsTaxiRouteListingResponseDataItem,
  BillingsTaxiRouteListingResponseDataStats,
} from "./api/billings-taxi-route-listing.response";
import BillingsTaxiRouteListingItem from "./types/billings-taxi-route-listing-item";
import BillingsTaxiRouteListingStatsSummary from "./types/billings-taxi-route-listing-stats-summary";

const createListingItemRouteAddress = (route: string[]): string => {
  return route.map((item) => item).join(", ");
};

const createListingItem = (
  responseDataItem: BillingsTaxiRouteListingResponseDataItem
): BillingsTaxiRouteListingItem => {
  return {
    uuid: responseDataItem.id,
    routeId: responseDataItem.human_id,
    cargoOrderInternalIds: responseDataItem.cargo_order_human_ids.join(", "),
    routeStartDate: new Date(responseDataItem.date),
    billingModel: responseDataItem.billing_model as BillingModel,
    sumOfBonuses: responseDataItem.sum_of_bonuses ?? 0,
    sumOfPenalties: responseDataItem.sum_of_penalties ?? 0,
    distance: responseDataItem.distance,
    additionalDistance: responseDataItem.favorable_distance,
    amountForDistance: responseDataItem.amount_for_distance ?? 0,
    baseDistanceRate: responseDataItem.base_distance_rate,
    rateForDistance: responseDataItem.rate_for_distance,
    tollRoadsCost: responseDataItem.amount_for_charge_highways ?? 0,
    totalCost: responseDataItem.all_contributions_amount ?? 0,
    driverName: responseDataItem.driver,
    fleetPartner: responseDataItem.fleet_partner,
    stopoverCost: responseDataItem.amount_for_charge_haltings ?? 0,
    addresses: createListingItemRouteAddress(responseDataItem.route),
  };
};

const createListingItems = (
  responseDataItems: BillingsTaxiRouteListingResponseDataItem[]
): BillingsTaxiRouteListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createStatsSummary = (
  responseStatsSummary: BillingsTaxiRouteListingResponseDataStats
): BillingsTaxiRouteListingStatsSummary => {
  return {
    distance: responseStatsSummary.distance,
    amountForChargeHaltings: responseStatsSummary.amount_for_charge_haltings,
    amountForChargeHighways: responseStatsSummary.amount_for_charge_highways,
    allContributionsAmount: responseStatsSummary.all_contributions_amount,
    sumOfBonuses: responseStatsSummary.sum_of_bonuses,
    sumOfPenalties: responseStatsSummary.sum_of_penalties,
  };
};

const billingsTaxiRouteListingFactory = {
  createListingItems,
  createStatsSummary,
};

export default billingsTaxiRouteListingFactory;
