import { OrderOptimizerPotentialDriversListResponseItem } from "../../../api/raily/order-optimizer/potential-drivers-list/order-optimizer-potential-drivers-list.response";
import { OrderOptimizerPotentialDriversListItem } from "./order-optimizer-potential-drivers-list";

const createItem = (
  responseItem: OrderOptimizerPotentialDriversListResponseItem
): OrderOptimizerPotentialDriversListItem => ({
  driver: responseItem.driver,
  taxiDriverAssociationUuid: responseItem.id,
  saDistance: responseItem.saDistance,
  taxiCorporation: responseItem.taxiCorporation,
});

const create = (
  responseData: OrderOptimizerPotentialDriversListResponseItem[]
): OrderOptimizerPotentialDriversListItem[] => {
  return responseData.map(createItem);
};

const orderOptimizerPotentialDriversListFactory = {
  create,
};

export default orderOptimizerPotentialDriversListFactory;
