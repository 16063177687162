import { FC } from "react";
import { CargoCargoTaxiContractListingItem } from "../common/types/cargo-cargo-taxi-contract-listing-item";
import CargoCargoTaxiContractListingTableRow from "../common/types/cargo-cargo-taxi-contract-listing-table-row";
import CargoCargoTaxiContractListingTableColumn from "../common/types/cargo-cargo-taxi-contract-listing-table-column";
import cargoCompanyListingTableHelper from "./cargo-cargo-taxi-contract-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import cargoCargoTaxiContractListingHelper from "../cargo-cargo-taxi-contract-listing.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

type CargoCompanyListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: CargoCargoTaxiContractListingItem[];
};

const CargoCargoTaxiContractListingTableComponent: FC<
  CargoCompanyListingTableProps
> = (props) => {
  const columns: CargoCargoTaxiContractListingTableColumn[] =
    cargoCompanyListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: CargoCargoTaxiContractListingItem
  ): CargoCargoTaxiContractListingTableRow => {
    const validToLabel =
      cargoCargoTaxiContractListingHelper.getDateInputFormattedDate(
        listingItem.validTo
      );
    const validToTitle =
      cargoCargoTaxiContractListingHelper.getDateInputFormattedDate(
        listingItem.validTo
      );

    const validSinceLabel =
      cargoCargoTaxiContractListingHelper.getDateInputFormattedDate(
        listingItem.validSince
      );
    const validSinceTitle =
      cargoCargoTaxiContractListingHelper.getDateInputFormattedDate(
        listingItem.validSince
      );

    const activityTitle =
      cargoCargoTaxiContractListingHelper.getActivityStatusTitle(
        listingItem.isValid
      );
    const allowChargeDuringRideTitle =
      cargoCargoTaxiContractListingHelper.getPermissionStatusTitle(
        listingItem.allowChargeDuringRide
      );
    const allowChargeWhileApproachingTitle =
      cargoCargoTaxiContractListingHelper.getPermissionStatusTitle(
        listingItem.allowChargeWhileApproaching
      );
    const allowChargeWhileReturningTitle =
      cargoCargoTaxiContractListingHelper.getPermissionStatusTitle(
        listingItem.allowChargeWhileReturning
      );
    const publishingAllowedTitle =
      cargoCargoTaxiContractListingHelper.getPermissionStatusTitle(
        listingItem.publishingAllowed
      );

    const discountLimitTitle =
      cargoCargoTaxiContractListingHelper.getDiscountLimitLabel(
        listingItem.discountLimit
      );
    const discountLimitLabel =
      cargoCargoTaxiContractListingHelper.getDiscountLimitLabel(
        listingItem.discountLimit
      );

    const distanceRateTitle =
      cargoCargoTaxiContractListingHelper.getDistanceRateLabel(
        listingItem.distanceRate
      );
    const distanceRateLabel =
      cargoCargoTaxiContractListingHelper.getDistanceRateLabel(
        listingItem.distanceRate
      );

    const haltingTimeAfterMinutesTitle =
      cargoCargoTaxiContractListingHelper.getHaltingTimeAfterMinutesLabel(
        listingItem.haltingTimeAfterMinutes
      );
    const haltingTimeAfterMinutesLabel =
      cargoCargoTaxiContractListingHelper.getHaltingTimeAfterMinutesLabel(
        listingItem.haltingTimeAfterMinutes
      );

    const haltingTimeRateTitle =
      cargoCargoTaxiContractListingHelper.getHaltingTimeRateLabel(
        listingItem.haltingTimeRate
      );
    const haltingTimeRateLabel =
      cargoCargoTaxiContractListingHelper.getHaltingTimeRateLabel(
        listingItem.haltingTimeRate
      );

    const today = new Date();

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const sevenDaysAhead = new Date(today);
    sevenDaysAhead.setDate(sevenDaysAhead.getDate() + 7);

    const validToClassName =
      listingItem.validTo >= oneMonthAgo &&
      listingItem.validTo <= sevenDaysAhead
        ? "cargo_cargo_taxi_contract__valid_to"
        : "";

    return {
      id: listingItem.id,
      value: {
        active: (
          <div title={activityTitle}>
            {listingItem.isValid ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        allowChargeDuringRide: (
          <div title={allowChargeDuringRideTitle}>
            {listingItem.allowChargeDuringRide ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        allowChargeWhileApproaching: (
          <div title={allowChargeWhileApproachingTitle}>
            {listingItem.allowChargeWhileApproaching ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        allowChargeWhileReturning: (
          <div title={allowChargeWhileReturningTitle}>
            {listingItem.allowChargeWhileReturning ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        cargoCompany: (
          <div title={listingItem.cargoCompany.companyName}>
            {listingItem.cargoCompany.companyName}
          </div>
        ),
        discountLimit: (
          <div title={discountLimitLabel}>{discountLimitTitle}</div>
        ),
        distanceRate: <div title={distanceRateTitle}>{distanceRateLabel}</div>,
        haltingTimeAfterMinutes: (
          <div title={haltingTimeAfterMinutesTitle}>
            {haltingTimeAfterMinutesLabel}
          </div>
        ),
        haltingTimeRate: (
          <div title={haltingTimeRateTitle}>{haltingTimeRateLabel}</div>
        ),
        model: <div title={listingItem.model}>{listingItem.model}</div>,
        publishingAllowed: (
          <div title={publishingAllowedTitle}>
            {listingItem.allowChargeWhileReturning ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        taxiCorporation: (
          <div title={listingItem.taxiCorporation.companyName}>
            {listingItem.taxiCorporation.companyName}
          </div>
        ),
        validTo: (
          <div title={validToTitle} className={validToClassName}>
            {validToLabel}
          </div>
        ),
        validSince: <div title={validSinceTitle}>{validSinceLabel}</div>,
      },
    };
  };

  const rows: CargoCargoTaxiContractListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default CargoCargoTaxiContractListingTableComponent;
