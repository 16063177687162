import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import dateService from "../../../../../../common/utils/date/date.service";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import { useAppContext } from "../../../../../../context/app.context";
import { OrderOptimizerDetailsItemStepsHomeToStart } from "../../../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableRow from "../types/order-optimizer-details-hidden-details-heading-tables-driver-table-row";
import orderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableHelper from "./order-optimizer-details-hidden-details-heading-tables-driver-table.helper";

type OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsStepsHomeToStartItem: OrderOptimizerDetailsItemStepsHomeToStart | null;
};

const OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableComponent: FC<
  OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createTableRow = (
    detailsStepsHomeToStartItem: OrderOptimizerDetailsItemStepsHomeToStart
  ): OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableRow => {
    const totalTimeLabel = dateService.formatTime(
      detailsStepsHomeToStartItem.driverTime
    );
    const totalTimeTitle = dateService.formatTime(
      detailsStepsHomeToStartItem.driverTime
    );

    return {
      id: uuidService.generate(),
      value: {
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title="00:00 h">00:00 h</div>,
        totalDrivingTime: <div title="00:00 h">00:00 h</div>,
        distance: <div title="0.0 KM">0.0 KM</div>,
      },
    };
  };

  const rows: OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableRow[] =
    useMemo(() => {
      if (!props.detailsStepsHomeToStartItem) return [];

      const orderRows = createTableRow(props.detailsStepsHomeToStartItem);

      return [orderRows];
    }, [props.detailsStepsHomeToStartItem, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsHiddenDetailsHeadingTablesDriverTableComponent;
