import OrderOptimizerPotentialDriversList, {
  OrderOptimizerPotentialDriversListItem,
} from "../../../../../../common/services/order-optimizer/potential-drivers-list/order-optimizer-potential-drivers-list";
import { OrderOptimizerDetailsHiddenDetailsDriverListItem } from "../types/order-optimizer-details-hidden-details-driver-list";

const createPotentialDriverListItem = (
  item: OrderOptimizerPotentialDriversListItem
): OrderOptimizerDetailsHiddenDetailsDriverListItem => {
  return {
    driver: item.driver,
    taxiDriverAssociationUuid: item.taxiDriverAssociationUuid,
    saDistance: item.saDistance,
    taxiCorporation: item.taxiCorporation,
  };
};

const createPotentialDriverList = (
  response: OrderOptimizerPotentialDriversList
): OrderOptimizerDetailsHiddenDetailsDriverListItem[] => {
  return response.map(createPotentialDriverListItem);
};

const orderOptimizerDetailsHiddenDetailsDriverListFactory = {
  createPotentialDriverList,
};

export default orderOptimizerDetailsHiddenDetailsDriverListFactory;
