import DriverDownloadParams, {
  DriverDownloadParamsOrder,
} from "../../../../../common/services/driver/download/driver-download-params";
import DriverListingFilter, {
  DriverListingActivityStatusFilter,
  DriverListingCandidatureActivityFilter,
  DriverListingDriverCreationDateFilter,
  DriverListingDriverModificationDateFilter,
  DriverListingFirstNameFilter,
  DriverListingFleetPartnerFilter,
  DriverListingLastNameFilter,
  DriverListingStartingAddressFilter,
  DriverListingTaxiCorporationFilter,
} from "./types/driver-listing-filter";
import DriverListingFilterType from "./types/driver-listing-filter-type";
import DriverListingSortKey from "./types/driver-listing-sort-key";

const createOrder = (
  sortKey: DriverListingSortKey | null
): DriverDownloadParamsOrder | undefined => {
  const definition: Record<DriverListingSortKey, DriverDownloadParamsOrder> = {
    [DriverListingSortKey.FIRST_NAME_ASC]:
      DriverDownloadParamsOrder.FIRST_NAME_ASC,
    [DriverListingSortKey.FIRST_NAME_DESC]:
      DriverDownloadParamsOrder.FIRST_NAME_DESC,
    [DriverListingSortKey.LAST_NAME_ASC]:
      DriverDownloadParamsOrder.LAST_NAME_ASC,
    [DriverListingSortKey.LAST_NAME_DESC]:
      DriverDownloadParamsOrder.LAST_NAME_DESC,
    [DriverListingSortKey.FLEET_PARTNER_ASC]:
      DriverDownloadParamsOrder.FLEET_PARTNER_NAME_ASC,
    [DriverListingSortKey.FLEET_PARTNER_DESC]:
      DriverDownloadParamsOrder.FLEET_PARTNER_NAME_DESC,
    [DriverListingSortKey.TAXI_CORPORATION_ASC]:
      DriverDownloadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_ASC,
    [DriverListingSortKey.TAXI_CORPORATION_DESC]:
      DriverDownloadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_DESC,
    [DriverListingSortKey.LAST_PLAN_ENTRY_END_TIME_ASC]:
      DriverDownloadParamsOrder.LAST_PLAN_ENTRY_END_TIME_ASC,
    [DriverListingSortKey.LAST_PLAN_ENTRY_END_TIME_DESC]:
      DriverDownloadParamsOrder.LAST_PLAN_ENTRY_END_TIME_DESC,
    [DriverListingSortKey.CREATION_DATE_ASC]:
      DriverDownloadParamsOrder.CREATED_AT_ASC,
    [DriverListingSortKey.CREATION_DATE_DESC]:
      DriverDownloadParamsOrder.CREATED_AT_DESC,
    [DriverListingSortKey.MODIFICATION_DATE_ASC]:
      DriverDownloadParamsOrder.MODIFIED_AT_ASC,
    [DriverListingSortKey.MODIFICATION_DATE_DESC]:
      DriverDownloadParamsOrder.MODIFIED_AT_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  filters: DriverListingFilter[],
  sortKey: DriverListingSortKey | null
): DriverDownloadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === DriverListingFilterType.ACTIVITY_STATUS
  )?.value as DriverListingActivityStatusFilter["value"] | undefined;

  const creationDateFilter = filters.find(
    (filter) => filter.type === DriverListingFilterType.CREATION_DATE
  )?.value as DriverListingDriverCreationDateFilter["value"] | undefined;

  const modificationDateFilter = filters.find(
    (filter) => filter.type === DriverListingFilterType.MODIFICATION_DATE
  )?.value as DriverListingDriverModificationDateFilter["value"] | undefined;

  return {
    creationStart: creationDateFilter?.from,
    creationEnd: creationDateFilter?.to,
    modificationStart: modificationDateFilter?.from,
    modificationEnd: modificationDateFilter?.to,
    firstName: filters.find(
      (filter) => filter.type === DriverListingFilterType.FIRST_NAME
    )?.value as DriverListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === DriverListingFilterType.LAST_NAME
    )?.value as DriverListingLastNameFilter["value"] | undefined,
    fleetPartner: filters.find(
      (filter) => filter.type === DriverListingFilterType.FLEET_PARTNER
    )?.value as DriverListingFleetPartnerFilter["value"] | undefined,
    address: filters.find(
      (filter) => filter.type === DriverListingFilterType.STARTING_ADDRESS
    )?.value as DriverListingStartingAddressFilter["value"] | undefined,
    pendingCandidature: filters.find(
      (filter) => filter.type === DriverListingFilterType.CANDIDATURE_ACTIVITY
    )?.value as DriverListingCandidatureActivityFilter["value"] | undefined,
    order: createOrder(sortKey),
    statusCriteria: statusFilter,
    taxiCorporation: filters.find(
      (filter) => filter.type === DriverListingFilterType.TAXI_CORPORATION
    )?.value as DriverListingTaxiCorporationFilter["value"] | undefined,
  };
};

const driverDownloadParamsFactory = {
  create,
};

export default driverDownloadParamsFactory;
