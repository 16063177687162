import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import DateRange from "../../../../../common/types/date-range";
import dateService from "../../../../../common/utils/date/date.service";
import taxiCargoContractEditFormValidationService from "../taxi-cargo-contract-edit-form-validation.service";
import TaxiCargoContractEditFormData from "./types/taxi-cargo-contract-edit-form-data";

const getEmptyFormData = (): TaxiCargoContractEditFormData => {
  return {
    isActive: null,
    contactPassengerAfterMinutes: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<TaxiCargoContractEditFormData> => {
    return {
      isActive: (values) =>
        taxiCargoContractEditFormValidationService.validateActivity(
          values.isActive
        ),
      contactPassengerAfterMinutes: (values) =>
        taxiCargoContractEditFormValidationService.validateContactPassengerAfterMinutes(
          values.contactPassengerAfterMinutes
        ),
    };
  };

const getPeriodOfValidityLabel = (
  periodOfValidity: DateRange | undefined
): string => {
  if (!periodOfValidity) {
    return "";
  }

  return `${dateService.formatDate(
    periodOfValidity.from
  )} - ${dateService.formatDate(periodOfValidity.to)}`;
};

const taxiCargoContractEditFormHelper = {
  getEmptyFormData,
  getValidationDefinition,
  getPeriodOfValidityLabel,
};

export default taxiCargoContractEditFormHelper;
