import useAsyncData from "../../../hooks/use-async-data";
import contractService from "../contract.service";
import CargoTaxiContractList from "./cargo-taxi-contract-list";
import CargoTaxiContractListLoadParams from "./cargo-taxi-contract-list-load-params";

const useCargoTaxiContractList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoTaxiContractList>({ data: [], totalCount: 0 });

  const load = async (
    params: CargoTaxiContractListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const cargoTaxiContractList: CargoTaxiContractList =
        await contractService.getCargoTaxiContractList(params, signal);

      setData(cargoTaxiContractList);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useCargoTaxiContractList;
