import DelegationListRequest, {
  DelegationListQueryParams,
} from "./delegation-list.request";

const createQueryParams = (
  request: DelegationListRequest
): DelegationListQueryParams => {
  return {
    offset: request.offset,
    limit: request.limit,
    order: request.order,
    companyId: request.companyUuid,
    cargoCompanyName: request.cargoCompanyName,
    costMax: request.costMax,
    costMin: request.costMin,
    distanceMax: request.totalDistanceMax,
    distanceMin: request.totalDistanceMin,
    createdDateSince: request.creationDateSince,
    createdDateTo: request.creationDateTo,
    delegationNumber: request.delegationNumber,
    endDateSince: request.endDateSince,
    endDateTo: request.endDateTo,
    firstCsvDownloadDateSince: request.firstCsvDownloadDateSince,
    firstCsvDownloadDateTo: request.firstCsvDownloadDateTo,
    firstPdfDownloadDateSince: request.firstPdfDownloadDateSince,
    firstPdfDownloadDateTo: request.firstPdfDownloadDateTo,
    startDateSince: request.startDateSince,
    startDateTo: request.startDateTo,
    type: request.type,
    workerName: request.workerName,
    state: request.state,
  };
};

const delegationListRequstFactory = {
  createQueryParams,
};

export default delegationListRequstFactory;
