import OrderOptimizerListRequest from "../../../api/raily/order-optimizer/list/order-optimizer-list.request";
import paginationService from "../../../utils/pagination/pagination.service";
import OrderOptimizerListLoadParams from "./order-optimizer-list-load-paramts";

const create = (
  params: OrderOptimizerListLoadParams
): OrderOptimizerListRequest => {
  const offset =
    params.page && params.pageSize
      ? paginationService.calculateOffset(params.page, params.pageSize)
      : undefined;

  return {
    limit: params.pageSize,
    offset: offset,
    orderHumanId: params.orderHumanId,
  };
};

const orderOptimizerListRequestFactory = { create };

export default orderOptimizerListRequestFactory;
