enum CargoCargoTaxiContractListingSortKey {
  VALID_SINCE_ASC = "VALID_SINCE_ASC",
  VALID_SINCE_DESC = "VALID_SINCE_DESC",
  VALID_TO_ASC = "VALID_TO_ASC",
  VALID_TO_DESC = "VALID_TO_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
}

export default CargoCargoTaxiContractListingSortKey;
