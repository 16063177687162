import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import LinkButtonComponent from "../../../common/components/button/link/link-button.component";
import CardComponent from "../../../common/components/card/card.component";
import HeadingComponent from "../../../common/components/heading/heading.component";
import useClientSidePagination from "../../../common/hooks/use-clientside-pagination";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import notificationService from "../../../common/utils/notification/notification.service";
import { useAppContext } from "../../../context/app.context";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import orderBreadcrumbsHelper from "../common/breadcrumbs/order-breadcrumbs.helper";
import orderRoutesHelper from "../common/routes/order-routes.helper";
import orderActiveListingFilteringService from "./common/order-active-listing-filtering.service";
import OrderActiveListingFilter from "./common/types/order-active-listing-filter";
import OrderActiveListingSortKey from "./common/types/order-active-listing-sort-key";
import useIsComponentMounted from "../../../common/hooks/use-is-component-mounted";
import OrderActiveListingTableComponent from "./table/order-active-listing-table.component";
import OrderActiveListingJoinTargetOrderData from "./common/types/order-active-listing-join-target-order-data";
import OrderActiveListingJoinSourceOrderData from "./common/types/order-active-listing-join-source-order-data";
import ButtonComponent from "../../../common/components/button/button.component";
import OrderActiveListingOrderJoinComponent from "./order-join/order-active-listing-order-join.component";
import useAbort from "../../../common/hooks/use-abort";
import ListingPaginationComponent from "../../../common/components/listing/pagination/listing-pagination.component";
import OrderActiveListingFilterComponent from "./filter/order-active-listing-filter.component";
import useOrderActiveListingFilterDao from "./filter/use-order-active-listing-filter.dao";
import useOrderActiveListingUserPermissions from "./common/user-permissions/use-order-active-listing-user-permission";
import orderActiveListingSortingService from "./common/order-active-listing-sorting.service";
import useRouteQueryParams from "../../../common/hooks/use-route-query-params";
import OrderActiveListingRouteQueryParams from "../common/routes/types/order-active-listing-route-params";
import useCargoOrderActiveList from "../../../common/services/cargo-order/active-list/use-cargo-order-active-list";
import { CargoOrderActiveListItem } from "../../../common/services/cargo-order/active-list/cargo-order-active-list";
import StatusLegendComponent from "../../../common/components/status-legend/status-legend.component";
import orderActiveListingHelper from "./common/order-active-listing.helper";

type OrderActiveListingProps = {};

const OrderActiveListingComponent: FC<OrderActiveListingProps> = () => {
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderActiveListingRouteQueryParams>();

  const userPermissions = useOrderActiveListingUserPermissions();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderActiveListing);

  const translations = orderTranslationsHelper.getActiveListingTranslations();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getActiveListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const cargoOrderActiveList = useCargoOrderActiveList();

  const filtersDao = useOrderActiveListingFilterDao();

  const [filters, setFilters] = useState<OrderActiveListingFilter[]>(
    filtersDao.getFilters()
  );
  const [sortKey, setSortKey] = useState<OrderActiveListingSortKey>(
    filtersDao.getSortKey() ?? OrderActiveListingSortKey.TIME_TO_START_ASC
  );

  const [expandedRowsOrderUuids, setExpandedRowsOrderUuids] = useState<
    string[]
  >([]);

  const listingItems: CargoOrderActiveListItem[] = useMemo(() => {
    const _filteredListingItems =
      orderActiveListingFilteringService.filterListingItems(
        cargoOrderActiveList.data.data,
        filters
      );

    const sortedListingItems =
      orderActiveListingSortingService.sortListingItems(
        _filteredListingItems,
        sortKey
      );

    return sortedListingItems;
  }, [cargoOrderActiveList.data.data, filters, sortKey]);

  const [isOrderJoinEnabled, setIsOrderJoinEnabled] = useState(false);
  const [selectedJoinTargetOrderData, setSelectedJoinTargetOrderData] =
    useState<OrderActiveListingJoinTargetOrderData | null>(null);
  const [selectedJoinSourceOrdersData, setSelectedJoinSourceOrdersData] =
    useState<OrderActiveListingJoinSourceOrderData[]>([]);

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: listingItems,
      totalResults: listingItems.length,
      defaultPageSize: filtersDao.getPageSize(),
      defaultPage: filtersDao.getPage(),
    });

  const [shouldOpenDefaultSelectedOrder, setShouldOpenDefaultSelectedOrder] =
    useState(false);

  const removeDefaultSelectedOrderUuidParam = () => {
    const newSearchParams = { ...routeQueryParams };
    delete newSearchParams.defaultSelectedOrderUuid;
    setRouteQueryParams(newSearchParams);
  };

  useEffect(() => {
    if (
      !routeQueryParams.defaultSelectedOrderUuid ||
      !listingItems.length ||
      shouldOpenDefaultSelectedOrder
    ) {
      return;
    }

    const getPage = () => {
      const orderListingItem = listingItems.find(
        (listingItem) =>
          listingItem.uuid === routeQueryParams.defaultSelectedOrderUuid
      );

      if (!orderListingItem) {
        return undefined;
      }

      const indexOfListingItemWithOrder =
        listingItems.indexOf(orderListingItem);

      if (indexOfListingItemWithOrder === -1) {
        return undefined;
      }

      return Math.ceil((indexOfListingItemWithOrder + 1) / pageSize);
    };

    const pageWithSearchingOrder = getPage();

    if (!pageWithSearchingOrder) {
      removeDefaultSelectedOrderUuidParam();
      return;
    }

    if (pageWithSearchingOrder === page) {
      setShouldOpenDefaultSelectedOrder(true);
    } else {
      setPage(pageWithSearchingOrder);
    }
  }, [
    listingItems,
    page,
    routeQueryParams.defaultSelectedOrderUuid,
    shouldOpenDefaultSelectedOrder,
  ]);

  const navigationType = (
    window.performance.getEntriesByType(
      "navigation"
    )[0] as PerformanceNavigationTiming
  ).type;

  useEffect(() => {
    if (
      shouldOpenDefaultSelectedOrder &&
      routeQueryParams.defaultSelectedOrderUuid
    ) {
      if (navigationType !== "reload") {
        const tableRowWithSelectedOrder = document.querySelector(
          `[data-id="${routeQueryParams.defaultSelectedOrderUuid}"]`
        ) as HTMLDivElement | null;

        if (!tableRowWithSelectedOrder) {
          removeDefaultSelectedOrderUuidParam();
          setShouldOpenDefaultSelectedOrder(false);
          return;
        }

        window.scrollTo({
          top: tableRowWithSelectedOrder.getBoundingClientRect().top - 50,
          behavior: "smooth",
        });
      }

      setExpandedRowsOrderUuids([routeQueryParams.defaultSelectedOrderUuid!]);
    }
  }, [
    shouldOpenDefaultSelectedOrder,
    routeQueryParams.defaultSelectedOrderUuid,
  ]);

  const listingItemsAbort = useAbort();

  const loadListingItems = () => {
    cargoOrderActiveList.load({}, listingItemsAbort.signal);
  };

  useEffect(() => {
    loadListingItems();
  }, []);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    filtersDao.saveFilters(filters, sortKey, page, pageSize);
  }, [filters, sortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setPage(1);
  }, [filters, pageSize, sortKey]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setExpandedRowsOrderUuids([]);
  }, [sortKey]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    window.scroll({ top: 0, behavior: "smooth" });
    setExpandedRowsOrderUuids([]);
  }, [page]);

  const onCancelOrderSuccess = () => {
    loadListingItems();
  };

  const onAddCandidatureSuccess = () => {
    loadListingItems();
  };

  const createHeadingActions = (): ReactNode[] => {
    const PlannerLink = (
      <LinkButtonComponent
        to={"#"}
        type="primary"
        title={translations.header.planLinkTitle}
        isDisabled
        idForTesting="order-active-listing-planner-link-button"
      >
        {translations.header.planLinkLabel}
      </LinkButtonComponent>
    );

    const OrderAddLink = (
      <LinkButtonComponent
        to={orderRoutesHelper.getAddRoute()}
        type="primary"
        title={translations.header.addNewOrderLinkTitle}
        idForTesting="order-active-listing-order-add-button"
      >
        {translations.header.addNewOrderLinkLabel}
      </LinkButtonComponent>
    );

    const onOrderJoinButtonClick = () => {
      if (isOrderJoinEnabled) {
        setIsOrderJoinEnabled(false);
        return;
      }

      setIsOrderJoinEnabled(true);
      notificationService.info(translations.orderJoin.tipNotificationLabel);
    };

    const OrderJoinButton = (
      <ButtonComponent
        type={isOrderJoinEnabled ? "success" : "primary"}
        title={
          isOrderJoinEnabled
            ? translations.header.joinOrderEnabledButtonTitle
            : translations.header.joinOrderButtonTitle
        }
        onClick={onOrderJoinButtonClick}
        idForTesting="order-active-listing-order-join-button"
      >
        {translations.header.joinOrderButtonLabel}
      </ButtonComponent>
    );

    const actions: ReactNode[] = [
      userPermissions.hasVisiblePlannerLink ? PlannerLink : undefined,
      userPermissions.hasVisibleOrderAddLink ? OrderAddLink : undefined,
      userPermissions.hasVisibleOrderJoinButton ? OrderJoinButton : undefined,
    ];

    return actions;
  };

  const headingActions = createHeadingActions();

  const isJoinVisible = !!selectedJoinTargetOrderData;

  const statusOptions = useMemo(
    () => orderActiveListingHelper.getStatusOptions(pageData),
    [pageData]
  );

  return (
    <>
      <HeadingComponent
        title={translations.header.headingLabel}
        actions={headingActions}
      />
      <StatusLegendComponent statusData={statusOptions} />
      <OrderActiveListingFilterComponent
        filters={filters}
        onFiltersChange={setFilters}
        onSortKeyChange={setSortKey}
        sortKey={sortKey}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <OrderActiveListingTableComponent
          listingItems={pageData}
          isError={cargoOrderActiveList.isError}
          isLoading={cargoOrderActiveList.isLoading}
          expandedRowsUuids={expandedRowsOrderUuids}
          onExpandedRowsUuidsChange={setExpandedRowsOrderUuids}
          selectedJoinSourceOrdersData={selectedJoinSourceOrdersData}
          onSelectedJoinSourceOrdersDataChange={setSelectedJoinSourceOrdersData}
          selectedJoinTargetOrderData={selectedJoinTargetOrderData}
          onSelectedJoinTargetOrderDataChange={setSelectedJoinTargetOrderData}
          isOrderJoinEnabled={isOrderJoinEnabled}
          onCancelOrderSuccess={onCancelOrderSuccess}
          onAddCandidatureSuccess={onAddCandidatureSuccess}
        />
        <ListingPaginationComponent
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          page={page}
          pageSize={pageSize}
          totalResults={totalResults}
        />
      </CardComponent>
      {isJoinVisible && isOrderJoinEnabled && (
        <OrderActiveListingOrderJoinComponent
          targetOrderData={selectedJoinTargetOrderData}
          sourceOrdersData={selectedJoinSourceOrdersData}
          onCancel={() => {
            setSelectedJoinSourceOrdersData([]);
            setSelectedJoinTargetOrderData(null);
            setIsOrderJoinEnabled(false);
          }}
        />
      )}
    </>
  );
};

export default OrderActiveListingComponent;
