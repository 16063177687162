import { FC } from "react";
import orderOptimizerTranslationsHelper from "../../../../../../../languages/order-optimizer-translations.helper";
import {
  OrderOptimizerDetailsItemDriver,
  OrderOptimizerDetailsItemStepsOrders,
} from "../../../../common/types/order-optimizer-details-item";
import orderOptimizerDetailsHelper from "../../../../order-optimizer-details.helper";
import TableLinkButtonComponent from "../../../../../../../common/components/table/button/link/table-link-button.component";
import { faMap } from "@fortawesome/free-regular-svg-icons";

type OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersStepSummaryProps = {
  driver: OrderOptimizerDetailsItemDriver | null;
  step: OrderOptimizerDetailsItemStepsOrders;
};

const OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersStepSummaryComponent: FC<
  OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersStepSummaryProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table
      .centerTables;

  const totalDriverTime = props.step.totalTime;
  const totalHaltingTime = props.step.haltingTime;
  const totalDistance = props.step.distance;
  const lastPoint = props.step.points[props.step.points.length - 1];

  return (
    <div className="content">
      <div className="human_id" />
      <div className="address" title={translations.summaryLabel}>
        {translations.summaryLabel}
      </div>
      <div className="driver_time">
        {orderOptimizerDetailsHelper.getTimeDeltaLabel(totalDriverTime)}
      </div>
      <div className="driver_halting_time">
        {orderOptimizerDetailsHelper.getTimeDeltaLabel(totalHaltingTime)}
      </div>
      <div className="driver_distance">
        {orderOptimizerDetailsHelper.getDistanceLabel(totalDistance)}
      </div>
      <div className="actions">
        <TableLinkButtonComponent
          icon={faMap}
          openInNewTab
          to={orderOptimizerDetailsHelper.getGoogleMapsUrl(
            [lastPoint],
            props.driver?.startLat,
            props.driver?.startLon,
            false,
            true
          )}
          type="primary"
        />
      </div>
    </div>
  );
};

export default OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersStepSummaryComponent;
