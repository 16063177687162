import orderOptimizerTranslationsHelper from "../../../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableColumn from "../types/order-optimizer-details-hidden-details-heading-tables-order-table-column";

const getColumns = (): OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableColumn[] => {

  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.headingTables.order;

  const tableColumns: OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "humanId",
      colSpan: 2,
    },
    {
      header: translations.addressLabel,
      title: translations.addressTitle,
      accessor: "address",
      colSpan: 5,
    },
    {
      header: translations.totalTimeLabel,
      title: translations.totalTimeTitle,
      accessor: "totalTime",
      colSpan: 3,
    },
    {
      header: translations.haltingTimeLabel,
      title: translations.haltingTimeTitle,
      accessor: "haltingTime",
      colSpan: 3,
    },
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 3,
    },
  ];

  return tableColumns;
};

const orderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableHelper;
