import CargoTaxiContractListRequest, {
  CargoTaxiContractListRequestQueryParams,
} from "./cargo-taxi-contract-list-request";

const createQueryParams = (
  data: CargoTaxiContractListRequest
): CargoTaxiContractListRequestQueryParams => {
  return {
    active: data.active,
    cargoCompanyName: data.cargoCompanyName,
    limit: data.limit,
    offset: data.offset,
    order: data.order,
    taxiCorporationName: data.taxiCorporationName,
  };
};

const cargoTaxiContractListRequestFactory = { createQueryParams };

export default cargoTaxiContractListRequestFactory;
