import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import ContractValidCollectionLoadParams from "./valid-collection/contract-valid-collection-load-params";
import ContractValidCollectionRequest from "../../api/raily/contracts/valid-collection/contract-valid-collection.request";
import contractValidCollectionRequestFactory from "./valid-collection/contract-valid-collection-request.factory";
import contractApiService from "../../api/raily/contracts/contract-api.service";
import ContractValidCollectionError from "./valid-collection/contract-valid-collection-error";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";
import CargoTaxiContractListLoadParams from "./cargo-taxi-contract-list/cargo-taxi-contract-list-load-params";
import CargoTaxiContractList from "./cargo-taxi-contract-list/cargo-taxi-contract-list";
import CargoTaxiContractListRequest from "../../api/raily/contracts/cargo-taxi-list/cargo-taxi-contract-list-request";
import cargoTaxiContractListRequestFactory from "./cargo-taxi-contract-list/cargo-taxi-contract-list-request.factory";
import CargoTaxiContractListResponse from "../../api/raily/contracts/cargo-taxi-list/cargo-taxi-contract-list.response";
import cargoTaxiContractListFactory from "./cargo-taxi-contract-list/cargo-taxi-contract-list.factory";

const handleListError = (
  error: HttpError | ContractValidCollectionError
): ContractValidCollectionError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getValidCollection = async (
  params: ContractValidCollectionLoadParams,
  abortSignal: AbortSignal
): Promise<any> => {
  const request: ContractValidCollectionRequest =
    contractValidCollectionRequestFactory.create(params);

  try {
    const response = await contractApiService().getValidCollection(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return response;
  } catch (_error) {
    const error = _error as HttpError | ContractValidCollectionError;

    throw handleListError(error);
  }
};

const handleCargoTaxiContractListError = (
  error: HttpError
): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getCargoTaxiContractList = async (
  params: CargoTaxiContractListLoadParams,
  abortSignal: AbortSignal
): Promise<CargoTaxiContractList> => {
  const request: CargoTaxiContractListRequest =
    cargoTaxiContractListRequestFactory.create(params);

  try {
    const response: CargoTaxiContractListResponse =
      await contractApiService().getCargoTaxiContractList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const cargoTaxiContractList: CargoTaxiContractList =
      cargoTaxiContractListFactory.create(response);

    return cargoTaxiContractList;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleCargoTaxiContractListError(error);
  }
};

const contractService = {
  getValidCollection,
  getCargoTaxiContractList,
};

export default contractService;
