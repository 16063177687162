import OrderOptimizerListingRouteQueryParams from "../../common/routes/types/order-optimizer-listing-route-params";
import OrderOptimizerListingFilter, {
  OrderOptimizerListingOrderHumanIdFilter,
} from "../common/types/order-optimizer-listing-filter";
import OrderOptimizerListingFilterType from "../common/types/order-optimizer-listing-filter-type";

const getPage = (
  routeQueryFilterParams: OrderOptimizerListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page;
};

const getPageSize = (
  routeQueryFilterParams: OrderOptimizerListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize;
};

const getFilters = (
  routeQueryFilterParams: OrderOptimizerListingRouteQueryParams
): OrderOptimizerListingFilter[] => {
  const filters: OrderOptimizerListingFilter[] = [];

  if (routeQueryFilterParams.orderHumanId) {
    const orderHumanIdFilter: OrderOptimizerListingOrderHumanIdFilter = {
      type: OrderOptimizerListingFilterType.ORDER_HUMAN_ID,
      value: routeQueryFilterParams.orderHumanId,
    };

    filters.push(orderHumanIdFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: OrderOptimizerListingFilter[],
  page: number,
  pageSize: number
): OrderOptimizerListingRouteQueryParams => {
  const routeQueryParams: OrderOptimizerListingRouteQueryParams = {
    page: page,
    pageSize: pageSize,
    orderHumanId: filters.find(
      (filter) => filter.type === OrderOptimizerListingFilterType.ORDER_HUMAN_ID
    )?.value as OrderOptimizerListingOrderHumanIdFilter["value"],
  };

  return routeQueryParams;
};

const orderOptimizerListingFiltersService = {
  getPage,
  getPageSize,
  getFilters,
  createRouteQueryParams,
};

export default orderOptimizerListingFiltersService;
