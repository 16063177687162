import CargoTaxiContractListLoadParams, {
  CargoTaxiContractListLoadParamsOrder,
} from "../../../../../common/services/contract/cargo-taxi-contract-list/cargo-taxi-contract-list-load-params";
import CargoCargoTaxiContractListingFilter, {
  CargoCargoTaxiContractListingActiveFilter,
  CargoCargoTaxiContractListingCargoCompanyFilter,
  CargoCargoTaxiContractListingTaxiCorporationFilter,
} from "./types/cargo-cargo-taxi-contract-listing-filter";
import CargoCargoTaxiContractListingFilterType from "./types/cargo-cargo-taxi-contract-listing-filter-type";
import CargoCargoTaxiContractListingSortKey from "./types/cargo-cargo-taxi-contract-listing-sort-key";

const createOrder = (
  sortKey: CargoCargoTaxiContractListingSortKey | null
): CargoTaxiContractListLoadParamsOrder | undefined => {
  const definition: Record<
    CargoCargoTaxiContractListingSortKey,
    CargoTaxiContractListLoadParamsOrder
  > = {
    [CargoCargoTaxiContractListingSortKey.DISTANCE_RATE_ASC]:
      CargoTaxiContractListLoadParamsOrder.DISTANCE_RATE_ASC,
    [CargoCargoTaxiContractListingSortKey.DISTANCE_RATE_DESC]:
      CargoTaxiContractListLoadParamsOrder.DISTANCE_RATE_DESC,
    [CargoCargoTaxiContractListingSortKey.VALID_SINCE_ASC]:
      CargoTaxiContractListLoadParamsOrder.VALID_SINCE_ASC,
    [CargoCargoTaxiContractListingSortKey.VALID_SINCE_DESC]:
      CargoTaxiContractListLoadParamsOrder.VALID_SINCE_DESC,
    [CargoCargoTaxiContractListingSortKey.VALID_TO_ASC]:
      CargoTaxiContractListLoadParamsOrder.VALID_TO_ASC,
    [CargoCargoTaxiContractListingSortKey.VALID_TO_DESC]:
      CargoTaxiContractListLoadParamsOrder.VALID_TO_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: CargoCargoTaxiContractListingFilter[],
  sortKey: CargoCargoTaxiContractListingSortKey | null
): CargoTaxiContractListLoadParams => {
  const active = filters.find(
    (filter) => filter.type == CargoCargoTaxiContractListingFilterType.ACTIVE
  )?.value as CargoCargoTaxiContractListingActiveFilter["value"] | undefined;

  return {
    order: createOrder(sortKey),
    page: page,
    pageSize: pageSize,
    active: active,
    cargoCompanyName: filters.find(
      (filter) =>
        filter.type === CargoCargoTaxiContractListingFilterType.CARGO_COMPANY
    )?.value as
      | CargoCargoTaxiContractListingCargoCompanyFilter["value"]
      | undefined,
    taxiCorporationName: filters.find(
      (filter) =>
        filter.type === CargoCargoTaxiContractListingFilterType.TAXI_CORPORATION
    )?.value as
      | CargoCargoTaxiContractListingTaxiCorporationFilter["value"]
      | undefined,
  };
};

const cargoTaxiContractListingLoadParamsFactory = {
  create,
};

export default cargoTaxiContractListingLoadParamsFactory;
