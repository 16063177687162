import useAsyncData from "../../../hooks/use-async-data";
import orderOptimizerService from "../order-optimizer.service";
import OrderOptimizerOptimizationStatus from "../optimization-status/order-optimizer-optimization-status";
import OrderOptimizerList from "./order-optimizer-list";
import OrderOptimizerListLoadParams from "./order-optimizer-list-load-paramts";

const useOrderOptimizerList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<OrderOptimizerList>({ data: [], totalCount: 0 });

  const applyOptimizationStatus = (
    optimizationStatus: OrderOptimizerOptimizationStatus,
    optimizationId: string
  ) => {
    const dataIndex = data.data.findIndex((row) => row.id === optimizationId);

    if (dataIndex === -1) return;

    const dataRow = data.data[dataIndex];

    dataRow.reviewedAt = optimizationStatus.reservedAt;
    dataRow.reviewedBy = optimizationStatus.reservedBy;

    data.data[dataIndex] = dataRow;

    const newData: OrderOptimizerList = {
      data: data.data,
      totalCount: data.totalCount,
    };

    setData(newData);
  };

  const load = async (
    params: OrderOptimizerListLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);

    try {
      const orderOptimizerList = await orderOptimizerService.getList(
        params,
        signal
      );

      setData(orderOptimizerList);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
    applyOptimizationStatus,
  };
};

export default useOrderOptimizerList;
