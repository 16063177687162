const cargoRoutesUrls = {
  companyAdd: `/cargo/add`,
  companyListing: `/cargo/listing`,
  cargoTaxiContractListing: `/cargo-taxi-contract/listing`,
  addressAdd: `/cargo/:cargoCompanyUuid/address/add`,
  addressEdit: `/cargo/:cargoCompanyUuid/address/:addressUuid/edit`,
  addressListing: `/cargo/:cargoCompanyUuid/address/listing`,
  taxiContractAdd: `/cargo/:cargoCompanyUuid/taxi-contract/add`,
  taxiContractListing: `/cargo/:cargoCompanyUuid/taxi-contract/listing`,
  dispatchListing: `/cargo/:cargoCompanyUuid/dispatch/listing`,
  dispatchAdd: `/cargo/:cargoCompanyUuid/dispatch/add`,
  dispatchEdit: `/cargo/:cargoCompanyUuid/dispatch/:dispatchUuid/edit`,
};

export default cargoRoutesUrls;
