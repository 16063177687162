import UserRole from "../../../../common/types/user-role";
import RouteItemDefinition from "../../../../routes/types/route-item-definition";
import CargoAddressListingComponent from "../../address/listing/cargo-address-listing-component";
import CargoCompanyAddComponent from "../../company/company/add/cargo-company-add-component";
import CargoTaxiContractListingComponent from "../../taxi-contract/listing/cargo-taxi-contract-listing-component";
import CargoCompanyListingComponent from "../../company/company/listing/cargo-company-listing.component";
import CargoTaxiContractAddComponent from "../../taxi-contract/add/cargo-taxi-contract-add.component";
import cargoRoutesUrls from "./cargo-routes-urls";
import CargoAddressEditComponent from "../../address/edit/cargo-address-edit.component";
import CargoAddressAddComponent from "../../address/add/cargo-address-add.component";
import CargoDispatchListingComponent from "../../company/dispatch/listing/cargo-dispatch-listing.component";
import CargoDispatchAddComponent from "../../company/dispatch/add/cargo-dispatch-add.component";
import CargoDispatchUpdateComponent from "../../company/dispatch/update/cargo-dispatch-update.component";
import CargoCargoTaxiContractListingComponent from "../../cargo-taxi-contract/listing/cargo-cargo-taxi-contract-listing.component";

const cargoRoutesDefinition: RouteItemDefinition[] = [
  {
    path: cargoRoutesUrls.companyAdd,
    component: <CargoCompanyAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: cargoRoutesUrls.companyListing,
    component: <CargoCompanyListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: cargoRoutesUrls.cargoTaxiContractListing,
    component: <CargoCargoTaxiContractListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
    ],
  },
  {
    path: cargoRoutesUrls.addressAdd,
    component: <CargoAddressAddComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: cargoRoutesUrls.addressEdit,
    component: <CargoAddressEditComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.OPERATOR,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
    ],
  },
  {
    path: cargoRoutesUrls.addressListing,
    component: <CargoAddressListingComponent />,
    userRolesWhitelist: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
  },
  {
    path: cargoRoutesUrls.taxiContractAdd,
    component: <CargoTaxiContractAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: cargoRoutesUrls.taxiContractListing,
    component: <CargoTaxiContractListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: cargoRoutesUrls.dispatchListing,
    component: <CargoDispatchListingComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: cargoRoutesUrls.dispatchAdd,
    component: <CargoDispatchAddComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
  {
    path: cargoRoutesUrls.dispatchEdit,
    component: <CargoDispatchUpdateComponent />,
    userRolesWhitelist: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  },
];

export default cargoRoutesDefinition;
