import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../common/components/table/table.component";
import { useAppContext } from "../../../../context/app.context";
import orderOptimizerDetailsOrdersTableHelper from "./order-optimizer-details-orders-table.helper";
import { OrderOptimizerDetailsItemOrders } from "../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsOrdersTableRow from "../common/types/order-optimizer-details-orders-table-row";
import uuidService from "../../../../common/utils/uuid/uuid.service";
import orderOptimizerDetailsHelper from "../order-optimizer-details.helper";
import OrderOptimizerDetailsOrdersTableDotComponent from "../dot/order-optimizer-details-orders-table-dot.component";
import orderOptimizerHelper from "../../common/order-optimizer.helper";
import TableButtonComponent from "../../../../common/components/table/button/table-button.component";
import { faEye } from "@fortawesome/free-solid-svg-icons";

type OrderOptimizerDetailsOrdersTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsOrderItems?: OrderOptimizerDetailsItemOrders[];
  hiddenOrders?: number[];
  onOrderVisibilityClick: (id: number) => void;
};

const OrderOptimizerDetailsOrdersTableComponent: FC<
  OrderOptimizerDetailsOrdersTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsOrdersTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createTableRow = (
    detailsOrderItems: OrderOptimizerDetailsItemOrders,
    index: number,
    hiddenOrders: number[],
    humanIds: number[]
  ): OrderOptimizerDetailsOrdersTableRow => {
    const distanceLabel = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsOrderItems.distance
    );
    const distanceTitle = orderOptimizerDetailsHelper.getDistanceLabel(
      detailsOrderItems.distance
    );

    const totalTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.totalTime
    );
    const totalTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.totalTime
    );

    const haltingTimeLabel = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.haltingTime
    );
    const haltingTimeTitle = orderOptimizerDetailsHelper.getTimeDeltaLabel(
      detailsOrderItems.haltingTime
    );

    const isButtonVisible =
      props.detailsOrderItems!.length - props.hiddenOrders!.length >= 3 ||
      hiddenOrders.includes(humanIds[index]);
    const isOrderHidden = hiddenOrders.includes(humanIds[index]);
    const dotColor = isOrderHidden
      ? "#808080"
      : orderOptimizerHelper.getOrderColor(index);

    return {
      id: uuidService.generate(),
      className: isOrderHidden
        ? "order_optimizer_details_orders_table__hidden_row"
        : undefined,
      value: {
        humanId: (
          <div className="order_optimizer_details_orders_table__humanId">
            <OrderOptimizerDetailsOrdersTableDotComponent color={dotColor} />
            <span title={String(detailsOrderItems.humanId)}>
              {detailsOrderItems.humanId}
            </span>
          </div>
        ),
        cargoCompany: (
          <div title={detailsOrderItems.cargoCompany}>
            {detailsOrderItems.cargoCompany}
          </div>
        ),
        billingModel: (
          <div title={detailsOrderItems.billingModel}>
            {detailsOrderItems.billingModel}
          </div>
        ),
        taxiCorporation: (
          <div title={detailsOrderItems.taxiCorporation}>
            {detailsOrderItems.taxiCorporation}
          </div>
        ),
        totalTime: <div title={totalTimeTitle}>{totalTimeLabel}</div>,
        haltingTime: <div title={haltingTimeTitle}>{haltingTimeLabel}</div>,
        distance: <div title={distanceTitle}>{distanceLabel}</div>,
        action: (
          <TableButtonComponent
            icon={faEye}
            type={"brand"}
            onClick={() =>
              props.onOrderVisibilityClick(detailsOrderItems.humanId)
            }
            isDisabled={!isButtonVisible}
          />
        ),
      },
    };
  };

  const rows: OrderOptimizerDetailsOrdersTableRow[] = useMemo(() => {
    const detailsOrderItems = props.detailsOrderItems || [];
    const humanIds = detailsOrderItems.map((item) => item.humanId);

    return detailsOrderItems.map((item, index) =>
      createTableRow(item, index, props.hiddenOrders ?? [], humanIds)
    );
  }, [props.detailsOrderItems, props.hiddenOrders, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsOrdersTableComponent;
