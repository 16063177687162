import CargoTaxiContractAddParams from "../../../../../common/services/cargo-taxi-contract/add/cargo-taxi-contract-add-params";
import TaxiCargoContractAddFormData from "./types/taxi-cargo-contract-add-form-data";

const create = (
  formData: TaxiCargoContractAddFormData,
  taxiCorporationUuid: string
): CargoTaxiContractAddParams => {
  return {
    billingModel: formData.billingModel!,
    cargoCompanyUuid: formData.cargoCompanyUuid,
    discountPercentLimit: formData.discountPercentLimit!,
    distanceRate: formData.distanceRate!,
    freeStoppingPeriodMinutes: formData.freeStoppingPeriodMinutes!,
    isActive: formData.isActive!,
    isOrderPublishingAllowed: formData.isOrderPublishingAllowed!,
    isTollRoadsDuringOrderAllowed: formData.isTollRoadsDuringOrderAllowed!,
    isTollRoadsWhileArrivalAllowed: formData.isTollRoadsWhileArrivalAllowed!,
    isTollRoadsWhileReturningAllowed:
      formData.isTollRoadsWhileReturningAllowed!,
    periodOfValidity: formData.periodOfValidity!,
    stoppingRate: formData.stoppingRate!,
    taxiCorporationUuid,
    contactPassengerAfterMinutes: formData.contactPassengerAfterMinutes!,
  };
};

const taxiCargoContractAddParamsFactory = {
  create,
};

export default taxiCargoContractAddParamsFactory;
