import { faIcons, faWrench } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../common/components/breadcrumbs/common/types/breadcrumb";
import orderOptimizerRouteHelper from "../routes/order-optimizer-routes.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import OrderOptimizerDetailsBreadcrumbsParams from "./types/order-optimizer-details-breadcrumbs-params";

const getListingBreadcrumbs = (): Breadcrumb[] => {
  return [
    {
      icon: faIcons,
      linkTo: orderOptimizerRouteHelper.getListingRoute(),
      text: appTranslationsHelper.getBreadcrumbsTranslations()
        .orderOptimizerListing,
    },
  ];
};

const getDetailsBreadcrumbs = (
  params: OrderOptimizerDetailsBreadcrumbsParams
): Breadcrumb[] => {
  const excludedOrders = params.excludedOrders || "all-orders";

  return [
    ...getListingBreadcrumbs(),
    {
      icon: faWrench,
      linkTo: orderOptimizerRouteHelper.getDetailsRoute({
        id: params.id,
        excludedOrders,
      }),
      text: appTranslationsHelper
        .getBreadcrumbsTranslations()
        .orderOptimizerDetails.replace("#{id}", params.id)
        .replace("#{excludedOrders}", excludedOrders),
    },
  ];
};

const orderOptimizerBreadcrumbsHelper = {
  getListingBreadcrumbs,
  getDetailsBreadcrumbs,
};

export default orderOptimizerBreadcrumbsHelper;
