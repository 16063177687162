import { FC, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../context/app.context";
import { useParams } from "react-router-dom";
import taxiTranslationsHelper from "../../../../languages/taxi-translations.helper";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import TaxiCargoContractListingItem from "./common/types/taxi-cargo-contract-listing-item";
import TaxiCargoContractListingTableRow from "./common/types/taxi-cargo-contract-listing-table-row";
import dateService from "../../../../common/utils/date/date.service";
import taxiCargoContractListingHelper from "./common/helper/taxi-cargo-contract-listing.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import useClientSidePagination from "../../../../common/hooks/use-clientside-pagination";
import taxiCargoContractListingApiService from "./common/api/taxi-cargo-contract-listing-api.service";
import TaxiCorporationResponse, {
  TaxiCorporationResponseDataItem,
} from "./common/api/types/taxi-corpotation-response";
import TaxiCargoContractListingResponse from "./common/api/types/taxi-cargo-contract-listing-response";
import taxiCargoContractListingFactory from "./common/factory/taxi-cargo-contract-listing.factory";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import CardComponent from "../../../../common/components/card/card.component";
import TableComponent from "../../../../common/components/table/table.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import taxiBreadcrumbsHelper from "../../common/breadcrumbs/taxi-breadcrumbs.helper";
import TaxiCargoContractListingRouteParams from "../../common/routes/types/taxi-cargo-contract-listing-route-params";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import TaxiCargoContractListingRouteQueryParams from "./common/types/taxi-cargo-contract-listing-route-query-params";
import TaxiCargoContractListingFilter from "./common/types/taxi-cargo-contract-listing-filter";
import taxiCargoContractListingRouteQueryParamsService from "./common/taxi-cargo-contract-listing-route-query-params.service";
import TaxiCargoContractListingSortKey from "./common/types/taxi-cargo-contract-listing-sort-key";
import taxiCargoContractListingFilterService from "./common/filter/taxi-cargo-contract-listing-filter.service";
import taxiCargoContractListingSortService from "./common/sort/taxi-cargo-contract-listing-sort.service";
import TaxiCargoContractListingSortSelectOption from "./common/types/taxi-cargo-contract-listing-sort-select-option";
import taxiCargoContractListingSortHelper from "./common/sort/taxi-cargo-contract-listing-sort.helper";
import TaxiCargoContractListingFiltersSelectComponent from "./filter/select/cargo-taxi-contract-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiCargoContractListingFiltersBadgeListComponent from "./filter/list/taxi-cargo-contract-listing-filters-badge-list.component";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import taxiRoutesHelper from "../../common/routes/taxi-routes.helper";
import TableLinkButtonComponent from "../../../../common/components/table/button/link/table-link-button.component";
import useTaxiCorporationDetails from "../../../../common/services/taxi-corporation/details/use-taxi-corporation-details";
import useAbort from "../../../../common/hooks/use-abort";
import useTaxiCargoContractListingUserPermissions from "./common/user-permissions/use-taxi-cargo-contract-listing-user-permissions";

type TaxiCargoContractListingProps = {};

const TaxiCargoContractListingComponent: FC<
  TaxiCargoContractListingProps
> = () => {
  const isComponentMounted = useIsComponentMounted();

  const userPermissions = useTaxiCargoContractListingUserPermissions();

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiCargoContractListingRouteQueryParams>();

  const { taxiCorporationUuid } =
    useParams<TaxiCargoContractListingRouteParams>();

  const translations =
    taxiTranslationsHelper.getCargoContractListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.taxiCargoContractListing);

  const [filters, setFilters] = useState<TaxiCargoContractListingFilter[]>(() =>
    taxiCargoContractListingRouteQueryParamsService.getFilters(routeQueryParams)
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiCargoContractListingSortKey | null>(
      () =>
        taxiCargoContractListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [taxiCorporationName, setTaxiCorporationName] = useState("");

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);

  const [listingItems, setListingItems] = useState<
    TaxiCargoContractListingItem[]
  >([]);
  const [filteredListingItems, setFilteredListingItems] = useState<
    TaxiCargoContractListingItem[]
  >([]);

  const createTableRow = (
    listingItem: TaxiCargoContractListingItem
  ): TaxiCargoContractListingTableRow => {
    const onTableRowClick = () => {};

    return {
      id: listingItem.uuid,
      onClick: onTableRowClick,
      value: {
        cargoCompany: (
          <div title={listingItem.cargoCompany}>{listingItem.cargoCompany}</div>
        ),
        validSince: (
          <div title={dateService.formatDate(listingItem.validFrom)}>
            {dateService.formatDate(listingItem.validFrom)}
          </div>
        ),
        validTo: (
          <div title={dateService.formatDate(listingItem.validTo)}>
            {dateService.formatDate(listingItem.validTo)}
          </div>
        ),
        billingModel: (
          <div title={listingItem.billingModel}>{listingItem.billingModel}</div>
        ),
        isTollRoadsWhileApproachingAllowed: (
          <div
            title={taxiCargoContractListingHelper.getActivityStatusText(
              listingItem.isTollRoadsWhileApproachingAllowed
            )}
          >
            {listingItem.isTollRoadsWhileApproachingAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        isTollRoadsWhileReturningAllowed: (
          <div
            title={taxiCargoContractListingHelper.getActivityStatusText(
              listingItem.isTollRoadsWhileReturningAllowed
            )}
          >
            {listingItem.isTollRoadsWhileReturningAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        isTollRoadsDuringOrderAllowed: (
          <div
            title={taxiCargoContractListingHelper.getActivityStatusText(
              listingItem.isTollRoadsDuringOrderAllowed
            )}
          >
            {listingItem.isTollRoadsDuringOrderAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        distanceRate: (
          <div
            title={taxiCargoContractListingHelper.getDistanceRateTitle(
              listingItem.distanceRate
            )}
          >
            {taxiCargoContractListingHelper.getDistanceRateLabel(
              listingItem.distanceRate
            )}
          </div>
        ),
        discountPercentLimit: (
          <div
            title={taxiCargoContractListingHelper.getDiscountPercentLimitTitle(
              listingItem.discountPercentLimit
            )}
          >
            {taxiCargoContractListingHelper.getDiscountPercentLimitLabel(
              listingItem.discountPercentLimit
            )}
          </div>
        ),
        stoppingRate: (
          <div
            title={taxiCargoContractListingHelper.getStoppingRateTitle(
              listingItem.stoppingRate
            )}
          >
            {taxiCargoContractListingHelper.getStoppingRateLabel(
              listingItem.stoppingRate
            )}
          </div>
        ),
        freeStoppingPeriodMinutes: (
          <div
            title={translations.table.freeStoppingPeriodContentLabel.replace(
              "#{freeStoppingPeriod}",
              listingItem.freeStoppingPeriodMinutes.toString()
            )}
          >
            {translations.table.freeStoppingPeriodContentLabel.replace(
              "#{freeStoppingPeriod}",
              listingItem.freeStoppingPeriodMinutes.toString()
            )}
          </div>
        ),
        contactPassengerAfterMinutes: (
          <div
          title={taxiCargoContractListingHelper.getContactPassengerAfterMinutesTitle(
            listingItem.contactPassengerAfterMinutes
          )}>
            {taxiCargoContractListingHelper.getContactPassengerAfterMinutesLabel(
              listingItem.contactPassengerAfterMinutes
            )}
          </div>
        ),
        isOrderPublishingAllowed: (
          <div
            title={taxiCargoContractListingHelper.getActivityStatusText(
              listingItem.isOrderPublishingAllowed
            )}
          >
            {listingItem.isOrderPublishingAllowed ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        activityStatus: (
          <div
            title={taxiCargoContractListingHelper.getActivityStatusText(
              listingItem.isActive
            )}
          >
            {listingItem.isActive ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        actions: userPermissions.hasAccessToEditCargoContract && (
          <div className="d-flex">
            <TableLinkButtonComponent
              icon={faEdit}
              to={taxiRoutesHelper.getCargoContractEditRoute({
                contractUuid: listingItem.uuid!,
                taxiCorporationUuid: taxiCorporationUuid!,
              })}
              idForTesting={`taxi-cargo-contract-listing-item-${listingItem.uuid}-edit-button`}
            />
          </div>
        ),
      },
    };
  };

  const tableColumns = taxiCargoContractListingHelper.getTableColumns();

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: filteredListingItems,
      totalResults: filteredListingItems.length,
      defaultPageSize: 100,
    });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiCargoContractListingRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  useEffect(() => {
    fetchTaxiCorporation();
    fetchListing();
  }, [taxiCorporationUuid]);

  const fetchTaxiCorporation = () => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiCargoContractListingApiService
      .fetchTaxiCorporation(taxiCorporationUuid)
      .then(handleTaxiCorporationResponse)
      .catch(onTaxiCorporationFetchFailure);
  };

  const handleTaxiCorporationResponse = (response: TaxiCorporationResponse) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  const onTaxiCorporationFetchSuccess = (
    response: TaxiCorporationResponseDataItem
  ) => {
    const cargoCompanyName = response.company_name;

    setTaxiCorporationName(cargoCompanyName);
  };

  const onTaxiCorporationFetchFailure = () => {};

  const fetchListing = () => {
    if (!taxiCorporationUuid) {
      return;
    }

    setIsListingFetching(true);
    setIsListingFetchingError(false);

    taxiCargoContractListingApiService
      .fetchListing(taxiCorporationUuid)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  const handleListingResponse = (
    response: TaxiCargoContractListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const onListingFetchSuccess = (
    response: TaxiCargoContractListingResponse
  ) => {
    const listingItems = taxiCargoContractListingFactory.createListingItems(
      response.data
    );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const filteredListingItems =
      taxiCargoContractListingFilterService.filterListingItems(
        listingItems,
        filters
      );

    const sortedListingItems =
      taxiCargoContractListingSortService.sortListingItems(
        filteredListingItems,
        selectedSortKey
      );

    setFilteredListingItems(sortedListingItems);
  }, [selectedSortKey, JSON.stringify(filters), JSON.stringify(listingItems)]);

  const taxiDetails = useTaxiCorporationDetails();
  const taxiDetailsAbort = useAbort();

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    taxiDetails.load(
      {
        taxiCorporationUuid,
      },
      taxiDetailsAbort.signal
    );
  }, [taxiCorporationUuid]);

  useEffect(() => {
    if (!taxiCorporationUuid) {
      return;
    }

    const breadcrumbs =
      taxiBreadcrumbsHelper.getCargoContractListingBreadcrumbs({
        taxiCorporationUuid,
        taxiCorporationDisplayName: taxiDetails.data?.displayName,
      });
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, taxiCorporationUuid, taxiDetails.data]);

  const addNewFilter = (newFilter: TaxiCargoContractListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
  };

  const deleteAllFilters = () => {
    setFilters([]);
  };

  const sortSelectOptions: TaxiCargoContractListingSortSelectOption[] = useMemo(
    () => taxiCargoContractListingSortHelper.getSelectOptions(),
    [selectedAppLanguage]
  );

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const tableRows: TaxiCargoContractListingTableRow[] = useMemo(() => {
    return pageData.map((listingItem) => createTableRow(listingItem));
  }, [pageData]);

  const AddNewContractLinkButton = useMemo(
    () => (
      <LinkButtonComponent
        type="primary"
        to={taxiRoutesHelper.getCargoContractAddRoute({
          taxiCorporationUuid: taxiCorporationUuid!,
        })}
        title={translations.header.addContractLinkButtonTitle}
        idForTesting="taxi-cargo-contract-listing-add-button"
      >
        {translations.header.addContractLinkButtonText}
      </LinkButtonComponent>
    ),
    [translations]
  );

  return (
    <div className="taxi_cargo_contract_listing">
      <HeadingComponent
        title={
          taxiCorporationName
            ? translations.header.headingLabelWithParams.replace(
                "#{taxiCorporationName}",
                taxiCorporationName
              )
            : translations.header.headingLabel
        }
        actions={[AddNewContractLinkButton]}
      />
      <div className="driver_contract_listing_tools">
        <TaxiCargoContractListingFiltersSelectComponent
          filters={filters}
          onAddNewFilter={addNewFilter}
        />
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="taxi-cargo-contract-listing-sort"
        />
      </div>
      <TaxiCargoContractListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent>
        <TableComponent
          columns={tableColumns}
          rows={tableRows}
          isLoading={isListingFetching}
          isError={isListingFetchingError}
        />
        <div className="taxi_cargo_contract_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default TaxiCargoContractListingComponent;
