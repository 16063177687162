import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";

const validateDistance = (
  distance: number | null,
  maxDistance: number
): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(maxDistance);
  return formValidationService.validate(distance, validationSchema);
};

const validateRate = (rate: number | null): FormValidationResult => {
  const validationSchema = Joi.number().min(0).max(4.0).precision(2);
  return formValidationService.validate(rate, validationSchema);
};

const orderOptimizerDetailsValidationService = {
  validateRate,
  validateDistance,
};

export default orderOptimizerDetailsValidationService;
