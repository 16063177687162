export enum MileageResultsOrder {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
  WORKER_NAME_ASC = "WORKER_NAME_ASC",
  WORKER_NAME_DESC = "WORKER_NAME_DESC",
  CARD_NO_ASC = "CARD_NO_ASC",
  CARD_NO_DESC = "CARD_NO_DESC",
  DISTANCE_ASC = "DISTANCE_ASC",
  DISTANCE_DESC = "DISTANCE_DESC",
  CARGO_COMPANY_NAME_ASC = "CARGO_COMPANY_NAME_ASC",
  CARGO_COMPANY_NAME_DESC = "CARGO_COMPANY_NAME_DESC",
}

export enum MileageListRequestVehicleType {
  COMPANY_OWNED = "COMPANY_OWNED",
  CAR_GT_900CM3 = "CAR_GT_900CM3",
  CAR_LE_900CM3 = "CAR_LE_900CM3",
  MOTORCYCLE = "MOTORCYCLE",
  MOPED = "MOPED",
}

export enum MileageListRequestState {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DELEGATION_CREATED = "DELEGATION_CREATED",
}

export type MileageListQueryParams = {
  offset: number | undefined;
  limit: number | undefined;
  order: MileageResultsOrder | undefined;
  cargoCompanyName: string | undefined;
  companyId: string | undefined;
  dateSince: Date | undefined;
  dateTo: Date | undefined;
  humanId: number | undefined;
  mileageNumber: string | undefined;
  workerName: string | undefined;
  cardNumber: string | undefined;
  address: string[] | undefined;
  startAddress: string[] | undefined;
  midAddress: string[] | undefined;
  endAddress: string[] | undefined;
  distanceMax: number | undefined;
  distanceMin: number | undefined;
  vehicleType: MileageListRequestVehicleType | undefined;
  state: MileageListRequestState | undefined;
};

type MileageListRequest = {
  offset?: number;
  limit?: number;
  order?: MileageResultsOrder;
  companyId?: string;
  dateSince?: Date;
  dateTo?: Date;
  cargoCompanyName?: string;
  humanId?: number;
  mileageNumber?: string;
  workerName?: string;
  cardNumber?: string;
  address?: string[];
  startAddress?: string[];
  midAddress?: string[];
  endAddress?: string[];
  distanceMax?: number;
  distanceMin?: number;
  vehicleType?: MileageListRequestVehicleType;
  state?: MileageListRequestState;
};

export default MileageListRequest;
