import OrderOptimizerDetailsRequest from "../../api/raily/order-optimizer/details/order-optimizer-details.request";
import OrderOptimizerDetailsResponse from "../../api/raily/order-optimizer/details/order-optimizer-details.response";
import OrderOptimizerListRequest from "../../api/raily/order-optimizer/list/order-optimizer-list.request";
import orderOptimizerApiService from "../../api/raily/order-optimizer/order-optimizer-api.service";
import OrderOptimizerPotentialDriversListRequest from "../../api/raily/order-optimizer/potential-drivers-list/order-optimizer-potential-drivers-list.request";
import OrderOptimizerPotentialDriversListResponse from "../../api/raily/order-optimizer/potential-drivers-list/order-optimizer-potential-drivers-list.response";
import { DataLoadDefaultErrors } from "../../utils/data-load-error/data-load-error";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import SearchRoadRoutingRequest from "../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../utils/search-road-route/search-road-routing.response";
import OrderOptimizerDetails from "./details/order-optimizer-details";
import OrderOptimizerDetailsLoadParams from "./details/order-optimizer-details-load-params";
import orderOptimizerDetailsRequestFactory from "./details/order-optimizer-details-request.factory";
import orderOptimizerDetailsFactory from "./details/order-optimizer-details.factory";
import OrderOptimizerList from "./list/order-optimizer-list";
import OrderOptimizerListError from "./list/order-optimizer-list-error";
import OrderOptimizerListLoadParams from "./list/order-optimizer-list-load-paramts";
import orderOptimizerListRequestFactory from "./list/order-optimizer-list-request.factory";
import orderOptimizerListFactory from "./list/order-optimizer-list.factory";
import OrderOptimizerOptimizationStatus from "./optimization-status/order-optimizer-optimization-status";
import OrderOptimizerOptimizationStatusError from "./optimization-status/order-optimizer-optimization-status-error";
import OrderOptimizerOptimizationStatusLoadParams from "./optimization-status/order-optimizer-optimization-status-load-params";
import orderOptimizerOptimizationStatusRequestFactory from "./optimization-status/order-optimizer-optimization-status-request.factory";
import orderOptimizerOptimizationStatusFactory from "./optimization-status/order-optimizer-optimization-status.factory";
import OrderOptimizerPotentialDriversList from "./potential-drivers-list/order-optimizer-potential-drivers-list";
import OrderOptimizerPotentialDriversListLoadParams from "./potential-drivers-list/order-optimizer-potential-drivers-list-load-params";
import orderOptimizerPotentialDriversListRequestFactory from "./potential-drivers-list/order-optimizer-potential-drivers-list-request.factory";
import orderOptimizerPotentialDriversListFactory from "./potential-drivers-list/order-optimizer-potential-drivers-list.factory";
import OrderOptimizerRoadRouteItem from "./road-route/order-optimizer-road-route-item";
import OrderOptimizerRoadRouteLoadParams from "./road-route/order-optimizer-road-route-load-params";
import orderOptimizerRoadRouteRequestFactory from "./road-route/order-optimizer-road-route-request.factory";
import orderOptimizerRoadRouteFactory from "./road-route/order-optimizer-road-route.factory";

const handleListError = (
  error: HttpError | OrderOptimizerListError
): OrderOptimizerListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: OrderOptimizerListLoadParams,
  abortSignal: AbortSignal
): Promise<OrderOptimizerList> => {
  const request: OrderOptimizerListRequest =
    orderOptimizerListRequestFactory.create(params);

  try {
    const response = await orderOptimizerApiService().getList(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const data = orderOptimizerListFactory.create(response);
    return data;
  } catch (_error) {
    const error = _error as HttpError | OrderOptimizerListError;

    throw handleListError(error);
  }
};

const handleRoadRouteError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getRoute = async (
  params: OrderOptimizerRoadRouteLoadParams
): Promise<OrderOptimizerRoadRouteItem> => {
  const request: SearchRoadRoutingRequest =
    orderOptimizerRoadRouteRequestFactory.create(params);

  try {
    const response: SearchRoadRoutingResponse =
      await orderOptimizerApiService().getRoute(request);

    const RoadRouteItems: OrderOptimizerRoadRouteItem =
      orderOptimizerRoadRouteFactory.create(response);

    return RoadRouteItems;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleRoadRouteError(error);
  }
};

const handleDetailsError = (error: HttpError): DataLoadDefaultErrors => {
  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: OrderOptimizerDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<OrderOptimizerDetails> => {
  const request: OrderOptimizerDetailsRequest =
    orderOptimizerDetailsRequestFactory.create(params);

  try {
    const response: OrderOptimizerDetailsResponse =
      await orderOptimizerApiService().getDetails(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const orderOptimizerDetails: OrderOptimizerDetails =
      orderOptimizerDetailsFactory.create(response);

    return orderOptimizerDetails;
  } catch (_error) {
    const error = _error as HttpError;

    throw handleDetailsError(error);
  }
};

const handleOptimizationStatusError = (
  error: HttpError | OrderOptimizerOptimizationStatusError
): OrderOptimizerOptimizationStatusError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getOptimizationStatus = async (
  params: OrderOptimizerOptimizationStatusLoadParams,
  abortSignal: AbortSignal
): Promise<OrderOptimizerOptimizationStatus> => {
  const request = orderOptimizerOptimizationStatusRequestFactory.create(params);

  try {
    const response = await orderOptimizerApiService().getOptimizationStatus(
      request,
      abortSignal
    );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    return orderOptimizerOptimizationStatusFactory.create(response);
  } catch (_error) {
    const error = _error as HttpError | OrderOptimizerOptimizationStatusError;

    throw handleOptimizationStatusError(error);
  }
};

const handlePotentialDriversListError = (
  error: HttpError
): DataLoadDefaultErrors => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getPotentialDriversList = async (
  params: OrderOptimizerPotentialDriversListLoadParams,
  abortSignal: AbortSignal
): Promise<OrderOptimizerPotentialDriversList> => {
  const request: OrderOptimizerPotentialDriversListRequest =
    orderOptimizerPotentialDriversListRequestFactory.create(params);

  try {
    const response: OrderOptimizerPotentialDriversListResponse =
      await orderOptimizerApiService().getPotentialDriversList(
        request,
        abortSignal
      );

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const orderOptimizerPotentialDriversList: OrderOptimizerPotentialDriversList =
      orderOptimizerPotentialDriversListFactory.create(response.data);

    return orderOptimizerPotentialDriversList;
  } catch (_error) {
    const error = _error as HttpError;

    throw handlePotentialDriversListError(error);
  }
};

const orderOptimizerService = {
  getList,
  getRoute,
  getDetails,
  getOptimizationStatus,
  getPotentialDriversList,
};

export default orderOptimizerService;
