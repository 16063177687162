import DriverDownloadRequest, {
  DriverDownloadRequestQueryParams,
} from "./driver-download.request";

const createQueryParams = (
  data: DriverDownloadRequest
): DriverDownloadRequestQueryParams => {
  return {
    firstName: data.firstName,
    fleetPartner: data.fleetPartner,
    fleetPartnerId: data.fleetPartnerId,
    lastName: data.lastName,
    order: data.order,
    statusCriteria: data.statusCriteria,
    taxiCorporation: data.taxiCorporation,
    taxiCorporationId: data.taxiCorporationId,
    address: data.address,
    creationEnd: data.creationEnd,
    creationStart: data.creationStart,
    modificationEnd: data.modificationEnd,
    modificationStart: data.modificationStart,
    email: data.email,
    pendingCandidature: data.pendingCandidature,
    username: data.username,
    separator: data.separator,
    timezone: data.timezone,
  };
};

const driverDownloadRequestFactory = {
  createQueryParams,
};

export default driverDownloadRequestFactory;
