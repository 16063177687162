import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import MileageListingStatus from "./types/mileage-listing-status";
import MileageListingVehicleType from "./types/mileage-listing-vehicle-type";

const getStatusOptions = () => [
  {
    status: MileageListingStatus.PENDING,
  },
  {
    status: MileageListingStatus.ACCEPTED,
  },
  {
    status: MileageListingStatus.REJECTED,
  },
  {
    status: MileageListingStatus.DELEGATION_CREATED,
  },
];

const getVehicleTypeLabel = (type: MileageListingVehicleType): string => {
  const translations =
    mileageTranslationsHelper.getMileageListingTranslations().type;

  switch (type) {
    case MileageListingVehicleType.CAR_GT_900CM3:
      return translations.carGT900CM3;
    case MileageListingVehicleType.CAR_LE_900CM3:
      return translations.carLE900CM3;
    case MileageListingVehicleType.COMPANY_OWNED:
      return translations.companyOwned;
    case MileageListingVehicleType.MOPED:
      return translations.moped;
    case MileageListingVehicleType.MOTORCYCLE:
      return translations.motorcycle;
  }
};

const getStateLabel = (type: MileageListingStatus): string => {
  const translations =
    mileageTranslationsHelper.getMileageListingTranslations().state;

  switch (type) {
    case MileageListingStatus.PENDING:
      return translations.pendingLabel;
    case MileageListingStatus.ACCEPTED:
      return translations.acceptedLabel;
    case MileageListingStatus.REJECTED:
      return translations.rejectedLabel;
    case MileageListingStatus.DELEGATION_CREATED:
      return translations.delegationCreatedLabel;
  }
};

const mileageListingHelper = {
  getStatusOptions,
  getVehicleTypeLabel,
  getStateLabel,
};

export default mileageListingHelper;
