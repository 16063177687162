import useAsyncData from "../../../hooks/use-async-data";
import orderOptimizerService from "../order-optimizer.service";
import OrderOptimizerRoadRouteItem, {
  OrderOptimizerRoadRouteItemWithHumanId,
} from "./order-optimizer-road-route-item";
import OrderOptimizerRoadRouteLoadParams from "./order-optimizer-road-route-load-params";

const useOrderOptimizerRoadRoute = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<OrderOptimizerRoadRouteItemWithHumanId[]>([]);

  const load = async (
    params: OrderOptimizerRoadRouteLoadParams[],
    orderHumanIds?: number[]
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await Promise.all(
        params.map((param) => orderOptimizerService.getRoute(param))
      );

      setData(
        data.map((routeItem, index) => ({
          ...routeItem,
          orderHumanId: orderHumanIds ? orderHumanIds[index] : index,
        }))
      );
      setIsLoading(false);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isError,
    isLoading,
    load,
  };
};

export default useOrderOptimizerRoadRoute;
