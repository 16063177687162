import OrderOptimizerOptimizationStatusResponse from "../../../api/raily/order-optimizer/optimization-status/order-optimizer-optimization-status.response";
import OrderOptimizerOptimizationStatus from "./order-optimizer-optimization-status";

const create = (
  optimizationStatusResponse: OrderOptimizerOptimizationStatusResponse
): OrderOptimizerOptimizationStatus => {
  return {
    reservedAt: optimizationStatusResponse.data.reservedAt,
    reservedBy: optimizationStatusResponse.data.reservedBy,
  };
};

const orderOptimizerOptimizationStatusFactory = {
  create,
};

export default orderOptimizerOptimizationStatusFactory;
