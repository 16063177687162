import OrderOptimizerDetailsHiddenDetailsCenterTablesTableColumn from "./types/order-optimizer-details-hidden-details-center-tables-table-column";

const getColumns =
  (): OrderOptimizerDetailsHiddenDetailsCenterTablesTableColumn[] => {
    const tableColumns: OrderOptimizerDetailsHiddenDetailsCenterTablesTableColumn[] =
      [
        {
          header: "",
          title: "",
          accessor: "orders",
          colSpan: 7,
        },
        {
          header: "",
          title: "",
          accessor: "driver",
          colSpan: 3,
        },
      ];

    return tableColumns;
  };

const orderOptimizerDetailsHiddenDetailsCenterTablesTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsHiddenDetailsCenterTablesTableHelper;
