enum DriverListingFilterType {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  TAXI_CORPORATION = "TAXI_CORPORATION",
  STARTING_ADDRESS = "STARTING_ADDRESS",
  FLEET_PARTNER = "FLEET_PARTNER",
  CANDIDATURE_ACTIVITY = "CANDIDATURE_ACTIVITY",
  ACTIVITY_STATUS = "ACTIVITY_STATUS",
  CREATION_DATE = "CREATION_DATE",
  MODIFICATION_DATE = "MODIFICATION_DATE",
}

export default DriverListingFilterType;
