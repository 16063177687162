import ListingFilterDefinition, {
  ListingFilterDefinitionOption,
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
} from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import UserStatus from "../../../../../common/types/user-status";
import dateService from "../../../../../common/utils/date/date.service";
import userHelper from "../../../../../common/utils/user/user.helper";
import userTranslationsHelper from "../../../../../languages/user-translations.helper";
import driverListingHelper from "../common/driver-listing.helper";
import DriverListingFilter, {
  DriverListingActivityStatusFilter,
  DriverListingCandidatureActivityFilter,
  DriverListingDriverCreationDateFilter,
  DriverListingDriverModificationDateFilter,
  DriverListingFirstNameFilter,
  DriverListingFleetPartnerFilter,
  DriverListingLastNameFilter,
  DriverListingStartingAddressFilter,
  DriverListingTaxiCorporationFilter,
} from "../common/types/driver-listing-filter";
import DriverListingFilterType from "../common/types/driver-listing-filter-type";
import DriverListingSortKey from "../common/types/driver-listing-sort-key";

const driverListingFilterHelper = () => {
  const translations = userTranslationsHelper.getDriverListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getBadgeForDriverCreationDate = (
    filterValue: DriverListingDriverCreationDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.creationDateLabel,
      translations.filters.creationDateTitle
    );
  };

  const getBadgeForDriverModificationDate = (
    filterValue: DriverListingDriverModificationDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.modificationDateLabel,
      translations.filters.modificationDateTitle
    );
  };

  const getBadgeForActivityStatus = (
    filterValue: DriverListingActivityStatusFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText = userHelper.getActivityStatusText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.activityStatusLabel,
      translations.filters.activityStatusTitle
    );
  };

  const getBadgeForCandidatureActivity = (
    filterValue: DriverListingCandidatureActivityFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const filterValueText =
      driverListingHelper.getCandidatureActivityText(filterValue);

    return createBadgeData(
      filterValueText,
      translations.filters.candidatureActivityLabel,
      translations.filters.candidatureActivityTitle
    );
  };

  const getBadgeForFirstName = (
    filterValue: DriverListingFirstNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.firstNameLabel,
      translations.filters.firstNameTitle
    );
  };

  const getBadgeForFleetPartner = (
    filterValue: DriverListingFleetPartnerFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.fleetPartnerLabel,
      translations.filters.fleetPartnerTitle
    );
  };

  const getBadgeForLastName = (
    filterValue: DriverListingLastNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.lastNameLabel,
      translations.filters.lastNameTitle
    );
  };

  const getBadgeForStartingAddress = (
    filterValue: DriverListingStartingAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.startingAddressLabel,
      translations.filters.startingAddressTitle
    );
  };

  const getBadgeForTaxiCorporation = (
    filterValue: DriverListingTaxiCorporationFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.taxiCorporationLabel,
      translations.filters.taxiCorporationTitle
    );
  };

  const getSelectDataForDriverCreationDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.creationDateLabel,
        },
      };
    };

  const getSelectDataForDriverModificationDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.modificationDateLabel,
        },
      };
    };

  const getSelectDefinitionForActivityStatus =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.ACTIVE)
              ),
            value: UserStatus.ACTIVE,
          },
          {
            label:
              translations.filters.search.activityStatusTemplateLabel.replace(
                "#{query}",
                userHelper.getActivityStatusText(UserStatus.DELETED)
              ),
            value: UserStatus.DELETED,
          },
        ],
      };
    };

  const getSelectDataForCandidatureActivity =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label:
              translations.filters.search.candidatureActivityTemplateLabel.replace(
                "#{query}",
                driverListingHelper.getCandidatureActivityText(true)
              ),
            value: true,
          },
          {
            label:
              translations.filters.search.candidatureActivityTemplateLabel.replace(
                "#{query}",
                driverListingHelper.getCandidatureActivityText(false)
              ),
            value: false,
          },
        ],
      };
    };

  const getSelectDataForFirstName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.firstNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForFleetPartner = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.fleetPartnerTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForLastName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.lastNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForStartingAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.startingAddressTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDefinitionForTaxiCorporation = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.taxiCorporationTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataByType = (
    filterType: DriverListingFilterType,
    query: string
  ) => {
    const options: Record<
      DriverListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [DriverListingFilterType.ACTIVITY_STATUS]:
        getSelectDefinitionForActivityStatus,
      [DriverListingFilterType.CANDIDATURE_ACTIVITY]:
        getSelectDataForCandidatureActivity,
      [DriverListingFilterType.FIRST_NAME]: getSelectDataForFirstName,
      [DriverListingFilterType.FLEET_PARTNER]: getSelectDataForFleetPartner,
      [DriverListingFilterType.LAST_NAME]: getSelectDefinitionForLastName,
      [DriverListingFilterType.STARTING_ADDRESS]:
        getSelectDefinitionForStartingAddress,
      [DriverListingFilterType.TAXI_CORPORATION]:
        getSelectDefinitionForTaxiCorporation,
      [DriverListingFilterType.CREATION_DATE]:
        getSelectDataForDriverCreationDate,
      [DriverListingFilterType.MODIFICATION_DATE]:
        getSelectDataForDriverModificationDate,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeDefinitionByType = (
    filterType: DriverListingFilterType,
    filterValue: DriverListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      DriverListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [DriverListingFilterType.ACTIVITY_STATUS]: () =>
        getBadgeForActivityStatus(
          filterValue as DriverListingActivityStatusFilter["value"]
        ),
      [DriverListingFilterType.CANDIDATURE_ACTIVITY]: () =>
        getBadgeForCandidatureActivity(
          filterValue as DriverListingCandidatureActivityFilter["value"]
        ),
      [DriverListingFilterType.FIRST_NAME]: () =>
        getBadgeForFirstName(
          filterValue as DriverListingFirstNameFilter["value"]
        ),
      [DriverListingFilterType.FLEET_PARTNER]: () =>
        getBadgeForFleetPartner(
          filterValue as DriverListingFleetPartnerFilter["value"]
        ),
      [DriverListingFilterType.LAST_NAME]: () =>
        getBadgeForLastName(
          filterValue as DriverListingLastNameFilter["value"]
        ),
      [DriverListingFilterType.STARTING_ADDRESS]: () =>
        getBadgeForStartingAddress(
          filterValue as DriverListingStartingAddressFilter["value"]
        ),
      [DriverListingFilterType.TAXI_CORPORATION]: () =>
        getBadgeForTaxiCorporation(
          filterValue as DriverListingTaxiCorporationFilter["value"]
        ),
      [DriverListingFilterType.CREATION_DATE]: () =>
        getBadgeForDriverCreationDate(
          filterValue as DriverListingDriverCreationDateFilter["value"]
        ),
      [DriverListingFilterType.MODIFICATION_DATE]: () =>
        getBadgeForDriverModificationDate(
          filterValue as DriverListingDriverModificationDateFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: DriverListingFilterType
  ): ListingFilterDefinitionOption<DriverListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<DriverListingFilter>[] => {
      return Object.values(DriverListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<DriverListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.firstNameAscLabel,
        value: DriverListingSortKey.FIRST_NAME_ASC,
      },
      {
        label: translations.sort.firstNameDescLabel,
        value: DriverListingSortKey.FIRST_NAME_DESC,
      },
      {
        label: translations.sort.lastNameAscLabel,
        value: DriverListingSortKey.LAST_NAME_ASC,
      },
      {
        label: translations.sort.lastNameDescLabel,
        value: DriverListingSortKey.LAST_NAME_DESC,
      },
      {
        label: translations.sort.fleetPartnerAscLabel,
        value: DriverListingSortKey.FLEET_PARTNER_ASC,
      },
      {
        label: translations.sort.fleetPartnerDescLabel,
        value: DriverListingSortKey.FLEET_PARTNER_DESC,
      },
      {
        label: translations.sort.taxiCorporationAscLabel,
        value: DriverListingSortKey.TAXI_CORPORATION_ASC,
      },
      {
        label: translations.sort.taxiCorporationDescLabel,
        value: DriverListingSortKey.TAXI_CORPORATION_DESC,
      },
      {
        label: translations.sort.lastPlanEntryEndTimeAscLabel,
        value: DriverListingSortKey.LAST_PLAN_ENTRY_END_TIME_ASC,
      },
      {
        label: translations.sort.lastPlanEntryEndTimeDescLabel,
        value: DriverListingSortKey.LAST_PLAN_ENTRY_END_TIME_DESC,
      },
      {
        label: translations.sort.creationDateAscLabel,
        value: DriverListingSortKey.CREATION_DATE_ASC,
      },
      {
        label: translations.sort.creationDateDescLabel,
        value: DriverListingSortKey.CREATION_DATE_DESC,
      },
      {
        label: translations.sort.modificationDateAscLabel,
        value: DriverListingSortKey.MODIFICATION_DATE_ASC,
      },
      {
        label: translations.sort.modificationDateDescLabel,
        value: DriverListingSortKey.MODIFICATION_DATE_DESC,
      },
    ];
  };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default driverListingFilterHelper;
