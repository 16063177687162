import { FC, ReactNode, RefObject, useCallback, useRef } from "react";
import Select, {
  ActionMeta,
  ContainerProps,
  GroupBase,
  InputProps,
  MenuListProps,
  MenuPlacement,
  MenuPosition,
  MultiValue,
  SelectInstance,
  SingleValue,
} from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import SelectOptionComponent from "../common/option/select-option.component";
import SelectOption from "../common/option/select-option";
import selectHelper from "../common/select.helper";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import ComponentClassnames from "../../../../types/component-classnames";
import SelectInputComponent from "../common/input/select-input.component";
import SelectContainerComponent from "../common/container/select-container.component";
import SelectMenuListComponent from "../common/menu-list/select-menu-list.component";

export type SingleSelectProps = {
  classNames?: ComponentClassnames;
  options: SelectOption[];
  value: SelectOption | null;
  placeholder?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  autoFocus?: boolean;
  defaultValue?: SelectOption | null;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  menuPortalTarget?: HTMLElement | null | undefined;
  menuPlacement?: MenuPlacement;
  inputValue?: string;
  idForTesting?: string;
  maxMenuHeight?: number;
  menuPosition?: MenuPosition | undefined;
  onChange: (value: SelectOption | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onInputChange?: (value: string) => void;
  noOptionsMessage?: (inputValue: string) => string;
  filterFunction?: (
    option: FilterOptionOption<SelectOption<any>>,
    inputValue: string
  ) => boolean;
  onKeyDown?: (
    event: React.KeyboardEvent,
    selectRef: RefObject<
      SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>
    >
  ) => void;
};

const SingleSelectComponent: FC<SingleSelectProps> = (props) => {
  const selectComponentTranslations =
    appTranslationsHelper.getComponentTranslations().select;

  const selectClasses = selectHelper.getSelectClasses(
    props.classNames,
    props.hasError
  );

  const onChange = (
    newValue: MultiValue<SelectOption<any>> | SingleValue<SelectOption<any>>,
    _actionMeta: ActionMeta<SelectOption<any>>
  ) => {
    const isValueExists = !(newValue === undefined || newValue === null);
    const value = isValueExists ? (newValue as SelectOption) : null;

    if ((newValue as SelectOption)?.onClick) {
      (newValue as SelectOption).onClick!();
      selectRef.current?.menuListRef?.blur();
    }

    props.onChange(value);
  };

  const selectRef: RefObject<
    SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>
  > = useRef(null);

  const MemoizedInputComponent = useCallback(
    (
      inputProps: InputProps<SelectOption, boolean, GroupBase<SelectOption>>
    ) => {
      return SelectInputComponent({
        ...inputProps,
        idForTesting: props.idForTesting
          ? `${props.idForTesting}-input`
          : undefined,
      });
    },
    [props.idForTesting]
  );

  const MemoizedSelectContainerComponent = useCallback(
    (
      containerProps: ContainerProps<
        SelectOption,
        boolean,
        GroupBase<SelectOption>
      >
    ) => {
      return SelectContainerComponent({
        ...containerProps,
        idForTesting: props.idForTesting
          ? `${props.idForTesting}-container`
          : undefined,
      });
    },
    [props.idForTesting]
  );

  const MemoizedMenuListComponent = useCallback(
    (
      menuListProps: MenuListProps<
        SelectOption,
        boolean,
        GroupBase<SelectOption>
      >
    ) => {
      return SelectMenuListComponent({
        ...menuListProps,
        idForTesting: props.idForTesting
          ? `${props.idForTesting}-list`
          : undefined,
      });
    },
    [props.idForTesting]
  );

  return (
    <Select
      ref={selectRef}
      value={props.value}
      defaultValue={props.defaultValue}
      options={props.options}
      backspaceRemovesValue
      autoFocus={props.autoFocus}
      classNamePrefix="select"
      className={selectClasses}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      menuPlacement={props.menuPlacement ?? "auto"}
      menuPosition={props.menuPosition || "absolute"}
      blurInputOnSelect={false}
      maxMenuHeight={props.maxMenuHeight}
      menuPortalTarget={props.menuPortalTarget || document.body}
      isClearable={props.isClearable}
      isLoading={props.isLoading}
      loadingMessage={() => selectComponentTranslations.loadingMessage}
      inputValue={props.inputValue}
      components={{
        Option: (optionProps) => (
          <SelectOptionComponent {...optionProps} selectRef={selectRef} />
        ),
        Input: MemoizedInputComponent,
        SelectContainer: MemoizedSelectContainerComponent,
        MenuList: MemoizedMenuListComponent,
      }}
      onFocus={props.onFocus}
      onKeyDown={(event) => {
        if (props.onKeyDown) {
          props.onKeyDown(event, selectRef);
        }
      }}
      onBlur={props.onBlur}
      onChange={onChange}
      onInputChange={props.onInputChange}
      filterOption={props.filterFunction ?? selectHelper.filterByDefault}
      noOptionsMessage={(input) => {
        return selectHelper.getNoOptionsMessage(
          input.inputValue,
          props.noOptionsMessage
        );
      }}
      styles={{
        menuPortal: (base) => ({
          ...base,
        }),
      }}
    />
  );
};

export default SingleSelectComponent;
