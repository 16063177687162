import { FC } from "react";

type OrderOptimizerDetailsTableDotProps = {
  color: string;
};

const OrderOptimizerDetailsTableDotComponent: FC<
  OrderOptimizerDetailsTableDotProps
> = ({ color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: "50%",
        width: "10px",
        height: "10px",
      }}
    ></div>
  );
};

export default OrderOptimizerDetailsTableDotComponent;
