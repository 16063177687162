import Joi from "joi";
import FormValidationResult from "../../../../common/utils/validation/types/form-validation-result";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import TaxiCargoContractEditFormData from "./common/types/taxi-cargo-contract-edit-form-data";

const validateActivity = (
  value: TaxiCargoContractEditFormData["isActive"]
): FormValidationResult => {
  const validationSchema = Joi.any().invalid(null).required();

  return formValidationService.validate(value, validationSchema);
};

const validateContactPassengerAfterMinutes = (
  value: TaxiCargoContractEditFormData["contactPassengerAfterMinutes"]
): FormValidationResult => {
  const validationSchema = Joi.number().integer().min(0).max(1440).required();

  return formValidationService.validate(value, validationSchema);
};

const taxiCargoContractEditFormValidationService = {
  validateActivity,
  validateContactPassengerAfterMinutes,
};

export default taxiCargoContractEditFormValidationService;
