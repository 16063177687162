import orderOptimizerTranslationsHelper from "../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsDriverTableColumn from "../common/types/order-optimizer-details-driver-table-column";

const getColumns = (): OrderOptimizerDetailsDriverTableColumn[] => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.driver
      .headers;

  const tableColumns: OrderOptimizerDetailsDriverTableColumn[] = [
    {
      header: translations.driverLabel,
      title: translations.driverTitle,
      accessor: "driver",
      colSpan: 3,
    },
    {
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      accessor: "billingModel",
      colSpan: 3,
    },
    {
      header: translations.contractRateLabel,
      title: translations.contractRateTitle,
      accessor: "contractRate",
      colSpan: 3,
    },
    {
      header: translations.totalTimeLabel,
      title: translations.totalTimeTitle,
      accessor: "totalTime",
      colSpan: 3,
    },
    {
      header: translations.haltingTimeLabel,
      title: translations.haltingTimeTitle,
      accessor: "haltingTime",
      colSpan: 3,
    },
    {
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      accessor: "distance",
      colSpan: 3,
    },
    {
      header: translations.marginLabel,
      title: translations.marginTitle,
      accessor: "margin",
      colSpan: 4,
    },
  ];

  return tableColumns;
};

const orderOptimizerDetailsDriverTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsDriverTableHelper;
