import CargoTaxiContractListRequest, {
  CargoTaxiContractListOrderRequest,
} from "../../../api/raily/contracts/cargo-taxi-list/cargo-taxi-contract-list-request";
import paginationService from "../../../utils/pagination/pagination.service";
import CargoTaxiContractListLoadParams, {
  CargoTaxiContractListLoadParamsOrder,
} from "./cargo-taxi-contract-list-load-params";

const createOrder = (
  order: CargoTaxiContractListLoadParamsOrder | undefined
): CargoTaxiContractListOrderRequest | undefined => {
  switch (order) {
    case CargoTaxiContractListLoadParamsOrder.DISTANCE_RATE_ASC:
      return CargoTaxiContractListOrderRequest.DISTANCE_RATE_ASC;
    case CargoTaxiContractListLoadParamsOrder.DISTANCE_RATE_DESC:
      return CargoTaxiContractListOrderRequest.DISTANCE_RATE_DESC;
    case CargoTaxiContractListLoadParamsOrder.VALID_SINCE_ASC:
      return CargoTaxiContractListOrderRequest.VALID_SINCE_ASC;
    case CargoTaxiContractListLoadParamsOrder.VALID_SINCE_DESC:
      return CargoTaxiContractListOrderRequest.VALID_SINCE_DESC;
    case CargoTaxiContractListLoadParamsOrder.VALID_TO_ASC:
      return CargoTaxiContractListOrderRequest.VALID_TO_ASC;
    case CargoTaxiContractListLoadParamsOrder.VALID_TO_DESC:
      return CargoTaxiContractListOrderRequest.VALID_TO_DESC;
    default:
      return undefined;
  }
};

const create = (
  params: CargoTaxiContractListLoadParams
): CargoTaxiContractListRequest => {
  return {
    active: params.active,
    cargoCompanyName: params.cargoCompanyName,
    limit: params.pageSize,
    offset: paginationService.calculateOffset(params.page, params.pageSize),
    order: createOrder(params.order),
    taxiCorporationName: params.taxiCorporationName,
  };
};

const cargoTaxiContractListRequestFactory = { create };

export default cargoTaxiContractListRequestFactory;
