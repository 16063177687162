export enum CargoTaxiContractListContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export enum CargoTaxiContractListModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type CargoTaxiContractListTaxiCorporationFleetPartners = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date;
  modifiedBy: string;
  taxiCorporationId: string;
  name: string;
};

export type CargoTaxiContractListCargoCompany = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxId: string;
  regon?: string | null;
  krsNo?: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMainteancePolicy: CargoTaxiContractListContractMaintenancePolicy;
  notes?: string | null;
};

export type CargoTaxiContractListTaxiCorporation = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxId: string;
  regon?: string | null;
  krsNo?: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMainteancePolicy: CargoTaxiContractListContractMaintenancePolicy;
  notes?: string | null;
  fleetPartners: CargoTaxiContractListTaxiCorporationFleetPartners[];
};

export type CargoTaxiContractListItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxiCorporation: CargoTaxiContractListTaxiCorporation;
  cargoCompany: CargoTaxiContractListCargoCompany;
  publishingAllowed: boolean;
  cargoCompanyId: string;
  taxiCorporationId: string;
  model: CargoTaxiContractListModel;
  validSince: Date;
  validTo: Date;
  isValid: boolean;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  allowChargeWhileApproaching: boolean;
  allowChargeWhileReturning: boolean;
  allowChargeDuringRide: boolean;
  discountLimit: number;
};

type CargoTaxiContractList = {
  data: CargoTaxiContractListItem[];
  totalCount: number;
};

export default CargoTaxiContractList;
