import CargoTaxiContractAddParams from "../../../../../common/services/cargo-taxi-contract/add/cargo-taxi-contract-add-params";
import CargoTaxiContractAddFormData from "./types/cargo-taxi-contract-add-form-data";

const create = (
  formData: CargoTaxiContractAddFormData,
  cargoCompanyUuid: string
): CargoTaxiContractAddParams => {
  return {
    billingModel: formData.billingModel!,
    cargoCompanyUuid,
    discountPercentLimit: formData.discountPercentLimit!,
    distanceRate: formData.distanceRate!,
    freeStoppingPeriodMinutes: formData.freeStoppingPeriodMinutes!,
    isActive: formData.isActive!,
    isOrderPublishingAllowed: formData.isOrderPublishingAllowed!,
    isTollRoadsDuringOrderAllowed: formData.isTollRoadsDuringOrderAllowed!,
    isTollRoadsWhileArrivalAllowed: formData.isTollRoadsWhileArrivalAllowed!,
    isTollRoadsWhileReturningAllowed:
      formData.isTollRoadsWhileReturningAllowed!,
    periodOfValidity: formData.periodOfValidity!,
    stoppingRate: formData.stoppingRate!,
    taxiCorporationUuid: formData.taxiCorporationUuid,
    contactPassengerAfterMinutes: 0,
  };
};

const cargoTaxiContractAddParamsFactory = {
  create,
};

export default cargoTaxiContractAddParamsFactory;
