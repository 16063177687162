import DriverListLoadParams, {
  DriverListLoadParamsOrder,
} from "../../../../../common/services/driver/list/driver-list-load-params";
import DriverListingFilter, {
  DriverListingActivityStatusFilter,
  DriverListingCandidatureActivityFilter,
  DriverListingDriverCreationDateFilter,
  DriverListingDriverModificationDateFilter,
  DriverListingFirstNameFilter,
  DriverListingFleetPartnerFilter,
  DriverListingLastNameFilter,
  DriverListingStartingAddressFilter,
  DriverListingTaxiCorporationFilter,
} from "./types/driver-listing-filter";
import DriverListingFilterType from "./types/driver-listing-filter-type";
import DriverListingSortKey from "./types/driver-listing-sort-key";

const createOrder = (
  sortKey: DriverListingSortKey | null
): DriverListLoadParamsOrder | undefined => {
  const definition: Record<DriverListingSortKey, DriverListLoadParamsOrder> = {
    [DriverListingSortKey.FIRST_NAME_ASC]:
      DriverListLoadParamsOrder.FIRST_NAME_ASC,
    [DriverListingSortKey.FIRST_NAME_DESC]:
      DriverListLoadParamsOrder.FIRST_NAME_DESC,
    [DriverListingSortKey.LAST_NAME_ASC]:
      DriverListLoadParamsOrder.LAST_NAME_ASC,
    [DriverListingSortKey.LAST_NAME_DESC]:
      DriverListLoadParamsOrder.LAST_NAME_DESC,
    [DriverListingSortKey.FLEET_PARTNER_ASC]:
      DriverListLoadParamsOrder.FLEET_PARTNER_NAME_ASC,
    [DriverListingSortKey.FLEET_PARTNER_DESC]:
      DriverListLoadParamsOrder.FLEET_PARTNER_NAME_DESC,
    [DriverListingSortKey.TAXI_CORPORATION_ASC]:
      DriverListLoadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_ASC,
    [DriverListingSortKey.TAXI_CORPORATION_DESC]:
      DriverListLoadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_DESC,
    [DriverListingSortKey.LAST_PLAN_ENTRY_END_TIME_ASC]:
      DriverListLoadParamsOrder.LAST_PLAN_ENTRY_END_TIME_ASC,
    [DriverListingSortKey.LAST_PLAN_ENTRY_END_TIME_DESC]:
      DriverListLoadParamsOrder.LAST_PLAN_ENTRY_END_TIME_DESC,
    [DriverListingSortKey.CREATION_DATE_ASC]:
      DriverListLoadParamsOrder.CREATION_DATE_ASC,
    [DriverListingSortKey.CREATION_DATE_DESC]:
      DriverListLoadParamsOrder.CREATION_DATE_DESC,
    [DriverListingSortKey.MODIFICATION_DATE_ASC]:
      DriverListLoadParamsOrder.MODIFICATION_DATE_ASC,
    [DriverListingSortKey.MODIFICATION_DATE_DESC]:
      DriverListLoadParamsOrder.MODIFICATION_DATE_DESC,
  };

  return sortKey ? definition[sortKey] : undefined;
};

const create = (
  page: number,
  pageSize: number,
  filters: DriverListingFilter[],
  sortKey: DriverListingSortKey | null
): DriverListLoadParams => {
  const statusFilter = filters.find(
    (filter) => filter.type === DriverListingFilterType.ACTIVITY_STATUS
  )?.value as DriverListingActivityStatusFilter["value"] | undefined;

  const creationDateFilter = filters.find(
    (filter) => filter.type === DriverListingFilterType.CREATION_DATE
  )?.value as DriverListingDriverCreationDateFilter["value"] | undefined;

  const modificationDateFilter = filters.find(
    (filter) => filter.type === DriverListingFilterType.MODIFICATION_DATE
  )?.value as DriverListingDriverModificationDateFilter["value"] | undefined;

  return {
    page,
    pageSize,
    creationDateFrom: creationDateFilter?.from,
    creationDateTo: creationDateFilter?.to,
    modificationDateFrom: modificationDateFilter?.from,
    modificationDateTo: modificationDateFilter?.to,
    firstName: filters.find(
      (filter) => filter.type === DriverListingFilterType.FIRST_NAME
    )?.value as DriverListingFirstNameFilter["value"] | undefined,
    lastName: filters.find(
      (filter) => filter.type === DriverListingFilterType.LAST_NAME
    )?.value as DriverListingLastNameFilter["value"] | undefined,
    fleetPartner: filters.find(
      (filter) => filter.type === DriverListingFilterType.FLEET_PARTNER
    )?.value as DriverListingFleetPartnerFilter["value"] | undefined,
    address: filters.find(
      (filter) => filter.type === DriverListingFilterType.STARTING_ADDRESS
    )?.value as DriverListingStartingAddressFilter["value"] | undefined,
    isCandidaturePending: filters.find(
      (filter) => filter.type === DriverListingFilterType.CANDIDATURE_ACTIVITY
    )?.value as DriverListingCandidatureActivityFilter["value"] | undefined,
    order: createOrder(sortKey),
    status: statusFilter,
    taxiCorporation: filters.find(
      (filter) => filter.type === DriverListingFilterType.TAXI_CORPORATION
    )?.value as DriverListingTaxiCorporationFilter["value"] | undefined,
  };
};

const driverListingLoadParamsFactory = {
  create,
};

export default driverListingLoadParamsFactory;
