import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import { useAppContext } from "../../../../../context/app.context";
import {
  OrderOptimizerDetailsItemDriver,
  OrderOptimizerDetailsItemOrders,
  OrderOptimizerDetailsItemStepsOrders,
} from "../../common/types/order-optimizer-details-item";
import orderOptimizerDetailsHiddenDetailsCenterTablesTableHelper from "./order-optimizer-details-hidden-details-center-tables.helper";
import OrderOptimizerDetailsHiddenDetailsCenterTablesTableRow from "./types/order-optimizer-details-hidden-details-center-tables-table-row";
import uuidService from "../../../../../common/utils/uuid/uuid.service";
import OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableComponent from "./driver/order-optimizer-details-hidden-details-heading-tables-driver-table.component";
import OrderOptimizerDetailsHiddenDetailsTablesOrdersComponent from "./orders/order-optimizer-details-hidden-details-center-tables-orders.component";

type OrderOptimizerDetailsHiddenDetailsCenterTablesTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  driver: OrderOptimizerDetailsItemDriver | null;
  orders: OrderOptimizerDetailsItemOrders[];
  ordersSteps: OrderOptimizerDetailsItemStepsOrders[];
};

const OrderOptimizerDetailsHiddenDetailsCenterTablesTableComponent: FC<
  OrderOptimizerDetailsHiddenDetailsCenterTablesTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsHiddenDetailsCenterTablesTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const allStepPoints = useMemo(() => {
    return props.ordersSteps.map((step) => step.points).flat();
  }, [props.ordersSteps]);

  const stepOffsets: number[] = useMemo(() => {
    const offsets: number[] = [];
    let totalOffset = 0;

    props.ordersSteps.forEach((steps) => {
      offsets.push(totalOffset);
      totalOffset += steps.points.length;
    });

    return offsets;
  }, [props.ordersSteps]);

  const createTableRow = (
    order: OrderOptimizerDetailsItemOrders,
    steps: OrderOptimizerDetailsItemStepsOrders,
    stepOffset: number,
    orderIndex: number
  ): OrderOptimizerDetailsHiddenDetailsCenterTablesTableRow => {
    return {
      id: uuidService.generate(),
      value: {
        orders: (
          <div className="order_optimizer_details_hidden_details_center_tables_order">
            <OrderOptimizerDetailsHiddenDetailsTablesOrdersComponent
              allStepPoints={allStepPoints}
              order={order}
              step={steps}
              stepOffset={stepOffset}
              orderIndex={orderIndex}
              driver={props.driver}
            />
          </div>
        ),
        driver: (
          <div className="order_optimizer_details_hidden_details_center_tables_driver">
            <OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableComponent
              detailsStepsOrdersPointsItems={steps.points}
            />
          </div>
        ),
      },
    };
  };

  const rows: OrderOptimizerDetailsHiddenDetailsCenterTablesTableRow[] =
    useMemo(() => {
      return props.orders.map((order, orderIndex) =>
        createTableRow(
          order,
          props.ordersSteps[orderIndex],
          stepOffsets[orderIndex],
          orderIndex
        )
      );
    }, [props.orders, props.ordersSteps, stepOffsets, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
      noHeader
    />
  );
};

export default OrderOptimizerDetailsHiddenDetailsCenterTablesTableComponent;
