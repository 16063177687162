enum MileageListingFilterType {
  MILEAGE_DATE = "MILEAGE_DATE",
  HUMAN_ID = "HUMAN_ID",
  CARGO_COMPANY_NAME = "CARGO_COMPANY_NAME",
  MILEAGE_NUMBER = "MILEAGE_NUMBER",
  WORKER_NAME = "WORKER_NAME",
  CARD_NUMBER = "CARD_NUMBER",
  START_ADDRESS = "START_ADDRESS",
  END_ADDRESS = "END_ADDRESS",
  DISTANCE = "DISTANCE",
  VEHICLE_TYPE = "VEHICLE_TYPE",
  STATE = "STATE",
}

export default MileageListingFilterType;
