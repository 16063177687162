import DelegationListingRouteQueryParams from "../../common/routes/types/delegation-listing-route-params";
import DelegationListingDelegationStatus from "../common/types/delegation-listing-delegation-status";
import DelegationListingDelegationType from "../common/types/delegation-listing-delegation-type";
import DelegationListingFilter, {
  DelegationListingCostFilter,
  DelegationListingCreationDateFilter,
  DelegationListingDelegationNumberFilter,
  DelegationListingEndDateFilter,
  DelegationListingFirstCsvDownloadDateFilter,
  DelegationListingFirstPdfDownloadDateFilter,
  DelegationListingStartDateFilter,
  DelegationListingDelegationTypeFilter,
  DelegationListingWorkerNameFilter,
  DelegationListingCargoCompanyNameFilter,
  DelegationListingTotalDistanceFilter,
  DelegationListingStateFilter,
} from "../common/types/delegation-listing-filter";
import DelegationListingFilterType from "../common/types/delegation-listing-filter-type";
import DelegationListingSortKey from "../common/types/delegation-listing-sort-key";

const getSortKey = (
  routeQueryParams: DelegationListingRouteQueryParams
): DelegationListingSortKey | undefined => {
  return routeQueryParams.sort as DelegationListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: DelegationListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: DelegationListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const getFilters = (
  routeQueryParams: DelegationListingRouteQueryParams
): DelegationListingFilter[] => {
  const filters: DelegationListingFilter[] = [];

  if (routeQueryParams.startDateSince && routeQueryParams.startDateTo) {
    const startDateFilter: DelegationListingStartDateFilter = {
      type: DelegationListingFilterType.START_DATE,
      value: {
        from: new Date(routeQueryParams.startDateSince),
        to: new Date(routeQueryParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryParams.endDateSince && routeQueryParams.endDateTo) {
    const endDateFilter: DelegationListingEndDateFilter = {
      type: DelegationListingFilterType.END_DATE,
      value: {
        from: new Date(routeQueryParams.endDateSince),
        to: new Date(routeQueryParams.endDateTo),
      },
    };

    filters.push(endDateFilter);
  }

  if (routeQueryParams.creationDateSince && routeQueryParams.creationDateTo) {
    const creationDateFilter: DelegationListingCreationDateFilter = {
      type: DelegationListingFilterType.CREATION_DATE,
      value: {
        from: new Date(routeQueryParams.creationDateSince),
        to: new Date(routeQueryParams.creationDateTo),
      },
    };

    filters.push(creationDateFilter);
  }

  if (
    routeQueryParams.firstCsvDownloadDateSince &&
    routeQueryParams.firstCsvDownloadDateTo
  ) {
    const firstCsvDownloadDateFilter: DelegationListingFirstCsvDownloadDateFilter =
      {
        type: DelegationListingFilterType.FIRST_CSV_DOWNLOAD,
        value: {
          from: new Date(routeQueryParams.firstCsvDownloadDateSince),
          to: new Date(routeQueryParams.firstCsvDownloadDateTo),
        },
      };

    filters.push(firstCsvDownloadDateFilter);
  }

  if (
    routeQueryParams.firstPdfDownloadDateSince &&
    routeQueryParams.firstPdfDownloadDateTo
  ) {
    const firstPdfDownloadDateFilter: DelegationListingFirstPdfDownloadDateFilter =
      {
        type: DelegationListingFilterType.FIRST_PDF_DOWNLOAD,
        value: {
          from: new Date(routeQueryParams.firstPdfDownloadDateSince),
          to: new Date(routeQueryParams.firstPdfDownloadDateTo),
        },
      };

    filters.push(firstPdfDownloadDateFilter);
  }

  if (routeQueryParams.cargoCompanyName) {
    const cargoCompanyNameFilter: DelegationListingCargoCompanyNameFilter = {
      type: DelegationListingFilterType.CARGO_COMPANY_NAME,
      value: routeQueryParams.cargoCompanyName,
    };

    filters.push(cargoCompanyNameFilter);
  }

  if (routeQueryParams.delegationNumber) {
    const delegationNumberFilter: DelegationListingDelegationNumberFilter = {
      type: DelegationListingFilterType.DELEGATION_NUMBER,
      value: routeQueryParams.delegationNumber,
    };

    filters.push(delegationNumberFilter);
  }

  if (routeQueryParams.workerName) {
    const workerNameFilter: DelegationListingWorkerNameFilter = {
      type: DelegationListingFilterType.WORKER_NAME,
      value: routeQueryParams.workerName,
    };

    filters.push(workerNameFilter);
  }

  if (routeQueryParams.totalDistanceMin && routeQueryParams.totalDistanceMax) {
    const totalDistanceFilter: DelegationListingTotalDistanceFilter = {
      type: DelegationListingFilterType.TOTAL_DISTANCE,
      value: {
        from: Number.parseFloat(routeQueryParams.totalDistanceMin),
        to: Number.parseFloat(routeQueryParams.totalDistanceMax),
      },
    };

    filters.push(totalDistanceFilter);
  }

  if (routeQueryParams.type) {
    const typeFilter: DelegationListingDelegationTypeFilter = {
      type: DelegationListingFilterType.DELEGATION_TYPE,
      value: routeQueryParams.type as DelegationListingDelegationType,
    };

    filters.push(typeFilter);
  }

  if (routeQueryParams.type) {
    const typeFilter: DelegationListingDelegationTypeFilter = {
      type: DelegationListingFilterType.DELEGATION_TYPE,
      value:
        routeQueryParams.type === "AUTO"
          ? DelegationListingDelegationType.AUTO
          : DelegationListingDelegationType.MANUAL,
    };

    filters.push(typeFilter);
  }

  if (routeQueryParams.state) {
    const typeFilter: DelegationListingStateFilter = {
      type: DelegationListingFilterType.STATE,
      value: routeQueryParams.type as DelegationListingDelegationStatus,
    };

    filters.push(typeFilter);
  }

  if (routeQueryParams.state) {
    const typeFilter: DelegationListingStateFilter = {
      type: DelegationListingFilterType.STATE,
      value:
        routeQueryParams.state === "UNDOWNLOADED"
          ? DelegationListingDelegationStatus.UNDOWNLOADED
          : routeQueryParams.state === "DOWNLOADED"
          ? DelegationListingDelegationStatus.DOWNLOADED
          : DelegationListingDelegationStatus.DELETED,
    };

    filters.push(typeFilter);
  }

  if (routeQueryParams.costMax && routeQueryParams.costMin) {
    const costFilter: DelegationListingCostFilter = {
      type: DelegationListingFilterType.COST,
      value: {
        from: Number.parseFloat(routeQueryParams.costMin),
        to: Number.parseFloat(routeQueryParams.costMax),
      },
    };

    filters.push(costFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: DelegationListingFilter[],
  sort: DelegationListingSortKey,
  page: number,
  pageSize: number
): DelegationListingRouteQueryParams => {
  const startDateFilterValue = filters.find(
    (filter) => filter.type == DelegationListingFilterType.START_DATE
  )?.value as DelegationListingStartDateFilter["value"] | undefined;

  const endDateFilterValue = filters.find(
    (filter) => filter.type == DelegationListingFilterType.END_DATE
  )?.value as DelegationListingEndDateFilter["value"] | undefined;

  const creationDateFilterValue = filters.find(
    (filter) => filter.type == DelegationListingFilterType.CREATION_DATE
  )?.value as DelegationListingCreationDateFilter["value"] | undefined;

  const firstCsvDownloadDateFilterValue = filters.find(
    (filter) => filter.type == DelegationListingFilterType.FIRST_CSV_DOWNLOAD
  )?.value as DelegationListingFirstCsvDownloadDateFilter["value"] | undefined;

  const firstPdfDownloadDateFilterValue = filters.find(
    (filter) => filter.type == DelegationListingFilterType.FIRST_PDF_DOWNLOAD
  )?.value as DelegationListingFirstPdfDownloadDateFilter["value"] | undefined;

  const totalDistance = filters.find(
    (filter) => filter.type == DelegationListingFilterType.TOTAL_DISTANCE
  )?.value as DelegationListingTotalDistanceFilter["value"] | undefined;

  const cost = filters.find(
    (filter) => filter.type == DelegationListingFilterType.COST
  )?.value as DelegationListingCostFilter["value"] | undefined;

  const routerQueryParams: DelegationListingRouteQueryParams = {
    page: page ? String(page) : undefined,
    pageSize: pageSize ? String(pageSize) : undefined,
    sort: sort,
    startDateSince: startDateFilterValue?.from.toJSON(),
    startDateTo: startDateFilterValue?.to.toJSON(),
    endDateSince: endDateFilterValue?.from.toJSON(),
    endDateTo: endDateFilterValue?.to.toJSON(),
    creationDateSince: creationDateFilterValue?.from.toJSON(),
    creationDateTo: creationDateFilterValue?.to.toJSON(),
    firstCsvDownloadDateSince: firstCsvDownloadDateFilterValue?.from.toJSON(),
    firstCsvDownloadDateTo: firstCsvDownloadDateFilterValue?.to.toJSON(),
    firstPdfDownloadDateSince: firstPdfDownloadDateFilterValue?.from.toJSON(),
    firstPdfDownloadDateTo: firstPdfDownloadDateFilterValue?.to.toJSON(),
    delegationNumber: filters.find(
      (filter) => filter.type == DelegationListingFilterType.DELEGATION_NUMBER
    )?.value as DelegationListingDelegationNumberFilter["value"] | undefined,
    cargoCompanyName: filters.find(
      (filter) => filter.type == DelegationListingFilterType.CARGO_COMPANY_NAME
    )?.value as DelegationListingCargoCompanyNameFilter["value"] | undefined,
    workerName: filters.find(
      (filter) => filter.type == DelegationListingFilterType.WORKER_NAME
    )?.value as DelegationListingWorkerNameFilter["value"] | undefined,
    costMax: cost && String(cost.from),
    costMin: cost && String(cost.to),
    totalDistanceMax: totalDistance && String(totalDistance.from),
    totalDistanceMin: totalDistance && String(totalDistance.to),
    type: filters.find(
      (filter) => filter.type == DelegationListingFilterType.DELEGATION_TYPE
    )?.value as DelegationListingDelegationTypeFilter["value"] | undefined,
    state: filters.find(
      (filter) => filter.type == DelegationListingFilterType.STATE
    )?.value as DelegationListingStateFilter["value"] | undefined,
  };

  return routerQueryParams;
};

const delegationListingFiltersService = {
  getSortKey,
  getFilters,
  createRouteQueryParams,
  getPage,
  getPageSize,
};

export default delegationListingFiltersService;
