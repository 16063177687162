import DriverDetailsRequest from "../../api/raily/driver/details/driver-details.request";
import DriverDownloadRequest from "../../api/raily/driver/download/driver-download.request";
import driverApiService from "../../api/raily/driver/driver-api.service";
import DriverListRequest from "../../api/raily/driver/list/driver-list.request";
import dataLoadErrorFactory from "../../utils/data-load-error/data-load-error.factory";
import fileDownloadService from "../../utils/file-download/file-download.service";
import HttpError from "../../utils/http/http.error";
import httpHelper from "../../utils/http/http.helper";
import DriverDetails from "./details/driver-details";
import DriverDetailsLoadParams from "./details/driver-details-load-params";
import driverDetailsRequestFactory from "./details/driver-details-request.factory";
import driverDetailsFactory from "./details/driver-details.factory";
import DriverDownloadError from "./download/driver-download-error";
import DriverDownloadParams from "./download/driver-download-params";
import driverDownloadRequestFactory from "./download/driver-download-request.factory";
import DriverList from "./list/driver-list";
import DriverListError from "./list/driver-list-error";
import DriverListLoadParams from "./list/driver-list-load-params";
import driverListRequestFactory from "./list/driver-list-request.factory";
import driverListFactory from "./list/driver-list.factory";

const handleListError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getList = async (
  params: DriverListLoadParams,
  abortSignal: AbortSignal
): Promise<DriverList> => {
  const request: DriverListRequest = driverListRequestFactory.create(params);

  try {
    const response = await driverApiService().getList(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const driverList: DriverList = driverListFactory.create(response);

    return driverList;
  } catch (_error) {
    const error = _error as HttpError | DriverListError;

    throw handleListError(error);
  }
};

const handleDownloadError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const download = async (params: DriverDownloadParams): Promise<void> => {
  const request: DriverDownloadRequest =
    driverDownloadRequestFactory.create(params);

  try {
    const response = await driverApiService().download(request);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    fileDownloadService.downloadFromBlob(
      response.data.data,
      response.data.filename
    );
  } catch (_error) {
    const error = _error as HttpError | DriverDownloadError;

    throw handleDownloadError(error);
  }
};

const handleDetailsError = (
  error: HttpError | DriverListError
): DriverListError => {
  if (!httpHelper.checkIsHttpError(error)) {
    return error;
  }

  switch (error) {
    case HttpError.OFFLINE:
      return dataLoadErrorFactory.createOfflineError();
    default:
      return dataLoadErrorFactory.createUnexpectedError();
  }
};

const getDetails = async (
  params: DriverDetailsLoadParams,
  abortSignal: AbortSignal
): Promise<DriverDetails> => {
  const request: DriverDetailsRequest =
    driverDetailsRequestFactory.create(params);

  try {
    const response = await driverApiService().getDetails(request, abortSignal);

    if (response.status !== 200) {
      throw dataLoadErrorFactory.createUnexpectedError();
    }

    const driverDetails = driverDetailsFactory.create(response.data);

    return driverDetails;
  } catch (_error) {
    const error = _error as HttpError | DriverListError;

    throw handleDetailsError(error);
  }
};

const driverService = {
  getList,
  getDetails,
  download,
};

export default driverService;
