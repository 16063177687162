import CargoCompanyAddTranslations from "./types/cargo/company/company/cargo-company-add.translations";
import appTranslationsHelper from "./app-translations.helper";
import CargoCompanyListingTranslations from "./types/cargo/company/company/cargo-company-listing.translations";
import CargoAddressAddTranslations from "./types/cargo/address/cargo-address-add.translations";
import CargoAddressListingTranslations from "./types/cargo/address/cargo-address-listing.translations";
import CargoTaxiContractAddTranslations from "./types/cargo/taxi-contract/cargo-taxi-contract-add.translations";
import CargoTaxiContractListingTranslations from "./types/cargo/taxi-contract/cargo-taxi-contract-listing.translations";
import CargoAddressEditTranslations from "./types/cargo/address/cargo-address-edit.translations";
import CargoDispatchTranslations from "./types/cargo/company/dispatch/cargo-dispatch.translations";
import CargoDispatchUpdateTranslations from "./types/cargo/company/dispatch/cargo-dispatch-update.translations";
import CargoDispatchAddTranslations from "./types/cargo/company/dispatch/cargo-dispatch-add.translations";
import CargoCargoTaxiContractListingTranslations from "./types/cargo/cargo-taxi-contract/cargo-cargo-taxi-contract-listing.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `cargo`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getCompanyAddTranslations = (): CargoCompanyAddTranslations => {
  const translations = getTranslations<CargoCompanyAddTranslations>(
    `company/company/cargo-company-add.translations.json`
  );

  return translations;
};

const getCompanyListingTranslations = (): CargoCompanyListingTranslations => {
  const translations = getTranslations<CargoCompanyListingTranslations>(
    `company/company/cargo-company-listing.translations.json`
  );

  return translations;
};

const getCargoTaxiContractListingTranslations =
  (): CargoCargoTaxiContractListingTranslations => {
    const translations =
      getTranslations<CargoCargoTaxiContractListingTranslations>(
        `cargo-taxi-contract/cargo-cargo-taxi-contract-listing.translations.json`
      );

    return translations;
  };

const getTaxiContractListingTranslations =
  (): CargoTaxiContractListingTranslations => {
    const translations = getTranslations<CargoTaxiContractListingTranslations>(
      `taxi-contract/cargo-taxi-contract-listing.translations.json`
    );

    return translations;
  };

const getAddressAddTranslations = (): CargoAddressAddTranslations => {
  const translations = getTranslations<CargoAddressAddTranslations>(
    `address/cargo-address-add.translations.json`
  );

  return translations;
};

const getAddressEditTranslations = (): CargoAddressEditTranslations => {
  const translations = getTranslations<CargoAddressEditTranslations>(
    `address/cargo-address-edit.translations.json`
  );

  return translations;
};

const getAddressListingTranslations = (): CargoAddressListingTranslations => {
  const translations = getTranslations<CargoAddressListingTranslations>(
    `address/cargo-address-listing.translations.json`
  );

  return translations;
};

const getTaxiContractAddTranslations = (): CargoTaxiContractAddTranslations => {
  const translations = getTranslations<CargoTaxiContractAddTranslations>(
    `taxi-contract/cargo-taxi-contract-add.translations.json`
  );

  return translations;
};

const getDispatchTranslations = (): CargoDispatchTranslations => {
  const translations = getTranslations<CargoDispatchTranslations>(
    `company/dispatch/cargo-dispatch.translations.json`
  );

  return translations;
};

const getDispatchUpdateTranslations = (): CargoDispatchUpdateTranslations => {
  const translations = getTranslations<CargoDispatchUpdateTranslations>(
    `company/dispatch/cargo-dispatch-update.translations.json`
  );

  return translations;
};

const getDispatchAddTranslations = (): CargoDispatchAddTranslations => {
  const translations = getTranslations<CargoDispatchAddTranslations>(
    `company/dispatch/cargo-dispatch-add.translations.json`
  );

  return translations;
};

const cargoTranslationsHelper = {
  getCompanyAddTranslations,
  getCompanyListingTranslations,
  getTaxiContractListingTranslations,
  getCargoTaxiContractListingTranslations,
  getAddressAddTranslations,
  getAddressEditTranslations,
  getAddressListingTranslations,
  getTaxiContractAddTranslations,
  getDispatchTranslations,
  getDispatchUpdateTranslations,
  getDispatchAddTranslations,
};

export default cargoTranslationsHelper;
