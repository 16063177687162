import OrderOptimizerRoadRouteLoadParams, {
  OrderOptimizerRoadRouteWaypoints,
} from "../../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import OrderOptimizerDetailsItem, {
  OrderOptimizerDetailsItemOrders,
  OrderOptimizerDetailsItemStepsOrdersPoints,
} from "./types/order-optimizer-details-item";
import OrderOptimizerDetailsWaypoint from "./types/order-optimizer-details-waypoint";

const createWaypoint = (
  order: OrderOptimizerDetailsItemOrders,
  point: OrderOptimizerDetailsItemStepsOrdersPoints
): OrderOptimizerDetailsWaypoint => {
  return {
    humanId: order.humanId,
    lat: point.lat,
    lon: point.lon,
  };
};

const createWaypoints = (
  responseTransportingOrderOptimizations: OrderOptimizerDetailsItem,
  hiddenOrders: number[]
): OrderOptimizerDetailsWaypoint[][] => {
  const splitWaypoints: OrderOptimizerDetailsWaypoint[][] = [];

  responseTransportingOrderOptimizations.steps.orders.forEach(
    (stepOrder, index) => {
      const order = responseTransportingOrderOptimizations.orders[index];

      if (hiddenOrders.includes(order.humanId)) {
        return;
      }

      const waypointsForOrder = stepOrder.points.map((point) =>
        createWaypoint(order, point)
      );
      splitWaypoints.push(waypointsForOrder);
    }
  );

  return splitWaypoints;
};

const createSearchRoutingCoordinate = (waypoint: {
  humanId: number;
  lat: number;
  lon: number;
}): OrderOptimizerRoadRouteWaypoints => {
  return {
    lat: waypoint.lat,
    lon: waypoint.lon,
  };
};

const createSearchRoutingRequest = (
  waypoints: {
    humanId: number;
    lat: number;
    lon: number;
  }[]
): OrderOptimizerRoadRouteLoadParams => {
  const request: OrderOptimizerRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const orderOptimizerDetailsWaypointFactory = {
  createWaypoints,
  createSearchRoutingRequest,
};

export default orderOptimizerDetailsWaypointFactory;
