import { FC } from "react";
import { OrderOptimizerDetailsItemOrders } from "../../../../common/types/order-optimizer-details-item";
import orderOptimizerDetailsHelper from "../../../../order-optimizer-details.helper";
import dateService from "../../../../../../../common/utils/date/date.service";
import orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper from "./order-optimizer-details-hidden-details-center-tables-orders-order-summary.helper";
import orderOptimizerHelper from "../../../../../common/order-optimizer.helper";
import LinkButtonComponent from "../../../../../../../common/components/button/link/link-button.component";
import orderRoutesHelper from "../../../../../../order/common/routes/order-routes.helper";

type OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderSummaryProps = {
  order: OrderOptimizerDetailsItemOrders;
  orderIndex: number;
};

const OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderSummaryComponent: FC<
  OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderSummaryProps
> = (props) => {
  const dotColor = orderOptimizerHelper.getOrderColor(props.orderIndex);
  const currentDate = new Date();
  const startTime = new Date(props.order.startTime);

  const orderIdTitle =
    orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper.getOrderIdTitle(
      props.order.humanId
    );
  const orderIdLabel =
    orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper.getOrderIdLabel(
      props.order.humanId
    );

  const timeDifferenceInSeconds = Math.max(
    Math.floor((startTime.getTime() - currentDate.getTime()) / 1000),
    0
  );

  const startTimeString = orderOptimizerDetailsHelper.getTimeDeltaLabel(
    timeDifferenceInSeconds
  );

  const startUntilTitle =
    orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper.getStartTimeTitle(
      startTimeString
    );
  const startUntilLabel =
    orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper.getStartTimeLabel(
      startTimeString
    );

  const startDateTitle = dateService.formatDate(props.order.startTime);
  const startDateLabel = dateService.formatDate(props.order.startTime);

  const planEntryIdTitle =
    orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper.getPlanEntryIdTitle(
      props.order.planEntryHumanId ?? 0
    );
  const routeIdLabel =
    orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper.getPlanEntryIdLabel(
      props.order.planEntryHumanId ?? 0
    );

  return (
    <div className="order_summary">
      <div className="order_id">
        <div
          className="dot"
          style={{
            backgroundColor: dotColor,
          }}
        />
        <LinkButtonComponent
          openInNewTab
          to={orderRoutesHelper.getListingOfActiveOrdersRoute({
            internalOrderId: String(props.order.humanId),
          })}
        >
          <div className="text" title={orderIdTitle}>
            {orderIdLabel}
          </div>
        </LinkButtonComponent>
      </div>
      <div title={props.order.cargoCompany}>{props.order.cargoCompany}</div>
      <div title={props.order.billingModel}>{props.order.billingModel}</div>
      <div title={props.order.taxiCorporation}>
        {props.order.taxiCorporation}
      </div>
      <div title={startUntilTitle}>{startUntilLabel}</div>
      <div title={startDateTitle}>{startDateLabel}</div>
      {props.order.driverName && (
        <div className="driver_name" title={props.order.driverName}>
          {props.order.driverName}
        </div>
      )}
      {props.order.fleetPartner && (
        <div title={props.order.fleetPartner}>{props.order.fleetPartner}</div>
      )}
      {props.order.planEntryHumanId && (
        <div title={planEntryIdTitle}>{routeIdLabel}</div>
      )}
    </div>
  );
};

export default OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderSummaryComponent;
