import { OrderOptimizerListItemRoute } from "../../../../common/services/order-optimizer/list/order-optimizer-list";
import OrderOptimizerRoadRouteLoadParams, {
  OrderOptimizerRoadRouteWaypoints,
} from "../../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import {
  OrderOptimizerListingItem,
  OrderOptimizerListingItemRoute,
} from "./types/order-optimizer-listing-item";

const createWaypoint = (
  address: OrderOptimizerListItemRoute
): OrderOptimizerListingItemRoute => {
  return {
    lat: address.lat,
    lon: address.lon,
    orderHumanId: address.orderHumanId,
  };
};

const createWaypoints = (
  responseTransportingOrderOptimizations: OrderOptimizerListingItem
): OrderOptimizerListingItemRoute[][] => {
  const splitWaypoints: OrderOptimizerListItemRoute[][] = [];

  responseTransportingOrderOptimizations.orders.forEach((order) => {
    splitWaypoints.push(
      responseTransportingOrderOptimizations.route.filter(
        (point) => point.orderHumanId === order.humanId
      )
    );
  });

  return splitWaypoints.map((route) => route.map(createWaypoint));
};

const createSearchRoutingCoordinate = (
  waypoint: OrderOptimizerListingItemRoute
): OrderOptimizerRoadRouteWaypoints => {
  return {
    lat: waypoint.lat,
    lon: waypoint.lon,
  };
};

const createSearchRoutingRequest = (
  waypoints: OrderOptimizerListingItemRoute[]
): OrderOptimizerRoadRouteLoadParams => {
  const request: OrderOptimizerRoadRouteLoadParams = {
    waypoints: waypoints.map(createSearchRoutingCoordinate),
  };

  return request;
};

const orderOptimizerDetailsWaypointFactory = {
  createWaypoints,
  createSearchRoutingRequest,
};

export default orderOptimizerDetailsWaypointFactory;
