import UserStatus from "../../../types/user-status";

export enum DriverDownloadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  FLEET_PARTNER_NAME_ASC = "FLEET_PARTNER_NAME_ASC",
  FLEET_PARTNER_NAME_DESC = "FLEET_PARTNER_NAME_DESC",
  TAXI_CORPORATION_DISPLAY_NAME_ASC = "TAXI_CORPORATION_DISPLAY_NAME_ASC",
  TAXI_CORPORATION_DISPLAY_NAME_DESC = "TAXI_CORPORATION_DISPLAY_NAME_DESC",
  LAST_PLAN_ENTRY_END_TIME_ASC = "LAST_PLAN_ENTRY_END_TIME_ASC",
  LAST_PLAN_ENTRY_END_TIME_DESC = "LAST_PLAN_ENTRY_END_TIME_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  MODIFIED_AT_ASC = "MODIFIED_AT_ASC",
  MODIFIED_AT_DESC = "MODIFIED_AT_DESC",
}

export enum DriverDownloadParamsTimezone {
  EUROPE_WARSAW = "Europe/Warsaw",
  UTC = "UTC",
}

export type DriverDownloadParamsSeparatorTypes = ";" | ",";

type DriverDownloadParams = {
  taxiCorporation?: string;
  taxiCorporationId?: string;
  fleetPartner?: string;
  fleetPartnerId?: string;
  address?: string;
  pendingCandidature?: boolean;
  order?: DriverDownloadParamsOrder;
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  statusCriteria?: UserStatus;
  creationStart?: Date;
  creationEnd?: Date;
  modificationStart?: Date;
  modificationEnd?: Date;
  separator?: DriverDownloadParamsSeparatorTypes;
  timezone?: DriverDownloadParamsTimezone;
};

export default DriverDownloadParams;
