import SearchRoadRoutingRequest from "../../../utils/search-road-route/search-road-routing.request";
import SearchRoadRoutingResponse from "../../../utils/search-road-route/search-road-routing.response";
import searchRoadRoutingService from "../../../utils/search-road-route/search-road-routing.service";
import railyApiService from "../raily-api.service";
import OrderOptimizerDetailsRequest from "./details/order-optimizer-details.request";
import OrderOptimizerDetailsResponse from "./details/order-optimizer-details.response";
import orderOptimizerListRequestFactory from "./list/order-optimizer-list-request.factory";
import OrderOptimizerListRequest, {
  OrderOptimizerListRequestQueryParams,
} from "./list/order-optimizer-list.request";
import OrderOptimizerListResponse from "./list/order-optimizer-list.response";
import OrderOptimizerOptimizationStatusRequest from "./optimization-status/order-optimizer-optimization-status.request";
import OrderOptimizerOptimizationStatusResponse from "./optimization-status/order-optimizer-optimization-status.response";
import OrderOptimizerPotentialDriversListRequest, {
  OrderOptimizerPotentialDriversListQueryParams,
} from "./potential-drivers-list/order-optimizer-potential-drivers-list.request";
import OrderOptimizerPotentialDriversListResponse from "./potential-drivers-list/order-optimizer-potential-drivers-list.response";
import orderOptimizerDetailsRequestFactory from "./details/order-optimizer-details-request.factory";
import orderOptimizerPotentialDriversListRequestFactory from "./potential-drivers-list/order-optimizer-potential-drivers-list-request.factory";

const orderOptimizerApiService = () => {
  const getList = async (
    request: OrderOptimizerListRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerListResponse> => {
    const url = `/optimizations`;

    const queryParams: OrderOptimizerListRequestQueryParams =
      orderOptimizerListRequestFactory.createQueryParams(request);

    return railyApiService(
      process.env.OPTIMIZER_URL
    ).get<OrderOptimizerListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getRoute = (
    request: SearchRoadRoutingRequest
  ): Promise<SearchRoadRoutingResponse> => {
    return searchRoadRoutingService.search(request);
  };

  const getDetails = async (
    request: OrderOptimizerDetailsRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerDetailsResponse> => {
    const url = `/optimizations/${request.optimizationId}`;
    const queryParams =
      orderOptimizerDetailsRequestFactory.createQueryParams(request);

    return railyApiService(
      process.env.OPTIMIZER_URL
    ).get<OrderOptimizerDetailsResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  const getOptimizationStatus = async (
    request: OrderOptimizerOptimizationStatusRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerOptimizationStatusResponse> => {
    const url = `/optimizations/${request.optimizationId}/status`;

    return railyApiService(process.env.OPTIMIZER_URL).get({
      url,
      abortSignal,
    });
  };

  const getPotentialDriversList = async (
    request: OrderOptimizerPotentialDriversListRequest,
    abortSignal: AbortSignal
  ): Promise<OrderOptimizerPotentialDriversListResponse> => {
    const url = `/optimizations/${request.optimizationId}/potential-drivers`;
    const queryParams: OrderOptimizerPotentialDriversListQueryParams =
      orderOptimizerPotentialDriversListRequestFactory.createQueryParams(
        request
      );

    return railyApiService(
      process.env.OPTIMIZER_URL
    ).get<OrderOptimizerPotentialDriversListResponse>({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getList,
    getRoute,
    getDetails,
    getOptimizationStatus,
    getPotentialDriversList,
  };
};

export default orderOptimizerApiService;
