import useAsyncData from "../../../hooks/use-async-data";
import orderOptimizerService from "../order-optimizer.service";
import OrderOptimizerPotentialDriversList from "./order-optimizer-potential-drivers-list";
import OrderOptimizerPotentialDriversListLoadParams from "./order-optimizer-potential-drivers-list-load-params";

const useOrderOptimizerPotentialDriversList = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<OrderOptimizerPotentialDriversList>([]);

  const load = async (
    params: OrderOptimizerPotentialDriversListLoadParams,
    abortSignal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const data = await orderOptimizerService.getPotentialDriversList(
        params,
        abortSignal
      );

      setData(data);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    isLoading,
    isError,
    load,
  };
};

export default useOrderOptimizerPotentialDriversList;
