import OrderOptimizerPotentialDriversListRequest, {
  OrderOptimizerPotentialDriversListQueryParams,
} from "./order-optimizer-potential-drivers-list.request";

const createQueryParams = (
  request: OrderOptimizerPotentialDriversListRequest
): OrderOptimizerPotentialDriversListQueryParams => {
  return {
    name: request.name,
  };
};

const orderOptimizerPotentialDriversListRequestFactory = {
  createQueryParams,
};

export default orderOptimizerPotentialDriversListRequestFactory;
