import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import orderOptimizerService from "../../../../common/services/order-optimizer/order-optimizer.service";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../common/utils/search-road-route/search-road-routing.response";
import {
  OrderOptimizerDetailsItemDriver,
  OrderOptimizerDetailsItemOrders,
  OrderOptimizerDetailsItemSteps,
  OrderOptimizerDetailsItemStepsOrdersPoints,
} from "./types/order-optimizer-details-item";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderOptimizerDetailsWaypoint from "./types/order-optimizer-details-waypoint";

const createMapMarker = (
  address: OrderOptimizerDetailsItemStepsOrdersPoints,
  addressNum: number
) => {
  const signature = `${addressNum + 1}`;

  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: signature,
  });

  const newMapMarker: MapMarker = {
    coordinate: {
      latitude: address.lat,
      longitude: address.lon,
    },
    icon: markerIcon,
  };

  return newMapMarker;
};

const createHomeMapMarker = (
  homeAddress: OrderOptimizerDetailsItemDriver
): MapMarker => {
  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: React.createElement(FontAwesomeIcon, {
      icon: faHome,
    }),
  });

  const marker: MapMarker = {
    coordinate: {
      latitude: homeAddress.startLat,
      longitude: homeAddress.startLon,
    },
    icon: markerIcon,
  };

  return marker;
};

const createRoutesFromHome = async (
  homeAddress: OrderOptimizerDetailsItemDriver,
  firstPoint: OrderOptimizerDetailsWaypoint,
  lastPoint: OrderOptimizerDetailsWaypoint,
  color: string
): Promise<MapRoute[]> => {
  const routes: MapRoute[] = [];

  try {
    const firstRouteResponse = await orderOptimizerService.getRoute({
      waypoints: [
        { lat: homeAddress.startLat, lon: homeAddress.startLon },
        { lat: firstPoint.lat, lon: firstPoint.lon },
      ],
    });
    routes.push(
      orderOptimizerDetailsRouteFactory.createMapRoute(
        firstRouteResponse.coordinates,
        color
      )
    );

    const lastRouteResponse = await orderOptimizerService.getRoute({
      waypoints: [
        { lat: lastPoint.lat, lon: lastPoint.lon },
        { lat: homeAddress.startLat, lon: homeAddress.startLon },
      ],
    });
    routes.push(
      orderOptimizerDetailsRouteFactory.createMapRoute(
        lastRouteResponse.coordinates,
        color
      )
    );
  } catch (error) {
    return [];
  }

  return routes;
};

const createMapMarkers = (
  steps: OrderOptimizerDetailsItemSteps,
  orders: OrderOptimizerDetailsItemOrders[],
  hiddenOrders: number[]
): MapMarker[] => {
  const mapMarkers: MapMarker[] = [];
  let addressNum = 0;

  steps.orders.forEach((step, orderIndex) => {
    const order = orders[orderIndex];
    if (!order || hiddenOrders.includes(order.humanId)) {
      return;
    }

    step.points.forEach((point) => {
      const marker = createMapMarker(point, addressNum);
      mapMarkers.push(marker);
      addressNum++;
    });
  });

  return mapMarkers;
};

const createMapRoute = (
  routesResponseGeometryCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[],
  color: string
): MapRoute => {
  return {
    waypoints: routesResponseGeometryCoordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
    options: {
      color: color,
    },
  };
};

const orderOptimizerDetailsRouteFactory = {
  createMapMarkers,
  createMapRoute,
  createHomeMapMarker,
  createRoutesFromHome,
};

export default orderOptimizerDetailsRouteFactory;
