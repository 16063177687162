import CargoTaxiContractListResponse, {
  CargoTaxiContractListResponseCargoCompany,
  CargoTaxiContractListResponseContractMaintenancePolicy,
  CargoTaxiContractListResponseItem,
  CargoTaxiContractListResponseModel,
  CargoTaxiContractListResponseTaxiCorporation,
  CargoTaxiContractListResponseTaxiCorporationFleetPartners,
} from "../../../api/raily/contracts/cargo-taxi-list/cargo-taxi-contract-list.response";
import CargoTaxiContractList, {
  CargoTaxiContractListCargoCompany,
  CargoTaxiContractListContractMaintenancePolicy,
  CargoTaxiContractListItem,
  CargoTaxiContractListModel,
  CargoTaxiContractListTaxiCorporation,
  CargoTaxiContractListTaxiCorporationFleetPartners,
} from "./cargo-taxi-contract-list";

const createFleetPartner = (
  fleetPartner: CargoTaxiContractListResponseTaxiCorporationFleetPartners
): CargoTaxiContractListTaxiCorporationFleetPartners => {
  return {
    createdAt: fleetPartner.createdAt,
    createdBy: fleetPartner.createdBy,
    id: fleetPartner.id,
    modifiedAt: fleetPartner.modifiedAt,
    modifiedBy: fleetPartner.modifiedBy,
    name: fleetPartner.name,
    taxiCorporationId: fleetPartner.taxiCorporationId,
  };
};

const createFleetPartners = (
  responseFleetPartners: CargoTaxiContractListResponseTaxiCorporationFleetPartners[]
): CargoTaxiContractListTaxiCorporationFleetPartners[] => {
  return responseFleetPartners.map(createFleetPartner);
};

const createModel = (
  model: CargoTaxiContractListResponseModel
): CargoTaxiContractListModel => {
  switch (model) {
    case CargoTaxiContractListResponseModel.AB:
      return CargoTaxiContractListModel.AB;
    case CargoTaxiContractListResponseModel.ABA:
      return CargoTaxiContractListModel.ABA;
    case CargoTaxiContractListResponseModel.OTHER:
      return CargoTaxiContractListModel.OTHER;
    case CargoTaxiContractListResponseModel.SABS:
      return CargoTaxiContractListModel.SABS;
  }
};

const createContractMainteancePolicy = (
  contractMainteancePolicy: CargoTaxiContractListResponseContractMaintenancePolicy
): CargoTaxiContractListContractMaintenancePolicy => {
  switch (contractMainteancePolicy) {
    case CargoTaxiContractListResponseContractMaintenancePolicy.AUCTION:
      return CargoTaxiContractListContractMaintenancePolicy.AUCTION;
    case CargoTaxiContractListResponseContractMaintenancePolicy.BASIC:
      return CargoTaxiContractListContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporation = (
  taxiCorporation: CargoTaxiContractListResponseTaxiCorporation
): CargoTaxiContractListTaxiCorporation => {
  return {
    companyName: taxiCorporation.companyName,
    contractMainteancePolicy: createContractMainteancePolicy(
      taxiCorporation.contractMainteancePolicy
    ),
    createdAt: taxiCorporation.createdAt,
    createdBy: taxiCorporation.createdBy,
    displayName: taxiCorporation.displayName,
    fleetPartners: createFleetPartners(taxiCorporation.fleetPartners),
    headquartersAddressString: taxiCorporation.headquartersAddressString,
    id: taxiCorporation.id,
    taxId: taxiCorporation.taxId,
    krsNo: taxiCorporation.krsNo,
    modifiedAt: taxiCorporation.modifiedAt,
    modifiedBy: taxiCorporation.modifiedBy,
    notes: taxiCorporation.notes,
    regon: taxiCorporation.regon,
  };
};

const createCargoCompany = (
  cargoCompany: CargoTaxiContractListResponseCargoCompany
): CargoTaxiContractListCargoCompany => {
  return {
    companyName: cargoCompany.companyName,
    contractMainteancePolicy: createContractMainteancePolicy(
      cargoCompany.contractMainteancePolicy
    ),
    createdAt: cargoCompany.createdAt,
    createdBy: cargoCompany.createdBy,
    displayName: cargoCompany.displayName,
    headquartersAddressString: cargoCompany.headquartersAddressString,
    id: cargoCompany.id,
    taxId: cargoCompany.taxId,
    krsNo: cargoCompany.krsNo,
    modifiedAt: cargoCompany.modifiedAt,
    modifiedBy: cargoCompany.modifiedBy,
    notes: cargoCompany.notes,
    regon: cargoCompany.regon,
  };
};

const createDataItem = (
  item: CargoTaxiContractListResponseItem
): CargoTaxiContractListItem => {
  return {
    allowChargeDuringRide: item.allowChargeDuringRide,
    allowChargeWhileApproaching: item.allowChargeWhileApproaching,
    allowChargeWhileReturning: item.allowChargeWhileReturning,
    cargoCompany: createCargoCompany(item.cargoCompany),
    cargoCompanyId: item.cargoCompanyId,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    discountLimit: item.discountLimit,
    distanceRate: item.distanceRate,
    haltingTimeAfterMinutes: item.haltingTimeAfterMinutes,
    haltingTimeRate: item.haltingTimeRate,
    id: item.id,
    model: createModel(item.model),
    publishingAllowed: item.publishingAllowed,
    taxiCorporation: createTaxiCorporation(item.taxiCorporation),
    taxiCorporationId: item.taxiCorporationId,
    validSince: item.validSince,
    validTo: item.validTo,
    isValid: item.isValid,
    modifiedAt: item.modifiedAt,
    modifiedBy: item.modifiedBy,
  };
};

const createData = (
  responseData: CargoTaxiContractListResponseItem[]
): CargoTaxiContractListItem[] => {
  const items: CargoTaxiContractListItem[] = responseData.map(createDataItem);

  const activeContracts = items.filter((contract) => contract.isValid);
  const inactiveContracts = items.filter((contract) => !contract.isValid);

  return [...activeContracts, ...inactiveContracts];
};

const create = (
  response: CargoTaxiContractListResponse
): CargoTaxiContractList => {
  return {
    data: createData(response.data.data),
    totalCount: response.data.totalCount,
  };
};

const cargoTaxiContractListFactory = {
  create,
};

export default cargoTaxiContractListFactory;
