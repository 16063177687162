import { FC } from "react";
import orderOptimizerTranslationsHelper from "../../../../../languages/order-optimizer-translations.helper";
import OrderOptimizerDetailsItem, {
  OrderOptimizerDetailsItemStepsOrdersPoints,
} from "../../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsOrdersTableDotComponent from "../../dot/order-optimizer-details-orders-table-dot.component";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import orderOptimizerDetailsHelper from "../../order-optimizer-details.helper";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";

type OrderOptimizerDetailsHiddenDetailsSummaryFooterProps = {
  detailsItem: OrderOptimizerDetailsItem;
};

const OrderOptimizerDetailsHiddenDetailsSummaryFooterComponent: FC<
  OrderOptimizerDetailsHiddenDetailsSummaryFooterProps
> = (props) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table
      .lowerSection;

  const dotColor = "#d9d9d9";

  const index = 0;

  const totalTimeSum =
    props.detailsItem.steps.orders.reduce(
      (sum, order) =>
        sum +
        order.points.reduce((sum, point) => sum + point.driverDrivingTime, 0),
      0
    ) + (props.detailsItem.steps.endToHome?.driverDrivingTime ?? 0);
  const haltingTimeSum = props.detailsItem.steps.orders.reduce(
    (sum, order) =>
      sum +
      order.points.reduce((sum, point) => sum + point.driverHaltingTime, 0),
    0
  );
  const distanceSum =
    props.detailsItem.steps.orders.reduce(
      (sum, order) =>
        sum +
        order.points.reduce((sum, point) => sum + point.driverDistance, 0),
      0
    ) + (props.detailsItem.steps.endToHome?.driverDistance ?? 0);

  const detailsStepsOrderPoints: OrderOptimizerDetailsItemStepsOrdersPoints[] =
    props.detailsItem.steps.orders.map((order) => order.points).flat();
  const detailsStepsOrderLastRoute: OrderOptimizerDetailsItemStepsOrdersPoints[] =
    props.detailsItem.steps.orders[props.detailsItem.steps.orders.length - 1]
      .points;
  const detailsStepsOrderLastPoint: OrderOptimizerDetailsItemStepsOrdersPoints[] =
    [detailsStepsOrderLastRoute[detailsStepsOrderLastRoute.length - 1]];

  return (
    <>
      <div className="order_optimizer_details_hidden_details__lower_section__left">
        <h4 className="order_optimizer_details_hidden_details__lower_section__left__driver">
          {props.detailsItem.driver?.displayName}
        </h4>
        <div className="order_optimizer_details_hidden_details__lower_section__left__order">
          <div className="order_optimizer_details_hidden_details__lower_section__left__order__human_id">
            {props.detailsItem.driver && (
              <>
                <span
                  className="order_optimizer_details_hidden_details__lower_section__left__order__human_id__text"
                  title={String(index)}
                >
                  {index}
                </span>
                <OrderOptimizerDetailsOrdersTableDotComponent
                  color={dotColor}
                />
              </>
            )}
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__left__order__text">
            {props.detailsItem.steps.endToHome?.displayName}
          </div>
          <div className="button_full_route">
            <LinkButtonComponent
              to={orderOptimizerDetailsHelper.getGoogleMapsUrl(
                detailsStepsOrderPoints,
                props.detailsItem.driver?.startLat,
                props.detailsItem.driver?.startLon,
                true,
                true
              )}
              title={translations.routeButtonTitle}
              type="primary"
              openInNewTab
            >
              {translations.routeButtonLabel}
            </LinkButtonComponent>
          </div>
          <div className="button_route">
            <TableLinkButtonComponent
              icon={faMap}
              openInNewTab
              to={orderOptimizerDetailsHelper.getGoogleMapsUrl(
                detailsStepsOrderLastPoint,
                props.detailsItem.driver?.startLat,
                props.detailsItem.driver?.startLon,
                false,
                true
              )}
              type="primary"
            />
          </div>
        </div>
      </div>
      <div className="order_optimizer_details_hidden_details__lower_section__right">
        <div className="order_optimizer_details_hidden_details__lower_section__right__driver">
          <div className="order_optimizer_details_hidden_details__lower_section__right__driver__cell">
            {orderOptimizerDetailsHelper.getTimeLabel(
              props.detailsItem.steps.endToHome?.driverTime ?? null
            )}
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__right__driver__cell">
            00:00 h
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__right__driver__cell">
            {orderOptimizerDetailsHelper.getTimeDeltaLabel(
              props.detailsItem.steps.endToHome?.driverDrivingTime
            )}
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__right__driver__cell">
            {orderOptimizerDetailsHelper.getDistanceLabel(
              props.detailsItem.steps.endToHome?.driverDistance
            )}
          </div>
        </div>
        <h5 className="order_optimizer_details_hidden_details__lower_section__right__text">
          {translations.summaryLabel}
        </h5>
        <div className="order_optimizer_details_hidden_details__lower_section__right__summary">
          <div className="order_optimizer_details_hidden_details__lower_section__right__summary__cell">
            {orderOptimizerDetailsHelper.getTimeDeltaLabel(totalTimeSum)}
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__right__summary__cell">
            {orderOptimizerDetailsHelper.getTimeDeltaLabel(haltingTimeSum)}
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__right__summary__cell">
            ---
          </div>
          <div className="order_optimizer_details_hidden_details__lower_section__right__summary__cell">
            {orderOptimizerDetailsHelper.getDistanceLabel(distanceSum)}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderOptimizerDetailsHiddenDetailsSummaryFooterComponent;
