import { CargoCargoTaxiContractListingRouteQueryParams } from "../../../common/routes/types/cargo-cargo-taxi-contract-listing-route-params";
import CargoCargoTaxiContractListingFilter, {
  CargoCargoTaxiContractListingActiveFilter,
  CargoCargoTaxiContractListingCargoCompanyFilter,
  CargoCargoTaxiContractListingTaxiCorporationFilter,
} from "../common/types/cargo-cargo-taxi-contract-listing-filter";
import CargoCargoTaxiContractListingFilterType from "../common/types/cargo-cargo-taxi-contract-listing-filter-type";
import CargoCargoTaxiContractListingSortKey from "../common/types/cargo-cargo-taxi-contract-listing-sort-key";

const getFilters = (
  routeQueryFilterParams: CargoCargoTaxiContractListingRouteQueryParams
): CargoCargoTaxiContractListingFilter[] => {
  const filters: CargoCargoTaxiContractListingFilter[] = [];

  if (routeQueryFilterParams.active) {
    const activeFilter: CargoCargoTaxiContractListingActiveFilter = {
      type: CargoCargoTaxiContractListingFilterType.ACTIVE,
      value: Boolean(routeQueryFilterParams.active),
    };

    filters.push(activeFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: CargoCargoTaxiContractListingCargoCompanyFilter =
      {
        type: CargoCargoTaxiContractListingFilterType.CARGO_COMPANY,
        value: routeQueryFilterParams.cargoCompany,
      };

    filters.push(cargoCompanyFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const taxiCorporationFilter: CargoCargoTaxiContractListingTaxiCorporationFilter =
      {
        type: CargoCargoTaxiContractListingFilterType.TAXI_CORPORATION,
        value: routeQueryFilterParams.taxiCorporation,
      };

    filters.push(taxiCorporationFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: CargoCargoTaxiContractListingRouteQueryParams
): CargoCargoTaxiContractListingSortKey | undefined => {
  return routeQueryParams.sort as CargoCargoTaxiContractListingSortKey;
};

const getPage = (
  routeQueryFilterParams: CargoCargoTaxiContractListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: CargoCargoTaxiContractListingRouteQueryParams
): number | undefined => {
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: CargoCargoTaxiContractListingFilter[],
  sortKey: CargoCargoTaxiContractListingSortKey | null,
  page: number,
  pageSize: number
): CargoCargoTaxiContractListingRouteQueryParams => {
  const routeQueryParams: CargoCargoTaxiContractListingRouteQueryParams = {
    active: filters.find(
      (filter) => filter.type == CargoCargoTaxiContractListingFilterType.ACTIVE
    )?.value as CargoCargoTaxiContractListingActiveFilter["value"] | undefined,
    cargoCompany: filters.find(
      (filter) =>
        filter.type === CargoCargoTaxiContractListingFilterType.CARGO_COMPANY
    )?.value as CargoCargoTaxiContractListingCargoCompanyFilter["value"],
    taxiCorporation: filters.find(
      (filter) =>
        filter.type === CargoCargoTaxiContractListingFilterType.TAXI_CORPORATION
    )?.value as CargoCargoTaxiContractListingTaxiCorporationFilter["value"],
    sort: sortKey ?? undefined,
    page: String(page),
    pageSize: String(pageSize),
  };

  return routeQueryParams;
};

const cargoCargoTaxiListingFiltersService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default cargoCargoTaxiListingFiltersService;
