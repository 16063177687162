import OrderOptimizerDetailsResponse, {
  OrderOptimizerDetailsResponseItemDriver,
  OrderOptimizerDetailsResponseItemOptimizedStats,
  OrderOptimizerDetailsResponseItemOrders,
  OrderOptimizerDetailsResponseItemOrdersByDriverAba,
  OrderOptimizerDetailsResponseItemOrdersByDriverSabs,
  OrderOptimizerDetailsResponseItemSteps,
  OrderOptimizerDetailsResponseItemStepsEndToHome,
  OrderOptimizerDetailsResponseItemStepsHomeToStart,
  OrderOptimizerDetailsResponseItemStepsOrders,
  OrderOptimizerDetailsResponseItemStepsOrdersPoints,
  OrderOptimizerDetailsResponseItemUnoptimizedStats,
} from "../../../api/raily/order-optimizer/details/order-optimizer-details.response";
import OrderOptimizerDetails, {
  OrderOptimizerDetailsDriver,
  OrderOptimizerDetailsOptimizedStats,
  OrderOptimizerDetailsOrders,
  OrderOptimizerDetailsOrdersByDriverAba,
  OrderOptimizerDetailsOrdersByDriverSabs,
  OrderOptimizerDetailsSteps,
  OrderOptimizerDetailsStepsEndToHome,
  OrderOptimizerDetailsStepsHomeToStart,
  OrderOptimizerDetailsStepsOrders,
  OrderOptimizerDetailsStepsOrdersPoints,
  OrderOptimizerDetailsUnoptimizedStats,
} from "./order-optimizer-details";

const createStepsOrderPoint = (
  stepsOrderPoint: OrderOptimizerDetailsResponseItemStepsOrdersPoints
): OrderOptimizerDetailsStepsOrdersPoints => {
  const point: OrderOptimizerDetailsStepsOrdersPoints = {
    displayName: stepsOrderPoint.displayName,
    distance: stepsOrderPoint.distance,
    driverDistance: stepsOrderPoint.driverDistance ?? 0,
    driverDrivingTime: stepsOrderPoint.driverDrivingTime ?? 0,
    driverHaltingTime: stepsOrderPoint.driverHaltingTime ?? 0,
    driverTime: stepsOrderPoint.driverTime,
    haltingTime: stepsOrderPoint.haltingTime,
    lat: stepsOrderPoint.lat,
    lon: stepsOrderPoint.lon,
    time: stepsOrderPoint.time,
  };

  return point;
};

const createStepsOrderPoints = (
  stepsOrderPoints: OrderOptimizerDetailsResponseItemStepsOrdersPoints[]
): OrderOptimizerDetailsStepsOrdersPoints[] => {
  return stepsOrderPoints.map(createStepsOrderPoint);
};

const createStepsOrder = (
  stepsOrder: OrderOptimizerDetailsResponseItemStepsOrders
): OrderOptimizerDetailsStepsOrders => {
  const order = {
    distance: stepsOrder.distance,
    haltingTime: stepsOrder.haltingTime,
    points: createStepsOrderPoints(stepsOrder.points),
    totalTime: stepsOrder.totalTime,
  };

  return order;
};

const createStepsOrders = (
  stepsOrders: OrderOptimizerDetailsResponseItemStepsOrders[]
): OrderOptimizerDetailsStepsOrders[] => {
  return stepsOrders.map(createStepsOrder);
};

const createStepsHomeToStart = (
  stepsHomeToStart: OrderOptimizerDetailsResponseItemStepsHomeToStart | null
): OrderOptimizerDetailsStepsHomeToStart | null => {
  if (!stepsHomeToStart) return null;
  const startStep = {
    displayName: stepsHomeToStart.displayName,
    driverTime: stepsHomeToStart.driverTime,
  };
  return startStep;
};

const createStepsEndToHome = (
  stepsEndToHome: OrderOptimizerDetailsResponseItemStepsEndToHome | null
): OrderOptimizerDetailsStepsEndToHome | null => {
  if (!stepsEndToHome) return null;
  const endStep = {
    displayName: stepsEndToHome.displayName,
    driverDistance: stepsEndToHome.driverDistance,
    driverDrivingTime: stepsEndToHome.driverDrivingTime,
    driverTime: stepsEndToHome.driverTime,
  };
  return endStep;
};

const createSteps = (
  steps: OrderOptimizerDetailsResponseItemSteps
): OrderOptimizerDetailsSteps => {
  const detailsSteps = {
    endToHome: createStepsEndToHome(steps.endToHome),
    homeToStart: createStepsHomeToStart(steps.homeToStart),
    orders: createStepsOrders(steps.orders),
  };
  return detailsSteps;
};

const createOrdersBySabs = (
  ordersBySabs: OrderOptimizerDetailsResponseItemOrdersByDriverSabs | null
): OrderOptimizerDetailsOrdersByDriverSabs | null => {
  if (!ordersBySabs) return null;
  const orders = {
    distance: ordersBySabs.distance,
    haltingTime: ordersBySabs.haltingTime,
    totalTime: ordersBySabs.totalTime,
  };
  return orders;
};

const createOrdersByAba = (
  ordersByABa: OrderOptimizerDetailsResponseItemOrdersByDriverAba | null
): OrderOptimizerDetailsOrdersByDriverAba | null => {
  if (!ordersByABa) return null;
  const orders = {
    distance: ordersByABa.distance,
    haltingTime: ordersByABa.haltingTime,
    totalTime: ordersByABa.totalTime,
  };
  return orders;
};

const createOrder = (
  order: OrderOptimizerDetailsResponseItemOrders
): OrderOptimizerDetailsOrders => {
  const detailsOrder = {
    billingModel: order.billingModel,
    cargoCompany: order.cargoCompany,
    distance: order.distance,
    driverName: order.driverName,
    fleetPartner: order.fleetPartner,
    haltingTime: order.haltingTime,
    humanId: order.humanId,
    planEntryHumanId: order.planEntryHumanId,
    startTime: order.startTime,
    taxiCorporation: order.taxiCorporation,
    totalTime: order.totalTime,
  };
  return detailsOrder;
};

const createOrders = (
  orders: OrderOptimizerDetailsResponseItemOrders[]
): OrderOptimizerDetailsOrders[] => {
  return orders.map(createOrder);
};

const createOptimizedStats = (
  optimizedStats: OrderOptimizerDetailsResponseItemOptimizedStats
): OrderOptimizerDetailsOptimizedStats => {
  const stats = {
    contractRate: optimizedStats.contractRate,
    distance: optimizedStats.distance,
    haltingTime: optimizedStats.haltingTime,
    margin: optimizedStats.margin,
    totalTime: optimizedStats.totalTime,
  };
  return stats;
};

const createUnoptimizedStats = (
  optimizedStats: OrderOptimizerDetailsResponseItemUnoptimizedStats
): OrderOptimizerDetailsUnoptimizedStats => {
  const stats = {
    contractRate: optimizedStats.contractRate,
    distance: optimizedStats.distance,
    haltingTime: optimizedStats.haltingTime,
    margin: optimizedStats.margin,
    totalTime: optimizedStats.totalTime,
  };
  return stats;
};

const createDriver = (
  driver: OrderOptimizerDetailsResponseItemDriver | null
): OrderOptimizerDetailsDriver | null => {
  if (!driver) return null;
  const detailsDriver = {
    contractRate: driver.contractRate,
    displayName: driver.displayName,
    driverId: driver.driverId,
    startLat: driver.startLat,
    startLon: driver.startLon,
    taxiCorporation: driver.taxiCorporation,
  };
  return detailsDriver;
};

const create = (
  response: OrderOptimizerDetailsResponse
): OrderOptimizerDetails => {
  return {
    driver: createDriver(response.data.driver),
    optimizedStats: createOptimizedStats(response.data.optimizedStats),
    orders: createOrders(response.data.orders),
    ordersByDriverAba: createOrdersByAba(response.data.ordersByDriverAba),
    ordersByDriverSabs: createOrdersBySabs(response.data.ordersByDriverSabs),
    reviewedAt: response.data.reviewedAt,
    reviewedBy: response.data.reviewedBy,
    saAndBsDistance: response.data.saAndBsDistance ?? 0,
    steps: createSteps(response.data.steps),
    unoptimizedStats: createUnoptimizedStats(response.data.unoptimizedStats),
    score: response.data.score,
    totalCargoIncome: response.data.totalCargoIncome,
    totalDriverHaltingCost: response.data.totalDriverHaltingCost ?? 0,
  };
};

const orderOptimizerDetailsFactory = {
  create,
};

export default orderOptimizerDetailsFactory;
