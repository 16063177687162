import OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableColumn from "../../types/order-optimizer-details-hidden-details-center-tables-orders-order-table-column";

const getColumns = (): OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableColumn[] => {

  const tableColumns: OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "humanId",
      colSpan: 2,
    },
    {
      header: "",
      title: "",
      accessor: "address",
      colSpan: 5,
    },
    {
      header: "",
      title: "",
      accessor: "time",
      colSpan: 3,
    },
    {
      header: "",
      title: "",
      accessor: "haltingTime",
      colSpan: 3,
    },
    {
      header: "",
      title: "",
      accessor: "distance",
      colSpan: 2,
    },
    {
      header: "",
      title: "",
      accessor: "actions",
      colSpan: 1,
    },
  ];

  return tableColumns;
};

const orderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderTableHelper;
