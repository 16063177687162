import cargoRoutesUrls from "./cargo-routes-urls";
import CargoAddressAddRouteParams from "./types/cargo-address-add-route-params";
import CargoAddressEditRouteParams from "./types/cargo-address-edit-route-params";
import CargoAddressListingRouteParams from "./types/cargo-address-listing-route-params";
import CargoDispatchRouteParams from "./types/cargo-dispatch-route-params";
import CargoDispatchUpdateRouteParams from "./types/cargo-dispatch-update-route-params";
import CargoTaxiContractAddRouteParams from "./types/cargo-taxi-contract-add-route-params";
import CargoTaxiContractListingRouteParams from "./types/cargo-taxi-contract-listing-route-params";

const getCompanyAddRoute = (): string => {
  return cargoRoutesUrls.companyAdd;
};

const getCompanyListingRoute = (): string => {
  return cargoRoutesUrls.companyListing;
};

const getCargoTaxiContractListingRoute = (): string => {
  return cargoRoutesUrls.cargoTaxiContractListing;
};

const getAddressAddRoute = (params: CargoAddressAddRouteParams): string => {
  return cargoRoutesUrls.addressAdd.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const getAddressListingRoute = (
  params: CargoAddressListingRouteParams
): string => {
  return cargoRoutesUrls.addressListing.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const getAddressEditRoute = (params: CargoAddressEditRouteParams): string => {
  return cargoRoutesUrls.addressEdit
    .replace(":cargoCompanyUuid", params.cargoCompanyUuid)
    .replace(":addressUuid", params.addressUuid);
};

const getTaxiContractAddRoute = (
  params: CargoTaxiContractAddRouteParams
): string => {
  return cargoRoutesUrls.taxiContractAdd.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const getTaxiContractListingRoute = (
  params: CargoTaxiContractListingRouteParams
): string => {
  return cargoRoutesUrls.taxiContractListing.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const getDispatchListingRoute = (params: CargoDispatchRouteParams): string => {
  return cargoRoutesUrls.dispatchListing.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const getDispatchAddRoute = (params: CargoDispatchRouteParams): string => {
  return cargoRoutesUrls.dispatchAdd.replace(
    ":cargoCompanyUuid",
    params.cargoCompanyUuid
  );
};

const getDispatchEditRoute = (
  params: CargoDispatchUpdateRouteParams
): string => {
  return cargoRoutesUrls.dispatchEdit
    .replace(":cargoCompanyUuid", params.cargoCompanyUuid)
    .replace(":dispatchUuid", params.dispatchUuid);
};

const cargoRoutesHelper = {
  getCompanyAddRoute,
  getCompanyListingRoute,
  getCargoTaxiContractListingRoute,
  getTaxiContractListingRoute,
  getAddressAddRoute,
  getAddressListingRoute,
  getAddressEditRoute,
  getTaxiContractAddRoute,
  getDispatchListingRoute,
  getDispatchAddRoute,
  getDispatchEditRoute,
};

export default cargoRoutesHelper;
