import DriverDownloadRequest, {
  DriverDownloadRequestOrder,
  DriverDownloadRequestStatusCriteria,
  DriverDownloadRequestTimezone,
} from "../../../api/raily/driver/download/driver-download.request";
import UserStatus from "../../../types/user-status";
import DriverDownloadParams, {
  DriverDownloadParamsOrder,
  DriverDownloadParamsTimezone,
} from "./driver-download-params";

const createTimezone = (
  timezone: DriverDownloadParamsTimezone | undefined
): DriverDownloadRequestTimezone | undefined => {
  switch (timezone) {
    case DriverDownloadParamsTimezone.EUROPE_WARSAW:
      return DriverDownloadRequestTimezone.EUROPE_WARSAW;
    case DriverDownloadParamsTimezone.UTC:
      return DriverDownloadRequestTimezone.UTC;
    default:
      return undefined;
  }
};

const createStatusCriteria = (
  status: UserStatus | undefined
): DriverDownloadRequestStatusCriteria => {
  switch (status) {
    case UserStatus.ACTIVE:
      return DriverDownloadRequestStatusCriteria.ACTIVE;
    case UserStatus.DELETED:
      return DriverDownloadRequestStatusCriteria.DELETED;
    default:
      return DriverDownloadRequestStatusCriteria.ALL;
  }
};

const createOrder = (
  status: DriverDownloadParamsOrder | undefined
): DriverDownloadRequestOrder | undefined => {
  switch (status) {
    case DriverDownloadParamsOrder.FIRST_NAME_ASC:
      return DriverDownloadRequestOrder.FIRST_NAME_ASC;
    case DriverDownloadParamsOrder.FIRST_NAME_DESC:
      return DriverDownloadRequestOrder.FIRST_NAME_DESC;
    case DriverDownloadParamsOrder.FLEET_PARTNER_NAME_ASC:
      return DriverDownloadRequestOrder.FLEET_PARTNER_NAME_ASC;
    case DriverDownloadParamsOrder.FLEET_PARTNER_NAME_DESC:
      return DriverDownloadRequestOrder.FLEET_PARTNER_NAME_DESC;
    case DriverDownloadParamsOrder.LAST_NAME_ASC:
      return DriverDownloadRequestOrder.LAST_NAME_ASC;
    case DriverDownloadParamsOrder.LAST_NAME_DESC:
      return DriverDownloadRequestOrder.LAST_NAME_DESC;
    case DriverDownloadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_ASC:
      return DriverDownloadRequestOrder.TAXI_CORPORATION_DISPLAY_NAME_ASC;
    case DriverDownloadParamsOrder.TAXI_CORPORATION_DISPLAY_NAME_DESC:
      return DriverDownloadRequestOrder.TAXI_CORPORATION_DISPLAY_NAME_DESC;
    case DriverDownloadParamsOrder.LAST_PLAN_ENTRY_END_TIME_ASC:
      return DriverDownloadRequestOrder.LAST_PLAN_ENTRY_END_TIME_ASC;
    case DriverDownloadParamsOrder.LAST_PLAN_ENTRY_END_TIME_DESC:
      return DriverDownloadRequestOrder.LAST_PLAN_ENTRY_END_TIME_DESC;
    case DriverDownloadParamsOrder.CREATED_AT_ASC:
      return DriverDownloadRequestOrder.CREATED_AT_ASC;
    case DriverDownloadParamsOrder.CREATED_AT_DESC:
      return DriverDownloadRequestOrder.CREATED_AT_DESC;
    case DriverDownloadParamsOrder.MODIFIED_AT_ASC:
      return DriverDownloadRequestOrder.MODIFIED_AT_ASC;
    case DriverDownloadParamsOrder.MODIFIED_AT_DESC:
      return DriverDownloadRequestOrder.MODIFIED_AT_DESC;
    default:
      return undefined;
  }
};

const create = (params: DriverDownloadParams): DriverDownloadRequest => {
  return {
    address: params.address,
    creationEnd: params.creationEnd,
    creationStart: params.creationStart,
    email: params.email,
    firstName: params.firstName,
    fleetPartner: params.fleetPartner,
    fleetPartnerId: params.fleetPartnerId,
    lastName: params.lastName,
    modificationEnd: params.modificationEnd,
    modificationStart: params.modificationStart,
    order: createOrder(params.order),
    pendingCandidature: params.pendingCandidature,
    statusCriteria: createStatusCriteria(params.statusCriteria),
    taxiCorporation: params.taxiCorporation,
    taxiCorporationId: params.taxiCorporationId,
    username: params.username,
    separator: params.separator,
    timezone: createTimezone(params.timezone),
  };
};

const driverDownloadRequestFactory = {
  create,
};

export default driverDownloadRequestFactory;
