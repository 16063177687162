import { FC } from "react";
import OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrdTableComponent from "./order/order-optimizer-details-hidden-details-center-tables-orders-order-table.component";
import {
  OrderOptimizerDetailsItemDriver,
  OrderOptimizerDetailsItemOrders,
  OrderOptimizerDetailsItemStepsOrders,
  OrderOptimizerDetailsItemStepsOrdersPoints,
} from "../../../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersStepSummaryComponent from "./step-summary/order-optimizer-details-hidden-details-center-tables-orders-step-summary.component";
import OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderSummaryComponent from "./order-summary/order-optimizer-details-hidden-details-center-tables-orders-order-summary.component";

type OrderOptimizerDetailsHiddenDetailsTablesOrdersProps = {
  driver: OrderOptimizerDetailsItemDriver | null;
  allStepPoints: OrderOptimizerDetailsItemStepsOrdersPoints[];
  order: OrderOptimizerDetailsItemOrders;
  step: OrderOptimizerDetailsItemStepsOrders;
  stepOffset: number;
  orderIndex: number;
};

const OrderOptimizerDetailsHiddenDetailsTablesOrdersComponent: FC<
  OrderOptimizerDetailsHiddenDetailsTablesOrdersProps
> = (props) => {
  return (
    <>
      <div className="order_optimizer_details_hidden_details_center_tables_orders">
        <div className="order_optimizer_details_hidden_details_center_tables_orders__left">
          <OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrderSummaryComponent
            order={props.order}
            orderIndex={props.orderIndex}
          />
        </div>
        <div className="order_optimizer_details_hidden_details_center_tables_orders__right">
          <div className="order_optimizer_details_hidden_details_center_tables_orders__right__order">
            <OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersOrdTableComponent
              driver={props.driver}
              allStepPoints={props.allStepPoints}
              stepPoints={props.step.points}
              stepOffset={props.stepOffset}
            />
          </div>
          <div className="order_optimizer_details_hidden_details_center_tables_orders__right__summary">
            <OrderOptimizerDetailsHiddenDetailsCenterTablesOrdersStepSummaryComponent
              driver={props.driver}
              step={props.step}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderOptimizerDetailsHiddenDetailsTablesOrdersComponent;
