const getOrderColor = (index: number): string => {
  const colors = [
    "#4ea015",
    "#7236ffcc",
    "#0c55ba",
    "#00c9b7",
    "#d1a111",
    "#f38722",
  ];

  return colors[index % colors.length];
};

const orderOptimizerHelper = { getOrderColor };

export default orderOptimizerHelper;
