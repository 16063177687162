import {
  OrderOptimizerListItem,
  OrderOptimizerListItemOrders,
  OrderOptimizerListItemRoute,
} from "../../../../common/services/order-optimizer/list/order-optimizer-list";
import {
  OrderOptimizerListingItem,
  OrderOptimizerListingItemOrder,
  OrderOptimizerListingItemRoute,
} from "./types/order-optimizer-listing-item";

const createListingItemOrder = (
  order: OrderOptimizerListItemOrders
): OrderOptimizerListingItemOrder => {
  return {
    cargoCompany: order.cargoCompany,
    humanId: order.humanId,
    startTime: order.startTime,
  };
};

const createLIstingItemOrders = (
  orders: OrderOptimizerListItemOrders[]
): OrderOptimizerListingItemOrder[] => {
  return orders.map(createListingItemOrder);
};

const createListingItemRoute = (
  route: OrderOptimizerListItemRoute
): OrderOptimizerListingItemRoute => {
  return {
    lat: route.lat,
    lon: route.lon,
    orderHumanId: route.orderHumanId,
  };
};

const createLIstingItemRoutes = (
  routes: OrderOptimizerListItemRoute[]
): OrderOptimizerListingItemRoute[] => {
  return routes.map(createListingItemRoute);
};

const createListingItem = (
  listItem: OrderOptimizerListItem
): OrderOptimizerListingItem => {
  return {
    id: listItem.id,
    orders: createLIstingItemOrders(listItem.orders),
    reviewedAt: listItem.reviewedAt,
    reviewedBy: listItem.reviewedBy,
    route: createLIstingItemRoutes(listItem.route),
    score: listItem.score,
  };
};

const createListingItems = (
  listItems: OrderOptimizerListItem[]
): OrderOptimizerListingItem[] => {
  return listItems.map(createListingItem);
};

const orderOptimizerListingItemFactory = {
  createListingItems,
};

export default orderOptimizerListingItemFactory;
