import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import OrderOptimizerListingRouteQueryParams from "../../common/routes/types/order-optimizer-listing-route-params";
import OrderOptimizerListingFilter from "../common/types/order-optimizer-listing-filter";
import orderOptimizerListingFiltersService from "./order-optimizer-listing-filters.service";

const useOrderOptimizerListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<OrderOptimizerListingRouteQueryParams>();

  const getPage = () => {
    return orderOptimizerListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = () => {
    return orderOptimizerListingFiltersService.getPageSize(routeQueryParams);
  };

  const getFilters = (): OrderOptimizerListingFilter[] => {
    return orderOptimizerListingFiltersService.getFilters(routeQueryParams);
  };

  const saveFilters = (
    filters: OrderOptimizerListingFilter[],
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      orderOptimizerListingFiltersService.createRouteQueryParams(
        filters,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getPage,
    getPageSize,
    getFilters,
    saveFilters,
  };
};

export default useOrderOptimizerListingFiltersDao;
