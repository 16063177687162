import StatusLegendData from "../../../../common/components/status-legend/types/status-legend-data";
import StatusLegendStatuses from "../../../../common/components/status-legend/types/status-legend-statuses";
import {
  CargoOrderActiveListItem,
  CargoOrderActiveListItemExecutionStatus,
} from "../../../../common/services/cargo-order/active-list/cargo-order-active-list";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";

const getStatusFromExecutionStatus = (
  executionStatus: CargoOrderActiveListItemExecutionStatus
): StatusLegendStatuses => {
  switch (executionStatus) {
    case CargoOrderActiveListItemExecutionStatus.CANDIDATURE:
      return StatusLegendStatuses.CANDIDATURE;
    case CargoOrderActiveListItemExecutionStatus.IN_PROGRESS:
      return StatusLegendStatuses.IN_PROGRESS;
    case CargoOrderActiveListItemExecutionStatus.PENDING:
      return StatusLegendStatuses.PENDING;
    case CargoOrderActiveListItemExecutionStatus.PLANNED:
      return StatusLegendStatuses.PLANNED;
  }
};

const getStatusOptions = (
  pageDate: CargoOrderActiveListItem[]
): StatusLegendData[] => {
  const statuses: CargoOrderActiveListItemExecutionStatus[] = [
    CargoOrderActiveListItemExecutionStatus.PENDING,
    CargoOrderActiveListItemExecutionStatus.PLANNED,
    CargoOrderActiveListItemExecutionStatus.IN_PROGRESS,
    CargoOrderActiveListItemExecutionStatus.CANDIDATURE,
  ];

  return statuses.map((executionStatus) => {
    return {
      status: getStatusFromExecutionStatus(executionStatus),
      totalCount: pageDate.filter(
        (item) => item.executionStatus == executionStatus
      ).length,
    };
  });
};

const getExecutionStatusOptions = () => {
  const translations =
    orderTranslationsHelper.getActiveListingTranslations().executionStatus;

  return [
    {
      label: translations.pendingLabel,
      status: CargoOrderActiveListItemExecutionStatus.PENDING,
    },
    {
      label: translations.plannedLabel,
      status: CargoOrderActiveListItemExecutionStatus.PLANNED,
    },
    {
      label: translations.inProgressLabel,
      status: CargoOrderActiveListItemExecutionStatus.IN_PROGRESS,
    },
    {
      label: translations.candidatureLabel,
      status: CargoOrderActiveListItemExecutionStatus.CANDIDATURE,
    },
  ];
};

const getExecutionStatusLabel = (
  status: CargoOrderActiveListItemExecutionStatus
): string => {
  const options = getExecutionStatusOptions();

  return options.find((option) => option.status === status)?.label ?? "";
};

const getMobileAppUsingLabel = (isUsing: boolean) => {
  const translations = orderTranslationsHelper.getActiveListingTranslations();

  return isUsing
    ? translations.mobileAppUserTitle
    : translations.noMobileAppUserTitle;
};

const orderActiveListingHelper = {
  getExecutionStatusOptions,
  getExecutionStatusLabel,
  getMobileAppUsingLabel,
  getStatusOptions,
};

export default orderActiveListingHelper;
