import User from "../../../../../common/types/user";
import OrderDetailsPassenger from "../types/order-details-passenger";
import OrderDetailsRouteWaypoint from "../types/order-details-route-waypoint";
import orderDetailsCopyToClipboardLabelBuilder from "./order-details-copy-to-clipboard-label.builder";
import orderDetailsCopyToClipboardRouteWaypointLabelBuilder from "./order-details-copy-to-clipboard-route-waypoint-label.builder";

const getRouteWaypointOnboardingPassengers = (
  waypoint: OrderDetailsRouteWaypoint,
  allOrderPassengers: OrderDetailsPassenger[]
): OrderDetailsPassenger[] => {
  const onboardingPassengers: OrderDetailsPassenger[] = [];

  waypoint.onboardingPassengersUuids.forEach((passengerUuid) => {
    const passenger = allOrderPassengers.find(
      (passenger) => passenger.uuid === passengerUuid
    );

    if (!passenger) {
      return;
    }

    onboardingPassengers.push(passenger);
  });

  return onboardingPassengers;
};

const getRouteWaypointOutboardingPassengers = (
  waypoint: OrderDetailsRouteWaypoint,
  allOrderPassengers: OrderDetailsPassenger[]
): OrderDetailsPassenger[] => {
  const outboardingPassengers: OrderDetailsPassenger[] = [];

  waypoint.outboardingPassengersUuids.forEach((passengerUuid) => {
    const passenger = allOrderPassengers.find(
      (passenger) => passenger.uuid === passengerUuid
    );

    if (!passenger) {
      return;
    }

    outboardingPassengers.push(passenger);
  });

  return outboardingPassengers;
};

const getPassengerLabel = (passenger: OrderDetailsPassenger): string => {
  const routeWaypointLabelTemplate = `#{passengerDisplayName} (Tel: #{passengerMobileNumber})`;

  return routeWaypointLabelTemplate
    .replace(`#{passengerDisplayName}`, passenger.displayName)
    .replace(`#{passengerMobileNumber}`, passenger.phoneNumber);
};

const getEstimatedDateLabel = (estimatedDate: Date | null): string => {
  if (!estimatedDate) {
    return "";
  }

  return `${estimatedDate.toLocaleDateString()} ${estimatedDate.toLocaleTimeString(
    undefined,
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;
};

const getRouteWaypointLabel = (
  waypoint: OrderDetailsRouteWaypoint,
  allOrderPassengers: OrderDetailsPassenger[]
): string => {
  const onboardingPassengers: OrderDetailsPassenger[] =
    getRouteWaypointOnboardingPassengers(waypoint, allOrderPassengers);

  const onboardingPassengersLabels: string[] =
    onboardingPassengers.map(getPassengerLabel);

  const outboardingPassengers: OrderDetailsPassenger[] =
    getRouteWaypointOutboardingPassengers(waypoint, allOrderPassengers);

  const outboardingPassengersLabels: string[] =
    outboardingPassengers.map(getPassengerLabel);

  const estimatedDateLabel = getEstimatedDateLabel(waypoint.estimatedDate);

  return orderDetailsCopyToClipboardRouteWaypointLabelBuilder(
    waypoint.markerPosition,
    waypoint.place.displayName
  )
    .withEstimatedDate(estimatedDateLabel)
    .withOnboardingPassengersLabels(onboardingPassengersLabels)
    .withOutboardingPassengersLabels(outboardingPassengersLabels)
    .build();
};

const getRouteWaypointsLabels = (
  waypoints: OrderDetailsRouteWaypoint[],
  allOrderPassengers: OrderDetailsPassenger[]
): string[] => {
  return waypoints.map((waypoint) =>
    getRouteWaypointLabel(waypoint, allOrderPassengers)
  );
};

const getTextToCopy = (
  user: User,
  cargoCompanyOrderId: number,
  waypoints: OrderDetailsRouteWaypoint[],
  allOrderPassengers: OrderDetailsPassenger[],
  routeNumber: number,
  contactAfterMinutes: number
) => {
  const routeWaypointsLabels: string[] = getRouteWaypointsLabels(
    waypoints,
    allOrderPassengers
  );

  const userDisplayName = `${user.firstName} ${user.lastName}`;

  return orderDetailsCopyToClipboardLabelBuilder()
    .withAuthor(userDisplayName)
    .withOrderId(cargoCompanyOrderId)
    .withRouteWaypoints(routeWaypointsLabels)
    .withRouteId(routeNumber)
    .withContactAfterMinutes(contactAfterMinutes)
    .build();
};

const orderDetailsCopyToClipboardHelper = {
  getTextToCopy,
};

export default orderDetailsCopyToClipboardHelper;
