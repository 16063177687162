import cargoTranslationsHelper from "../../../../../languages/cargo-translations.helper";
import CargoCargoTaxiContractListingTableColumn from "../common/types/cargo-cargo-taxi-contract-listing-table-column";

const getColumns = (): CargoCargoTaxiContractListingTableColumn[] => {
  const translations =
    cargoTranslationsHelper.getCargoTaxiContractListingTranslations().table
      .headers;

  const columns: CargoCargoTaxiContractListingTableColumn[] = [
    {
      header: translations.cargoCompanyLabel,
      title: translations.cargoCompanyTitle,
      accessor: "cargoCompany",
      colSpan: 10,
    },
    {
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      accessor: "taxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.validSinceLabel,
      title: translations.validSinceTitle,
      accessor: "validSince",
      colSpan: 10,
    },
    {
      header: translations.validToLabel,
      title: translations.validToTitle,
      accessor: "validTo",
      colSpan: 10,
    },
    {
      header: translations.modelLabel,
      title: translations.modelTitle,
      accessor: "model",
      colSpan: 10,
    },
    {
      header: translations.allowChargeWhileApproachingLabel,
      title: translations.allowChargeWhileApproachingTitle,
      accessor: "allowChargeWhileApproaching",
      colSpan: 10,
    },
    {
      header: translations.allowChargeWhileReturningLabel,
      title: translations.allowChargeWhileReturningTitle,
      accessor: "allowChargeWhileReturning",
      colSpan: 10,
    },
    {
      header: translations.allowChargeDuringRideLabel,
      title: translations.allowChargeDuringRideTitle,
      accessor: "allowChargeDuringRide",
      colSpan: 10,
    },
    {
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      accessor: "distanceRate",
      colSpan: 10,
    },
    {
      header: translations.discountLimitLabel,
      title: translations.discountLimitTitle,
      accessor: "discountLimit",
      colSpan: 10,
    },
    {
      header: translations.haltingTimeRateLabel,
      title: translations.haltingTimeRateTitle,
      accessor: "haltingTimeRate",
      colSpan: 10,
    },
    {
      header: translations.haltingTimeAfterMinutesLabel,
      title: translations.haltingTimeAfterMinutesTitle,
      accessor: "haltingTimeAfterMinutes",
      colSpan: 10,
    },
    {
      header: translations.publishingAllowedLabel,
      title: translations.publishingAllowedTitle,
      accessor: "publishingAllowed",
      colSpan: 10,
    },
    {
      header: translations.activeLabel,
      title: translations.activeTitle,
      accessor: "active",
      colSpan: 10,
    },
  ];

  return columns;
};

const cargoCargoTaxiContractListingTableHelper = {
  getColumns,
};

export default cargoCargoTaxiContractListingTableHelper;
