import CargoTaxiContractList, {
  CargoTaxiContractListCargoCompany,
  CargoTaxiContractListContractMaintenancePolicy,
  CargoTaxiContractListItem,
  CargoTaxiContractListModel,
  CargoTaxiContractListTaxiCorporation,
  CargoTaxiContractListTaxiCorporationFleetPartners,
} from "../../../../../common/services/contract/cargo-taxi-contract-list/cargo-taxi-contract-list";
import CargoCargoTaxiContractListing, {
  CargoCargoTaxiContractListingCargoCompany,
  CargoCargoTaxiContractListingContractMaintenancePolicy,
  CargoCargoTaxiContractListingItem,
  CargoCargoTaxiContractListingModel,
  CargoCargoTaxiContractListingTaxiCorporation,
  CargoCargoTaxiContractListingTaxiCorporationFleetPartners,
} from "./types/cargo-cargo-taxi-contract-listing-item";

const createFleetPartner = (
  fleetPartner: CargoTaxiContractListTaxiCorporationFleetPartners
): CargoCargoTaxiContractListingTaxiCorporationFleetPartners => {
  return {
    createdAt: fleetPartner.createdAt,
    createdBy: fleetPartner.createdBy,
    id: fleetPartner.id,
    modifiedAt: fleetPartner.modifiedAt,
    modifiedBy: fleetPartner.modifiedBy,
    name: fleetPartner.name,
    taxiCorporationId: fleetPartner.taxiCorporationId,
  };
};

const createFleetPartners = (
  responseFleetPartners: CargoTaxiContractListTaxiCorporationFleetPartners[]
): CargoCargoTaxiContractListingTaxiCorporationFleetPartners[] => {
  return responseFleetPartners.map(createFleetPartner);
};

const createModel = (
  model: CargoTaxiContractListModel
): CargoCargoTaxiContractListingModel => {
  switch (model) {
    case CargoTaxiContractListModel.AB:
      return CargoCargoTaxiContractListingModel.AB;
    case CargoTaxiContractListModel.ABA:
      return CargoCargoTaxiContractListingModel.ABA;
    case CargoTaxiContractListModel.OTHER:
      return CargoCargoTaxiContractListingModel.OTHER;
    case CargoTaxiContractListModel.SABS:
      return CargoCargoTaxiContractListingModel.SABS;
  }
};

const createContractMainteancePolicy = (
  contractMainteancePolicy: CargoTaxiContractListContractMaintenancePolicy
): CargoCargoTaxiContractListingContractMaintenancePolicy => {
  switch (contractMainteancePolicy) {
    case CargoTaxiContractListContractMaintenancePolicy.AUCTION:
      return CargoCargoTaxiContractListingContractMaintenancePolicy.AUCTION;
    case CargoTaxiContractListContractMaintenancePolicy.BASIC:
      return CargoCargoTaxiContractListingContractMaintenancePolicy.BASIC;
  }
};

const createTaxiCorporation = (
  taxiCorporation: CargoTaxiContractListTaxiCorporation
): CargoCargoTaxiContractListingTaxiCorporation => {
  return {
    companyName: taxiCorporation.companyName,
    contractMainteancePolicy: createContractMainteancePolicy(
      taxiCorporation.contractMainteancePolicy
    ),
    createdAt: taxiCorporation.createdAt,
    createdBy: taxiCorporation.createdBy,
    displayName: taxiCorporation.displayName,
    fleetPartners: createFleetPartners(taxiCorporation.fleetPartners),
    headquartersAddressString: taxiCorporation.headquartersAddressString,
    id: taxiCorporation.id,
    taxId: taxiCorporation.taxId,
    krsNo: taxiCorporation.krsNo,
    modifiedAt: taxiCorporation.modifiedAt,
    modifiedBy: taxiCorporation.modifiedBy,
    notes: taxiCorporation.notes,
    regon: taxiCorporation.regon,
  };
};

const createCargoCompany = (
  cargoCompany: CargoTaxiContractListCargoCompany
): CargoCargoTaxiContractListingCargoCompany => {
  return {
    companyName: cargoCompany.companyName,
    contractMainteancePolicy: createContractMainteancePolicy(
      cargoCompany.contractMainteancePolicy
    ),
    createdAt: cargoCompany.createdAt,
    createdBy: cargoCompany.createdBy,
    displayName: cargoCompany.displayName,
    headquartersAddressString: cargoCompany.headquartersAddressString,
    id: cargoCompany.id,
    taxId: cargoCompany.taxId,
    krsNo: cargoCompany.krsNo,
    modifiedAt: cargoCompany.modifiedAt,
    modifiedBy: cargoCompany.modifiedBy,
    notes: cargoCompany.notes,
    regon: cargoCompany.regon,
  };
};

const createDataItem = (
  item: CargoTaxiContractListItem
): CargoCargoTaxiContractListingItem => {
  return {
    allowChargeDuringRide: item.allowChargeDuringRide,
    allowChargeWhileApproaching: item.allowChargeWhileApproaching,
    allowChargeWhileReturning: item.allowChargeWhileReturning,
    cargoCompany: createCargoCompany(item.cargoCompany),
    cargoCompanyId: item.cargoCompanyId,
    createdAt: item.createdAt,
    createdBy: item.createdBy,
    discountLimit: item.discountLimit,
    distanceRate: item.distanceRate,
    haltingTimeAfterMinutes: item.haltingTimeAfterMinutes,
    haltingTimeRate: item.haltingTimeRate,
    id: item.id,
    model: createModel(item.model),
    publishingAllowed: item.publishingAllowed,
    taxiCorporation: createTaxiCorporation(item.taxiCorporation),
    taxiCorporationId: item.taxiCorporationId,
    validSince: item.validSince,
    validTo: item.validTo,
    isValid: item.isValid,
    modifiedAt: item.modifiedAt,
    modifiedBy: item.modifiedBy,
  };
};

const createData = (
  data: CargoTaxiContractListItem[]
): CargoCargoTaxiContractListingItem[] => {
  const items: CargoCargoTaxiContractListingItem[] = data.map(createDataItem);

  return items;
};

const create = (list: CargoTaxiContractList): CargoCargoTaxiContractListing => {
  return {
    data: createData(list.data),
    totalCount: list.totalCount,
  };
};

const cargoCargoTaxiContractListingItemFactory = {
  create,
};

export default cargoCargoTaxiContractListingItemFactory;
