import dateService from "../../../../../common/utils/date/date.service";
import orderOptimizerTranslationsHelper from "../../../../../languages/order-optimizer-translations.helper";

const getDateLabel = (date: Date | null): string => {
  if (!date) {
    return "";
  }

  return dateService.formatDateTime(date);
};

const getScoreLabel = (score: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getListingTranslations().table;

  return `${translations.scoreLabel} ${score.toFixed() ?? "---"}`;
};

const getIdLabel = (id: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getListingTranslations().table;

  return `${translations.idLabel} ${id ?? "---"}`;
};

const orderOptimizerListingTableRowHelper = {
  getDateLabel,
  getScoreLabel,
  getIdLabel,
};

export default orderOptimizerListingTableRowHelper;
