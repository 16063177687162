import ListingFilterDefinition, {
  ListingFilterDefinitionOptionBadgeData,
  ListingFilterDefinitionOptionSelectData,
  ListingFilterDefinitionOptionSelectDataType,
  ListingFilterDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-filter-definition";
import ListingSortDefinition, {
  ListingSortDefinitionOption,
} from "../../../../common/components/listing/filter/types/listing-sort-definition";
import dateService from "../../../../common/utils/date/date.service";
import mileageTranslationsHelper from "../../../../languages/mileage-translations.helper";
import mileageListingHelper from "../common/mileage-listing.helper";
import MileageListingFilter, {
  MileageListingMileageDateFilter,
  MileageListingHumanIdFilter,
  MileageListingMileageNumberFilter,
  MileageListingWorkerNameFilter,
  MileageListingCardNumberFilter,
  MileageListingStartAddressFilter,
  MileageListingEndAddressFilter,
  MileageListingDistanceFilter,
  MileageListingCargoCompanyNameFilter,
  MileageListingVehicleTypeFilter,
  MileageListingStateFilter,
} from "../common/types/mileage-listing-filter";
import MileageListingFilterType from "../common/types/mileage-listing-filter-type";
import MileageListingSortKey from "../common/types/mileage-listing-sort-key";
import MileageListingStatus from "../common/types/mileage-listing-status";
import MileageListingVehicleType from "../common/types/mileage-listing-vehicle-type";

const mileageListingFilterHelper = () => {
  const translations =
    mileageTranslationsHelper.getMileageListingTranslations();

  const createBadgeData = (
    value: string,
    textTemplate: string,
    titleTemplate: string
  ): ListingFilterDefinitionOptionBadgeData => {
    return {
      text: textTemplate.replace("#{query}", value),
      title: titleTemplate.replace("#{query}", value),
    };
  };

  const getSortDefinitionOptions = (): ListingSortDefinitionOption[] => {
    return [
      {
        label: translations.sort.mileageDateAscLabel,
        value: MileageListingSortKey.DATE_ASC,
      },
      {
        label: translations.sort.mileageDateDescLabel,
        value: MileageListingSortKey.DATE_DESC,
      },
      {
        label: translations.sort.humanIdAscLabel,
        value: MileageListingSortKey.HUMAN_ID_ASC,
      },
      {
        label: translations.sort.humanIdDescLabel,
        value: MileageListingSortKey.HUMAN_ID_DESC,
      },
      {
        label: translations.sort.cargoCompanyNameAscLabel,
        value: MileageListingSortKey.CARGO_COMPANY_NAME_ASC,
      },
      {
        label: translations.sort.cargoCompanyNameDescLabel,
        value: MileageListingSortKey.CARGO_COMPANY_NAME_DESC,
      },
      {
        label: translations.sort.workerNameAscLabel,
        value: MileageListingSortKey.WORKER_NAME_ASC,
      },
      {
        label: translations.sort.workerNameDescLabel,
        value: MileageListingSortKey.WORKER_NAME_DESC,
      },
      {
        label: translations.sort.cardNoAscLabel,
        value: MileageListingSortKey.CARD_NO_ASC,
      },
      {
        label: translations.sort.cardNoDescLabel,
        value: MileageListingSortKey.CARD_NO_DESC,
      },
      {
        label: translations.sort.distanceAscLabel,
        value: MileageListingSortKey.DISTANCE_ASC,
      },
      {
        label: translations.sort.distanceDescLabel,
        value: MileageListingSortKey.DISTANCE_DESC,
      },
    ];
  };

  const getSelectDataForMileageDate =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DATE_RANGE,
        option: {
          label: translations.filters.search.mileageDateLabel,
        },
      };
    };

  const getSelectDataForHumanId = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_QUERY,
      options: [
        {
          label: translations.filters.search.humanIdTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: Number.parseInt(query),
        },
      ],
    };
  };

  const getSelectDataForCargoCompanyName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label:
            translations.filters.search.cargoCompanyNameTemplateLabel.replace(
              "#{query}",
              query
            ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForMileageNumber = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.mileageNumberTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForWorkerName = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.workerNameTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForMileageCardNumber = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.cardNumberTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForStartAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.addressStartTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForEndAddress = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.QUERY,
      options: [
        {
          label: translations.filters.search.addressEndTemplateLabel.replace(
            "#{query}",
            query
          ),
          value: query,
        },
      ],
    };
  };

  const getSelectDataForVehicleType =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.vehicleTypeLabel.replace(
              "#{query}",
              translations.type.carGT900CM3
            ),
            value: MileageListingVehicleType.CAR_GT_900CM3,
          },
          {
            label: translations.filters.search.vehicleTypeLabel.replace(
              "#{query}",
              translations.type.carLE900CM3
            ),
            value: MileageListingVehicleType.CAR_LE_900CM3,
          },
          {
            label: translations.filters.search.vehicleTypeLabel.replace(
              "#{query}",
              translations.type.companyOwned
            ),
            value: MileageListingVehicleType.COMPANY_OWNED,
          },
          {
            label: translations.filters.search.vehicleTypeLabel.replace(
              "#{query}",
              translations.type.moped
            ),
            value: MileageListingVehicleType.MOPED,
          },
          {
            label: translations.filters.search.vehicleTypeLabel.replace(
              "#{query}",
              translations.type.motorcycle
            ),
            value: MileageListingVehicleType.MOTORCYCLE,
          },
        ],
      };
    };

    const getSelectDataForState =
    (): ListingFilterDefinitionOptionSelectData => {
      return {
        type: ListingFilterDefinitionOptionSelectDataType.DEFAULT,
        options: [
          {
            label: translations.filters.search.stateTemplateLabel.replace(
              "#{query}",
              translations.state.pendingLabel
            ),
            value: MileageListingStatus.PENDING,
          },
          {
            label: translations.filters.search.stateTemplateLabel.replace(
              "#{query}",
              translations.state.acceptedLabel
            ),
            value: MileageListingStatus.ACCEPTED,
          },
          {
            label: translations.filters.search.stateTemplateLabel.replace(
              "#{query}",
              translations.state.rejectedLabel
            ),
            value: MileageListingStatus.REJECTED,
          },
          {
            label: translations.filters.search.stateTemplateLabel.replace(
              "#{query}",
              translations.state.delegationCreatedLabel
            ),
            value: MileageListingStatus.DELEGATION_CREATED,
          },
        ],
      };
    };

  const getSelectDataForDistance = (
    query: string
  ): ListingFilterDefinitionOptionSelectData => {
    return {
      type: ListingFilterDefinitionOptionSelectDataType.NUMERIC_RANGE,
      option: {
        label: translations.filters.search.distanceLabel,
      },
    };
  };

  const getSelectDataByType = (
    filterType: MileageListingFilterType,
    query: string
  ) => {
    const options: Record<
      MileageListingFilterType,
      (query: string) => ListingFilterDefinitionOptionSelectData
    > = {
      [MileageListingFilterType.MILEAGE_DATE]: getSelectDataForMileageDate,
      [MileageListingFilterType.HUMAN_ID]: getSelectDataForHumanId,
      [MileageListingFilterType.CARGO_COMPANY_NAME]:
        getSelectDataForCargoCompanyName,
      [MileageListingFilterType.MILEAGE_NUMBER]: getSelectDataForMileageNumber,
      [MileageListingFilterType.WORKER_NAME]: getSelectDataForWorkerName,
      [MileageListingFilterType.CARD_NUMBER]: getSelectDataForMileageCardNumber,
      [MileageListingFilterType.START_ADDRESS]: getSelectDataForStartAddress,
      [MileageListingFilterType.END_ADDRESS]: getSelectDataForEndAddress,
      [MileageListingFilterType.VEHICLE_TYPE]: getSelectDataForVehicleType,
      [MileageListingFilterType.STATE]: getSelectDataForState,
      [MileageListingFilterType.DISTANCE]: getSelectDataForDistance,
    };

    const result = options[filterType](query);

    return result;
  };

  const getBadgeForMileageDate = (
    filterValue: MileageListingMileageDateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${dateService.formatDate(filterValue.from)}-${dateService.formatDate(
        filterValue.to
      )}`,
      translations.filters.mileageDateLabel,
      translations.filters.mileageDateTitle
    );
  };

  const getBadgeForCargoCompanyName = (
    filterValue: MileageListingCargoCompanyNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.cargoCompanyNameLabel,
      translations.filters.cargoCompanyNameTitle
    );
  };

  const getBadgeForHumanId = (
    filterValue: MileageListingHumanIdFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      String(filterValue),
      translations.filters.humanIdLabel,
      translations.filters.humanIdTitle
    );
  };

  const getBadgeForMileageNumber = (
    filterValue: MileageListingMileageNumberFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.mileageNumberLabel,
      translations.filters.mileageNumberTitle
    );
  };

  const getBadgeForWorkerName = (
    filterValue: MileageListingWorkerNameFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.workerNameLabel,
      translations.filters.workerNameTitle
    );
  };

  const getBadgeForDelegationType = (
    filterValue: MileageListingVehicleTypeFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      mileageListingHelper.getVehicleTypeLabel(filterValue),
      translations.filters.vehicleTypeLabel,
      translations.filters.vehicleTypeTitle
    );
  };

  const getBadgeForState = (
    filterValue: MileageListingStateFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      mileageListingHelper.getStateLabel(filterValue),
      translations.filters.stateLabel,
      translations.filters.stateTitle
    );
  };

  const getBadgeForMileageCardNumber = (
    filterValue: MileageListingCardNumberFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.cardNumberLabel,
      translations.filters.cardNumberTitle
    );
  };

  const getBadgeForStartAddress = (
    filterValue: MileageListingStartAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.addressStartLabel,
      translations.filters.addressStartTitle
    );
  };

  const getBadgeForEndAddress = (
    filterValue: MileageListingEndAddressFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      filterValue,
      translations.filters.addressEndLabel,
      translations.filters.addressEndTitle
    );
  };

  const getBadgeForDistance = (
    filterValue: MileageListingDistanceFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    return createBadgeData(
      `${filterValue.from}-${filterValue.to}`,
      translations.filters.distanceLabel,
      translations.filters.distanceTitle
    );
  };

  const getBadgeDefinitionByType = (
    filterType: MileageListingFilterType,
    filterValue: MileageListingFilter["value"]
  ): ListingFilterDefinitionOptionBadgeData => {
    const options: Record<
      MileageListingFilterType,
      () => ListingFilterDefinitionOptionBadgeData
    > = {
      [MileageListingFilterType.MILEAGE_DATE]: () =>
        getBadgeForMileageDate(
          filterValue as MileageListingMileageDateFilter["value"]
        ),
      [MileageListingFilterType.HUMAN_ID]: () =>
        getBadgeForHumanId(filterValue as MileageListingHumanIdFilter["value"]),
      [MileageListingFilterType.CARGO_COMPANY_NAME]: () =>
        getBadgeForCargoCompanyName(
          filterValue as MileageListingCargoCompanyNameFilter["value"]
        ),
      [MileageListingFilterType.MILEAGE_NUMBER]: () =>
        getBadgeForMileageNumber(
          filterValue as MileageListingMileageNumberFilter["value"]
        ),
      [MileageListingFilterType.WORKER_NAME]: () =>
        getBadgeForWorkerName(
          filterValue as MileageListingWorkerNameFilter["value"]
        ),
      [MileageListingFilterType.CARD_NUMBER]: () =>
        getBadgeForMileageCardNumber(
          filterValue as MileageListingCardNumberFilter["value"]
        ),
      [MileageListingFilterType.START_ADDRESS]: () =>
        getBadgeForStartAddress(
          filterValue as MileageListingStartAddressFilter["value"]
        ),
      [MileageListingFilterType.END_ADDRESS]: () =>
        getBadgeForEndAddress(
          filterValue as MileageListingEndAddressFilter["value"]
        ),
      [MileageListingFilterType.DISTANCE]: () =>
        getBadgeForDistance(
          filterValue as MileageListingDistanceFilter["value"]
        ),
      [MileageListingFilterType.VEHICLE_TYPE]: () =>
        getBadgeForDelegationType(
          filterValue as MileageListingVehicleTypeFilter["value"]
        ),
      [MileageListingFilterType.STATE]: () =>
        getBadgeForState(
          filterValue as MileageListingStateFilter["value"]
        ),
    };

    return options[filterType]();
  };

  const getFilterDefinitionByType = (
    filterType: MileageListingFilterType
  ): ListingFilterDefinitionOption<MileageListingFilter> => {
    return {
      getSelectData: (query: string) => getSelectDataByType(filterType, query),
      getBadgeData: (value) => getBadgeDefinitionByType(filterType, value),
      filterType,
    };
  };

  const getFilterDefinitionOptions =
    (): ListingFilterDefinitionOption<MileageListingFilter>[] => {
      return Object.values(MileageListingFilterType).map(
        getFilterDefinitionByType
      );
    };

  const getFilterDefinition =
    (): ListingFilterDefinition<MileageListingFilter> => {
      return {
        options: getFilterDefinitionOptions(),
      };
    };

  const getSortDefinition = (): ListingSortDefinition => {
    return {
      options: getSortDefinitionOptions(),
    };
  };

  return {
    getFilterDefinition,
    getSortDefinition,
  };
};

export default mileageListingFilterHelper;
