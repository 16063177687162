import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import { CargoCargoTaxiContractListingRouteQueryParams } from "../../../common/routes/types/cargo-cargo-taxi-contract-listing-route-params";
import CargoCargoTaxiContractListingFilter from "../common/types/cargo-cargo-taxi-contract-listing-filter";
import CargoCargoTaxiContractListingSortKey from "../common/types/cargo-cargo-taxi-contract-listing-sort-key";
import cargoCargoTaxiListingFiltersService from "./cargo-cargo-taxi-contract-listing-filter.service";

const useCargoCargoTaxiContractListingFiltersDao = () => {
  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<CargoCargoTaxiContractListingRouteQueryParams>();

  const getFilters = (): CargoCargoTaxiContractListingFilter[] => {
    return cargoCargoTaxiListingFiltersService.getFilters(routeQueryParams);
  };

  const getPage = (): number | undefined => {
    return cargoCargoTaxiListingFiltersService.getPage(routeQueryParams);
  };

  const getPageSize = (): number | undefined => {
    return cargoCargoTaxiListingFiltersService.getPageSize(routeQueryParams);
  };

  const getSortKey = (): CargoCargoTaxiContractListingSortKey | undefined => {
    return cargoCargoTaxiListingFiltersService.getSortKey(routeQueryParams);
  };

  const saveFilters = (
    filters: CargoCargoTaxiContractListingFilter[],
    sortKey: CargoCargoTaxiContractListingSortKey,
    page: number,
    pageSize: number
  ): void => {
    const routeQueryParams =
      cargoCargoTaxiListingFiltersService.createRouteQueryParams(
        filters,
        sortKey,
        page,
        pageSize
      );

    setRouteQueryParams(routeQueryParams);
  };

  return {
    getFilters,
    getPage,
    getPageSize,
    getSortKey,
    saveFilters,
  };
};

export default useCargoCargoTaxiContractListingFiltersDao;
