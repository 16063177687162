export enum CargoCargoTaxiContractListingContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export enum CargoCargoTaxiContractListingModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type CargoCargoTaxiContractListingTaxiCorporationFleetPartners = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxiCorporationId: string;
  name: string;
};

export type CargoCargoTaxiContractListingCargoCompany = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxId: string;
  regon?: string | null;
  krsNo?: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMainteancePolicy: CargoCargoTaxiContractListingContractMaintenancePolicy;
  notes?: string | null;
};

export type CargoCargoTaxiContractListingTaxiCorporation = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxId: string;
  regon?: string | null;
  krsNo?: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMainteancePolicy: CargoCargoTaxiContractListingContractMaintenancePolicy;
  notes?: string | null;
  fleetPartners: CargoCargoTaxiContractListingTaxiCorporationFleetPartners[];
};

export type CargoCargoTaxiContractListingItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt?: Date | null;
  modifiedBy?: string | null;
  taxiCorporation: CargoCargoTaxiContractListingTaxiCorporation;
  cargoCompany: CargoCargoTaxiContractListingCargoCompany;
  publishingAllowed: boolean;
  cargoCompanyId: string;
  taxiCorporationId: string;
  model: CargoCargoTaxiContractListingModel;
  validSince: Date;
  validTo: Date;
  isValid: boolean;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  allowChargeWhileApproaching: boolean;
  allowChargeWhileReturning: boolean;
  allowChargeDuringRide: boolean;
  discountLimit: number;
};

type CargoCargoTaxiContractListing = {
  data: CargoCargoTaxiContractListingItem[];
  totalCount: number;
};

export default CargoCargoTaxiContractListing;
