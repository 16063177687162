import MileageListRequest, {
  MileageListQueryParams,
} from "./mileage-list.request";

const createQueryParams = (
  data: MileageListRequest
): MileageListQueryParams => {
  return {
    address: data.address,
    cardNumber: data.cardNumber,
    companyId: data.companyId,
    dateSince: data.dateSince,
    dateTo: data.dateTo,
    cargoCompanyName: data.cargoCompanyName,
    distanceMax: data.distanceMax,
    distanceMin: data.distanceMin,
    endAddress: data.endAddress,
    humanId: data.humanId,
    midAddress: data.midAddress,
    mileageNumber: data.mileageNumber,
    startAddress: data.startAddress,
    workerName: data.workerName,
    limit: data.limit,
    offset: data.offset,
    order: data.order,
    vehicleType: data.vehicleType,
    state: data.state,
  };
};

const mileageListRequstFactory = {
  createQueryParams,
};

export default mileageListRequstFactory;
