export enum DelegationListResultsOrder {
  DELEGATION_NUMBER_ASC = "DELEGATION_NUMBER_ASC",
  DELEGATION_NUMBER_DESC = "DELEGATION_NUMBER_DESC",
  CARGO_COMPANY_NAME_ASC = "CARGO_COMPANY_NAME_ASC",
  CARGO_COMPANY_NAME_DESC = "CARGO_COMPANY_NAME_DESC",
  WORKER_NAME_ASC = "WORKER_NAME_ASC",
  WORKER_NAME_DESC = "WORKER_NAME_DESC",
  COST_ASC = "COST_ASC",
  COST_DESC = "COST_DESC",
  TOTAL_DISTANCE_ASC = "TOTAL_DISTANCE_ASC",
  TOTAL_DISTANCE_DESC = "TOTAL_DISTANCE_DESC",
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
  CREATED_DATE_ASC = "CREATED_DATE_ASC",
  CREATED_DATE_DESC = "CREATED_DATE_DESC",
  FIRST_CSV_DOWNLOAD_ASC = "FIRST_CSV_DOWNLOAD_ASC",
  FIRST_CSV_DOWNLOAD_DESC = "FIRST_CSV_DOWNLOAD_DESC",
  FIRST_PDF_DOWNLOAD_ASC = "FIRST_PDF_DOWNLOAD_ASC",
  FIRST_PDF_DOWNLOAD_DESC = "FIRST_PDF_DOWNLOAD_DESC",
}

export enum DelegationListRequestDelegationType {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

export enum DelegationListRequestState {
  NEW = "NEW",
  DOWNLOADED = "DOWNLOADED",
  DELETED = "DELETED",
}

export type DelegationListQueryParams = {
  offset: number | undefined;
  limit: number | undefined;
  order: DelegationListResultsOrder | undefined;
  delegationNumber: string | undefined;
  cargoCompanyName: string | undefined;
  type: DelegationListRequestDelegationType | undefined;
  workerName: string | undefined;
  startDateSince: Date | undefined;
  startDateTo: Date | undefined;
  endDateSince: Date | undefined;
  endDateTo: Date | undefined;
  createdDateSince: Date | undefined;
  createdDateTo: Date | undefined;
  firstCsvDownloadDateSince: Date | undefined;
  firstCsvDownloadDateTo: Date | undefined;
  firstPdfDownloadDateSince: Date | undefined;
  firstPdfDownloadDateTo: Date | undefined;
  costMin: number | undefined;
  costMax: number | undefined;
  distanceMin: number | undefined;
  distanceMax: number | undefined;
  companyId: string | undefined;
  state: DelegationListRequestState | undefined;
};

type DelegationListRequest = {
  offset?: number;
  limit?: number;
  order?: DelegationListResultsOrder;
  companyUuid?: string;
  cargoCompanyName?: string;
  delegationNumber?: string;
  type?: DelegationListRequestDelegationType;
  workerName?: string;
  startDateSince?: Date;
  startDateTo?: Date;
  endDateSince?: Date;
  endDateTo?: Date;
  creationDateSince?: Date;
  creationDateTo?: Date;
  firstCsvDownloadDateSince?: Date;
  firstCsvDownloadDateTo?: Date;
  firstPdfDownloadDateSince?: Date;
  firstPdfDownloadDateTo?: Date;
  costMin?: number;
  costMax?: number;
  totalDistanceMin?: number;
  totalDistanceMax?: number;
  state?: DelegationListRequestState;
};

export default DelegationListRequest;
