import CargoTaxiContractAddRequest, {
  CargoTaxiContractAddRequestBody,
} from "./cargo-taxi-contract-add.request";

const createBody = (
  request: CargoTaxiContractAddRequest
): CargoTaxiContractAddRequestBody => {
  return {
    allowChargeDuringRide: request.allowChargeDuringRide,
    allowChargeWhileApproaching: request.allowChargeWhileApproaching,
    allowChargeWhileReturning: request.allowChargeWhileReturning,
    cargoCompanyId: request.cargoCompanyId,
    disabled: request.disabled,
    discountLimit: request.discountLimit,
    distanceRate: request.distanceRate,
    haltingTimeAfterMinutes: request.haltingTimeAfterMinutes,
    haltingTimeRate: request.haltingTimeRate,
    model: request.model,
    publishingAllowed: request.publishingAllowed,
    taxiCorporationId: request.taxiCorporationId,
    validSince: request.validSince,
    validTo: request.validTo,
    contactPassengerAfterMinutes: request.contactPassengerAfterMinutes,
  };
};

const cargoTaxiContractAddRequestFactory = {
  createBody,
};

export default cargoTaxiContractAddRequestFactory;
