export enum CargoTaxiContractListOrderRequest {
  VALID_SINCE_ASC = "VALID_SINCE_ASC",
  VALID_SINCE_DESC = "VALID_SINCE_DESC",
  VALID_TO_ASC = "VALID_TO_ASC",
  VALID_TO_DESC = "VALID_TO_DESC",
  DISTANCE_RATE_ASC = "DISTANCE_RATE_ASC",
  DISTANCE_RATE_DESC = "DISTANCE_RATE_DESC",
}

export type CargoTaxiContractListRequestQueryParams = {
  active: boolean | undefined;
  order: CargoTaxiContractListOrderRequest | undefined;
  offset: number | undefined;
  limit: number | undefined;
  cargoCompanyName: string | undefined;
  taxiCorporationName: string | undefined;
};

type CargoTaxiContractListRequest = {
  active?: boolean;
  order?: CargoTaxiContractListOrderRequest;
  offset?: number;
  limit?: number;
  cargoCompanyName?: string;
  taxiCorporationName?: string;
};

export default CargoTaxiContractListRequest;
