import MileageListingFilter, {
  MileageListingMileageDateFilter,
  MileageListingHumanIdFilter,
  MileageListingMileageNumberFilter,
  MileageListingWorkerNameFilter,
  MileageListingCardNumberFilter,
  MileageListingStartAddressFilter,
  MileageListingEndAddressFilter,
  MileageListingDistanceFilter,
  MileageListingCargoCompanyNameFilter,
  MileageListingVehicleTypeFilter,
  MileageListingStateFilter,
} from "../common/types/mileage-listing-filter";
import MileageListingRouteQueryParams from "../common/types/mileage-listing-filter-route-params";
import MileageListingFilterType from "../common/types/mileage-listing-filter-type";
import MileageListingSortKey from "../common/types/mileage-listing-sort-key";
import MileageListingStatus from "../common/types/mileage-listing-status";
import MileageListingVehicleType from "../common/types/mileage-listing-vehicle-type";

const getSortKey = (
  routeQueryParams: MileageListingRouteQueryParams
): MileageListingSortKey | undefined => {
  return routeQueryParams.sort as MileageListingSortKey | undefined;
};

const getPage = (
  routeQueryParams: MileageListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: MileageListingRouteQueryParams
): number | undefined => {
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const getFilters = (
  routeQueryParams: MileageListingRouteQueryParams
): MileageListingFilter[] => {
  const filters: MileageListingFilter[] = [];

  if (routeQueryParams.dateSince && routeQueryParams.dateTo) {
    const dateFilter: MileageListingMileageDateFilter = {
      type: MileageListingFilterType.MILEAGE_DATE,
      value: {
        from: new Date(routeQueryParams.dateSince),
        to: new Date(routeQueryParams.dateTo),
      },
    };

    filters.push(dateFilter);
  }

  if (routeQueryParams.cargoCompanyName) {
    const cargoCompanyNameFilter: MileageListingCargoCompanyNameFilter = {
      type: MileageListingFilterType.CARGO_COMPANY_NAME,
      value: routeQueryParams.cargoCompanyName,
    };

    filters.push(cargoCompanyNameFilter);
  }

  if (routeQueryParams.humanId) {
    const humanIdFilter: MileageListingHumanIdFilter = {
      type: MileageListingFilterType.HUMAN_ID,
      value: Number.parseInt(routeQueryParams.humanId),
    };

    filters.push(humanIdFilter);
  }

  if (routeQueryParams.mileageNumber) {
    const mileageNumberFilter: MileageListingMileageNumberFilter = {
      type: MileageListingFilterType.MILEAGE_NUMBER,
      value: routeQueryParams.mileageNumber,
    };

    filters.push(mileageNumberFilter);
  }

  if (routeQueryParams.workerName) {
    const workerNameFilter: MileageListingWorkerNameFilter = {
      type: MileageListingFilterType.WORKER_NAME,
      value: routeQueryParams.workerName,
    };

    filters.push(workerNameFilter);
  }

  if (routeQueryParams.cardNumber) {
    const cardNumberFilter: MileageListingCardNumberFilter = {
      type: MileageListingFilterType.CARD_NUMBER,
      value: routeQueryParams.cardNumber,
    };

    filters.push(cardNumberFilter);
  }

  if (routeQueryParams.startAddress) {
    const addressStartFilter: MileageListingStartAddressFilter = {
      type: MileageListingFilterType.START_ADDRESS,
      value: routeQueryParams.startAddress,
    };

    filters.push(addressStartFilter);
  }

  if (routeQueryParams.endAddress) {
    const addressEndFilter: MileageListingEndAddressFilter = {
      type: MileageListingFilterType.END_ADDRESS,
      value: routeQueryParams.endAddress,
    };

    filters.push(addressEndFilter);
  }

  if (routeQueryParams.vehicleType) {
    let vehicleTypeValue: MileageListingVehicleType | undefined;

    switch (routeQueryParams.vehicleType) {
      case "COMPANY_OWNED":
        vehicleTypeValue = MileageListingVehicleType.COMPANY_OWNED;
        break;
      case "CAR_GT_900CM3":
        vehicleTypeValue = MileageListingVehicleType.CAR_GT_900CM3;
        break;
      case "CAR_LE_900CM3":
        vehicleTypeValue = MileageListingVehicleType.CAR_LE_900CM3;
        break;
      case "MOTORCYCLE":
        vehicleTypeValue = MileageListingVehicleType.MOTORCYCLE;
        break;
      case "MOPED":
        vehicleTypeValue = MileageListingVehicleType.MOPED;
        break;
    }

    if (vehicleTypeValue) {
      const typeFilter: MileageListingVehicleTypeFilter = {
        type: MileageListingFilterType.VEHICLE_TYPE,
        value: vehicleTypeValue!,
      };

      filters.push(typeFilter);
    }
  }

  if (routeQueryParams.state) {
    let stateValue: MileageListingStatus | undefined;

    switch (routeQueryParams.state) {
      case "PENDING":
        stateValue = MileageListingStatus.PENDING;
        break;
      case "ACCEPTED":
        stateValue = MileageListingStatus.ACCEPTED;
        break;
      case "REJECTED":
        stateValue = MileageListingStatus.REJECTED;
        break;
      case "DELEGATION_CREATED":
        stateValue = MileageListingStatus.DELEGATION_CREATED;
        break;
    }

    if (stateValue) {
      const typeFilter: MileageListingStateFilter = {
        type: MileageListingFilterType.STATE,
        value: stateValue!,
      };

      filters.push(typeFilter);
    }
  }

  if (routeQueryParams.distanceMax && routeQueryParams.distanceMin) {
    const distanceFilter: MileageListingDistanceFilter = {
      type: MileageListingFilterType.DISTANCE,
      value: {
        from: Number(routeQueryParams.distanceMin),
        to: Number(routeQueryParams.distanceMax),
      },
    };

    filters.push(distanceFilter);
  }

  return filters;
};

const createRouteQueryParams = (
  filters: MileageListingFilter[],
  sort: MileageListingSortKey,
  page: number,
  pageSize: number
): MileageListingRouteQueryParams => {
  const dateFilterValue = filters.find(
    (filter) => filter.type == MileageListingFilterType.MILEAGE_DATE
  )?.value as MileageListingMileageDateFilter["value"] | undefined;

  const humanIdFilter = filters.find(
    (filter) => filter.type == MileageListingFilterType.HUMAN_ID
  )?.value as MileageListingHumanIdFilter["value"] | undefined;

  const distanceFilter = filters.find(
    (filter) => filter.type == MileageListingFilterType.DISTANCE
  )?.value as MileageListingDistanceFilter["value"] | undefined;

  const routeQueryParams: MileageListingRouteQueryParams = {
    page: String(page),
    pageSize: String(pageSize),
    sort: String(sort),
    dateSince: dateFilterValue?.from.toJSON(),
    dateTo: dateFilterValue?.to.toJSON(),
    humanId: humanIdFilter ? String(humanIdFilter) : undefined,
    mileageNumber: filters.find(
      (filter) => filter.type == MileageListingFilterType.MILEAGE_NUMBER
    )?.value as MileageListingMileageNumberFilter["value"] | undefined,
    cargoCompanyName: filters.find(
      (filter) => filter.type == MileageListingFilterType.CARGO_COMPANY_NAME
    )?.value as MileageListingCargoCompanyNameFilter["value"] | undefined,
    workerName: filters.find(
      (filter) => filter.type == MileageListingFilterType.WORKER_NAME
    )?.value as MileageListingWorkerNameFilter["value"] | undefined,
    cardNumber: filters.find(
      (filter) => filter.type == MileageListingFilterType.CARD_NUMBER
    )?.value as MileageListingCardNumberFilter["value"] | undefined,
    startAddress: filters.find(
      (filter) => filter.type == MileageListingFilterType.START_ADDRESS
    )?.value as MileageListingStartAddressFilter["value"] | undefined,
    endAddress: filters.find(
      (filter) => filter.type == MileageListingFilterType.END_ADDRESS
    )?.value as MileageListingEndAddressFilter["value"] | undefined,
    vehicleType: filters.find(
      (filter) => filter.type == MileageListingFilterType.VEHICLE_TYPE
    )?.value as MileageListingVehicleTypeFilter["value"] | undefined,
    state: filters.find(
      (filter) => filter.type == MileageListingFilterType.STATE
    )?.value as MileageListingStateFilter["value"] | undefined,
    distanceMax: distanceFilter ? String(distanceFilter?.from) : undefined,
    distanceMin: distanceFilter ? String(distanceFilter?.to) : undefined,
  };

  return routeQueryParams;
};

const mileageListingFiltersService = {
  getSortKey,
  getPage,
  getPageSize,
  getFilters,
  createRouteQueryParams,
};

export default mileageListingFiltersService;
