import OrderOptimizerOptimizationStatusRequest from "../../../api/raily/order-optimizer/optimization-status/order-optimizer-optimization-status.request";
import OrderOptimizerOptimizationStatusLoadParams from "./order-optimizer-optimization-status-load-params";

const create = (
  loadParams: OrderOptimizerOptimizationStatusLoadParams
): OrderOptimizerOptimizationStatusRequest => {
  return {
    optimizationId: loadParams.optimizationId,
  };
};

const orderOptimizerOptimizationStatusRequestFactory = {
  create,
};

export default orderOptimizerOptimizationStatusRequestFactory;
