import OrderOptimizerPotentialDriversListRequest from "../../../api/raily/order-optimizer/potential-drivers-list/order-optimizer-potential-drivers-list.request";
import OrderOptimizerPotentialDriversListLoadParams from "./order-optimizer-potential-drivers-list-load-params";

const create = (
  params: OrderOptimizerPotentialDriversListLoadParams
): OrderOptimizerPotentialDriversListRequest => {
  return {
    optimizationId: params.optimizationId,
    name: params.name,
  };
};

const orderOptimizerPotentialDriversListRequestFactory = {
  create,
};

export default orderOptimizerPotentialDriversListRequestFactory;
