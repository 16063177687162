import { FC, useEffect, useMemo, useState } from "react";
import {
  OrderOptimizerListingItem,
  OrderOptimizerListingItemRoute,
} from "../../common/types/order-optimizer-listing-item";
import MapRoute from "../../../../../common/components/map/types/map-route";
import useOrderOptimizerRoadRoute from "../../../../../common/services/order-optimizer/road-route/use-mileage-road-route";
import MapMarker from "../../../../../common/components/map/types/map-marker";
import orderOptimizerListingMapMarkersFactory from "../../common/order-optimizer-listing-map-markers.factory";
import orderOptimizerListingWaypointFactory from "../../common/order-optimizer-listing-waypoint.factory";
import orderOptimizerListingRouteFactory from "../../common/order-optimizer-listing-route.factory";
import MapComponent from "../../../../../common/components/map/map.component";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../../common/utils/search-road-route/search-road-routing.response";
import OrderOptimizerRoadRouteLoadParams from "../../../../../common/services/order-optimizer/road-route/order-optimizer-road-route-load-params";
import orderOptimizerTranslationsHelper from "../../../../../languages/order-optimizer-translations.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faWrench } from "@fortawesome/free-solid-svg-icons";
import orderOptimizerListingTableRowHelper from "./order-optimizer-listing-table-row.helper";
import LinkButtonComponent from "../../../../../common/components/button/link/link-button.component";
import orderOptimizerRoutesHelper from "../../../common/routes/order-optimizer-routes.helper";
import ButtonComponent from "../../../../../common/components/button/button.component";
import orderOptimizerHelper from "../../../common/order-optimizer.helper";

type OrderOptimizerListingTableRowProps = {
  listingItem: OrderOptimizerListingItem;
  selectedHumanId: number | undefined;
  onOptimizationRequestClick: () => void;
};

const OrderOptimizerListingTableRowComponent: FC<
  OrderOptimizerListingTableRowProps
> = (props) => {
  const [waypoints, setWaypoints] = useState<OrderOptimizerListingItemRoute[]>(
    []
  );

  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);

  const roadRoute = useOrderOptimizerRoadRoute();

  const mapMarkers: MapMarker[] = useMemo(() => {
    const mapMarkers =
      orderOptimizerListingMapMarkersFactory.createMapMarkers(waypoints);

    return mapMarkers;
  }, [waypoints]);

  const translations =
    orderOptimizerTranslationsHelper.getListingTranslations().table;

  useEffect(() => {
    if (!props.listingItem) {
      return;
    }

    const preparedWaypoints =
      orderOptimizerListingWaypointFactory.createWaypoints(props.listingItem);

    setWaypoints(preparedWaypoints.flat());

    const loadParams: OrderOptimizerRoadRouteLoadParams[] = [];

    preparedWaypoints.forEach((preparedWaypointsRoute) => {
      if (preparedWaypointsRoute.length > 1) {
        const request =
          orderOptimizerListingWaypointFactory.createSearchRoutingRequest(
            preparedWaypointsRoute
          );
        loadParams.push(request);
      }
    });

    roadRoute.load(loadParams);
  }, [props.listingItem]);

  useEffect(() => {
    if (!roadRoute.data) {
      return;
    }

    const routes = roadRoute.data.map((route, index) => {
      const formattedCoordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[] =
        route.coordinates;

      return orderOptimizerListingRouteFactory.createMapRoute(
        formattedCoordinates,
        orderOptimizerHelper.getOrderColor(index)
      );
    });

    setMapRoutes(routes);
  }, [roadRoute.data]);

  const scoreLabel = orderOptimizerListingTableRowHelper.getScoreLabel(
    props.listingItem.score
  );

  const idLabel = orderOptimizerListingTableRowHelper.getIdLabel(
    Number(props.listingItem.id)
  );

  return (
    <div className="order_optimizer_table_row">
      <div className="order_optimizer_table_row__left">
        <MapComponent markers={mapMarkers} autoFit routes={mapRoutes} />
      </div>
      <div className="order_optimizer_table_row__right">
        <div className="order_optimizer_table_row__right__upper_section">
          <div className="order_optimizer_table_row__right__upper_section__left">
            <h1 className="order_optimizer_table_row__right__upper_section__text">
              {scoreLabel}
            </h1>
            <h1 className="order_optimizer_table_row__right__upper_section__text">
              {idLabel}
            </h1>
          </div>
          <div className="order_optimizer_table_row__right__upper_section__right">
            {props.listingItem.reviewedBy === null ? (
              <ButtonComponent
                type="primary"
                title={translations.optimizationButtonTitle}
                classNames={{
                  root: "order_optimizer_table_row__right__upper_section__right__button",
                }}
                onClick={props.onOptimizationRequestClick}
              >
                <div className="order_optimizer_table_row__text">
                  {translations.optimizationButtonLabel}
                  <FontAwesomeIcon
                    icon={faWrench}
                    className="order_optimizer_table_row__right__upper_section__right__button__icon"
                  />
                </div>
              </ButtonComponent>
            ) : (
              <>
                <LinkButtonComponent
                  to={orderOptimizerRoutesHelper.getDetailsRoute({
                    id: props.listingItem.id,
                  })}
                  type="primary"
                  title={translations.previewOptimizationButtonTitle}
                  classNames={{
                    root: "order_optimizer_table_row__right__upper_section__right__button_preview",
                  }}
                  openInNewTab
                >
                  <div className="order_optimizer_table_row__text">
                    {translations.previewOptimizationButtonLabel}
                    <FontAwesomeIcon
                      icon={faEye}
                      className="order_optimizer_table_row__right__upper_section__right__button_preview__icon"
                    />
                  </div>
                </LinkButtonComponent>
                <div
                  className="order_optimizer_table_row__right__upper_section__right__badge"
                  title={`${
                    props.listingItem.reviewedBy
                  }, ${orderOptimizerListingTableRowHelper.getDateLabel(
                    props.listingItem.reviewedAt
                  )}`}
                >
                  {props.listingItem.reviewedBy}
                  {orderOptimizerListingTableRowHelper.getDateLabel(
                    props.listingItem.reviewedAt
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="order_optimizer_table_row__right__lower_section">
          <div className="order_optimizer_table_row__right__lower_section__left">
            <div className="order_optimizer_table_row__right__lower_section__left__item">
              <h3 className="order_optimizer_table_row__right__lower_section__left__item__text">
                {translations.orderNumbersLabel}
              </h3>
              {props.listingItem.orders.map((order) => (
                <div
                  key={order.humanId}
                  className={
                    props.selectedHumanId === order.humanId
                      ? "order_optimizer_table_row__right__lower_section__left__item__highlighted"
                      : "order_optimizer_table_row__right__lower_section"
                  }
                >
                  {order.humanId}
                </div>
              ))}
            </div>
            <div className="order_optimizer_table_row__right__lower_section__left__item">
              <h3 className="order_optimizer_table_row__right__lower_section__left__item__text">
                {translations.startDateLabel}
              </h3>
              {props.listingItem.orders.map((order) => (
                <div
                  key={String(order.startTime)}
                  className="order_optimizer_table_row__right__lower_section"
                >
                  {orderOptimizerListingTableRowHelper.getDateLabel(order.startTime)}
                </div>
              ))}
            </div>
          </div>
          <div className="order_optimizer_table_row__right__lower_section__right">
            <h3 className="order_optimizer_table_row__right__lower_section__right__text">
              {translations.cargoCompanyLabel}
            </h3>
            {props.listingItem.orders.map((order) => (
              <div
                key={order.cargoCompany}
                className="order_optimizer_table_row__right__lower_section"
              >
                {order.cargoCompany}
              </div>
            ))}
          </div>        
        </div>
      </div>
    </div>
  );
};

export default OrderOptimizerListingTableRowComponent;
