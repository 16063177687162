import railyApiService from "../raily-api.service";
import CargoTaxiContractListRequest, {
  CargoTaxiContractListRequestQueryParams,
} from "./cargo-taxi-list/cargo-taxi-contract-list-request";
import cargoTaxiContractListRequestFactory from "./cargo-taxi-list/cargo-taxi-contract-list-request.factory";
import CargoTaxiContractListResponse from "./cargo-taxi-list/cargo-taxi-contract-list.response";
import ContractValidCollectionRequest from "./valid-collection/contract-valid-collection.request";
import ContractValidCollectionResponse from "./valid-collection/contract-valid-collection.response";

const contractApiService = () => {
  const getValidCollection = async (
    request: ContractValidCollectionRequest,
    abortSignal: AbortSignal
  ): Promise<ContractValidCollectionResponse> => {
    const url = `/contracts/cargo-companies/${request.cargoCompanyId}/valid-collection`;

    return railyApiService().get({
      url,
      abortSignal,
    });
  };

  const getCargoTaxiContractList = (
    request: CargoTaxiContractListRequest,
    abortSignal: AbortSignal
  ): Promise<CargoTaxiContractListResponse> => {
    const url = `/contracts/cargo-taxi`;

    const queryParams: CargoTaxiContractListRequestQueryParams =
      cargoTaxiContractListRequestFactory.createQueryParams(request);

    return railyApiService().get({
      url,
      abortSignal,
      queryParams,
    });
  };

  return {
    getValidCollection,
    getCargoTaxiContractList,
  };
};

export default contractApiService;
