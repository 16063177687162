import orderOptimizerTranslationsHelper from "../../../../../../../languages/order-optimizer-translations.helper";

const getOrderIdTitle = (orderHumanId: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.centerTables
      .orderSummary;

  return translations.orderHumanIdTitleTemplate.replace(
    ":orderHumanId",
    orderHumanId.toString()
  );
};
const getOrderIdLabel = (orderHumanId: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.centerTables
      .orderSummary;
  return translations.orderHumanIdLabelTemplate.replace(
    ":orderHumanId",
    orderHumanId.toString()
  );
};
const getStartTimeTitle = (startTimeString: string | null) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.centerTables
      .orderSummary;
  return translations.startTimeTitleTemplate.replace(
    ":startTime",
    startTimeString ?? translations.startTimeExpired
  );
};
const getStartTimeLabel = (startTimeString: string | null) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.centerTables
      .orderSummary;
  return translations.startTimeLabelTemplate.replace(
    ":startTime",
    startTimeString ?? translations.startTimeExpired
  );
};
const getPlanEntryIdTitle = (planEntryId: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.centerTables
      .orderSummary;
  return translations.planEntryHumanIdTitleTemplate.replace(
    ":planEntryHumanId",
    planEntryId.toString()
  );
};
const getPlanEntryIdLabel = (planEntryId: number) => {
  const translations =
    orderOptimizerTranslationsHelper.getDetailsTranslations().table.centerTables
      .orderSummary;
  return translations.planEntryHumanIdLabelTemplate.replace(
    ":planEntryHumanId",
    planEntryId.toString()
  );
};

const orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper = {
  getOrderIdLabel,
  getOrderIdTitle,
  getPlanEntryIdLabel,
  getPlanEntryIdTitle,
  getStartTimeLabel,
  getStartTimeTitle,
};

export default orderOptimizerDetailsHiddenDetailsOrdersOrderSummaryHelper;
