import OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableColumn from "../types/order-optimizer-details-hidden-details-center-tables-driver-table-column";

const getColumns = (): OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableColumn[] => {

  const tableColumns: OrderOptimizerDetailsHiddenDetailsCenterTablesDriverTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "driverTime",
      colSpan: 3,
    },
    {
      header: "",
      title: "",
      accessor: "driverHaltingTime",
      colSpan: 3,
    },
    {
      header: "",
      title: "",
      accessor: "driverDrivingTime",
      colSpan: 3,
    },
    {
      header: "",
      title: "",
      accessor: "driverDistance",
      colSpan: 3,
    },
  ];

  return tableColumns;
};

const orderOptimizerDetailsHiddenDetailsCenterTablesDriverTableHelper = {
  getColumns,
};

export default orderOptimizerDetailsHiddenDetailsCenterTablesDriverTableHelper;
