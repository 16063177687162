import UserStatus from "../../../types/user-status";

export enum DriverListLoadParamsOrder {
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  FLEET_PARTNER_NAME_ASC = "FLEET_PARTNER_NAME_ASC",
  FLEET_PARTNER_NAME_DESC = "FLEET_PARTNER_NAME_DESC",
  TAXI_CORPORATION_DISPLAY_NAME_ASC = "TAXI_CORPORATION_DISPLAY_NAME_ASC",
  TAXI_CORPORATION_DISPLAY_NAME_DESC = "TAXI_CORPORATION_DISPLAY_NAME_DESC",
  LAST_PLAN_ENTRY_END_TIME_ASC = "LAST_PLAN_ENTRY_END_TIME_ASC",
  LAST_PLAN_ENTRY_END_TIME_DESC = "LAST_PLAN_ENTRY_END_TIME_DESC",
  CREATION_DATE_ASC = "CREATION_DATE_ASC",
  CREATION_DATE_DESC = "CREATION_DATE_DESC",
  MODIFICATION_DATE_ASC = "MODIFICATION_DATE_ASC",
  MODIFICATION_DATE_DESC = "MODIFICATION_DATE_DESC",
}

type DriverListLoadParams = {
  isCandidaturePending?: boolean;
  taxiCorporation?: string;
  taxiCorporationUuid?: string;
  fleetPartner?: string;
  fleetPartnerUuid?: string;
  order?: DriverListLoadParamsOrder;
  firstName?: string;
  lastName?: string;
  status?: UserStatus;
  page: number;
  pageSize: number;
  address?: string;
  username?: string;
  email?: string;
  creationDateFrom?: Date;
  creationDateTo?: Date;
  modificationDateFrom?: Date;
  modificationDateTo?: Date;
};

export default DriverListLoadParams;
