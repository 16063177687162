import OrderOptimizerDetailsHiddenDetailsDriver from "../types/order-optimizer-details-hidden-details-driver";
import { OrderOptimizerDetailsHiddenDetailsDriverListItem } from "../types/order-optimizer-details-hidden-details-driver-list";
import OrderOptimizerDetailsHiddenDetailsDriverSelectOption from "../types/order-optimizer-details-hidden-details-driver-select-option";

const createPotentialDriver = (
  potentialDriver: OrderOptimizerDetailsHiddenDetailsDriverListItem
): OrderOptimizerDetailsHiddenDetailsDriver => {
  return {
    driver: potentialDriver.driver,
    taxiDriverAssociationUuid: potentialDriver.taxiDriverAssociationUuid,
    saDistance: potentialDriver.saDistance,
    taxiCorporation: potentialDriver.taxiCorporation,
  };
};

const createPotentialDriverSelectOption = (
  potentialDriver: OrderOptimizerDetailsHiddenDetailsDriver
): OrderOptimizerDetailsHiddenDetailsDriverSelectOption => {
  return {
    label: potentialDriver.driver,
    subText: potentialDriver.taxiCorporation,
    value: potentialDriver,
  };
};

const createPotentialDriverSelectOptions = (
  data: OrderOptimizerDetailsHiddenDetailsDriverListItem[]
): OrderOptimizerDetailsHiddenDetailsDriverSelectOption[] => {
  const workers = data.map(createPotentialDriver);
  return workers.map(createPotentialDriverSelectOption);
};

const orderOptimizerDetailsHiddenDetailsDriverFactory = {
  createPotentialDriverSelectOption,
  createPotentialDriverSelectOptions,
};

export default orderOptimizerDetailsHiddenDetailsDriverFactory;
