import useAsyncData from "../../../hooks/use-async-data";
import orderOptimizerService from "../order-optimizer.service";
import OrderOptimizerDetails from "./order-optimizer-details";
import OrderOptimizerDetailsLoadParams from "./order-optimizer-details-load-params";

const useOrderOptimizerDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<OrderOptimizerDetails | null>(null);

  const load = async (
    params: OrderOptimizerDetailsLoadParams,
    signal: AbortSignal
  ): Promise<void> => {
    setIsError(false);
    setIsLoading(true);

    try {
      const orderOptimizerDetails: OrderOptimizerDetails =
        await orderOptimizerService.getDetails(params, signal);

      setData(orderOptimizerDetails);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setIsError(false);
    setIsLoading(true);
    setData(null);
  };

  return {
    data,
    isError,
    isLoading,
    load,
    reset,
  };
};

export default useOrderOptimizerDetails;
