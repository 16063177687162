import HttpResponse from "../../../../utils/http/http.response";

export enum CargoTaxiContractListResponseContractMaintenancePolicy {
  BASIC = "BASIC",
  AUCTION = "AUCTION",
}

export enum CargoTaxiContractListResponseModel {
  ABA = "ABA",
  SABS = "SABS",
  AB = "AB",
  OTHER = "OTHER",
}

export type CargoTaxiContractListResponseTaxiCorporationFleetPartners = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date;
  modifiedBy: string;
  taxiCorporationId: string;
  name: string;
};

export type CargoTaxiContractListResponseCargoCompany = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMainteancePolicy: CargoTaxiContractListResponseContractMaintenancePolicy;
  notes: string | null;
};

export type CargoTaxiContractListResponseTaxiCorporation = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMainteancePolicy: CargoTaxiContractListResponseContractMaintenancePolicy;
  notes: string | null;
  fleetPartners: CargoTaxiContractListResponseTaxiCorporationFleetPartners[];
};

export type CargoTaxiContractListResponseItem = {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  taxiCorporation: CargoTaxiContractListResponseTaxiCorporation;
  cargoCompany: CargoTaxiContractListResponseCargoCompany;
  publishingAllowed: boolean;
  cargoCompanyId: string;
  taxiCorporationId: string;
  model: CargoTaxiContractListResponseModel;
  validSince: Date;
  validTo: Date;
  isValid: boolean;
  distanceRate: number;
  haltingTimeRate: number;
  haltingTimeAfterMinutes: number;
  allowChargeWhileApproaching: boolean;
  allowChargeWhileReturning: boolean;
  allowChargeDuringRide: boolean;
  discountLimit: number;
};

type CargoTaxiContractListResponse = HttpResponse<{
  data: CargoTaxiContractListResponseItem[];
  totalCount: number;
}>;

export default CargoTaxiContractListResponse;
