import { FC, useMemo } from "react";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import uuidService from "../../../../../../common/utils/uuid/uuid.service";
import { useAppContext } from "../../../../../../context/app.context";
import { OrderOptimizerDetailsItemStepsHomeToStart } from "../../../common/types/order-optimizer-details-item";
import OrderOptimizerDetailsTableDotComponent from "../../../dot/order-optimizer-details-orders-table-dot.component";
import OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableRow from "../types/order-optimizer-details-hidden-details-heading-tables-order-table-row";
import orderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableHelper from "./order-optimizer-details-hidden-details-heading-tables-order-table.helper";

type OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  detailsStepsHomeToStartItems: OrderOptimizerDetailsItemStepsHomeToStart | null;
};

const OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableComponent: FC<
  OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();

  const columns = useMemo(() => {
    return orderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableHelper.getColumns();
  }, [selectedAppLanguage]);

  const createTableRow = (
    detailsStepsHomeToStartItems: OrderOptimizerDetailsItemStepsHomeToStart
  ): OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableRow => {
    const dotColor = "#d9d9d9";

    const index = 0;

    return {
      id: uuidService.generate(),
      value: {
        humanId: (
          <div className="order_optimizer_details_hidden_details_order__humanId">
            <span title={String(index)}>{index}</span>
            <OrderOptimizerDetailsTableDotComponent color={dotColor} />
          </div>
        ),
        address: (
          <div title={detailsStepsHomeToStartItems.displayName}>
            {detailsStepsHomeToStartItems.displayName}
          </div>
        ),
        totalTime: <div />,
        haltingTime: <div />,
        distance: <div />,
      },
    };
  };

  const rows: OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableRow[] =
    useMemo(() => {
      if (!props.detailsStepsHomeToStartItems) return [];

      const orderRows = createTableRow(props.detailsStepsHomeToStartItems);

      return [orderRows];
    }, [props.detailsStepsHomeToStartItems, selectedAppLanguage]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isLoading={props.isLoading}
      isError={props.isError}
    />
  );
};

export default OrderOptimizerDetailsHiddenDetailsHeadingTablesOrderTableComponent;
